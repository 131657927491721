const settingsRouterPaths = {
  root: '/settings',
  profile: '/settings/profile',
  members: '/settings/members',
  credentials: '/settings/credentials',
  storagePaths: '/settings/storage-paths',
  tokens: '/settings/tokens',
  ssoConnections: '/settings/sso-connections',
  ssoConnection: '/settings/sso-connections/:uuid',
  billing: '/settings/billing',
};

export default settingsRouterPaths;
