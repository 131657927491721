import React from 'react';
import { AnimatePresence } from 'framer-motion';
import useSnackbar from 'store/SnackbarStore';
import Snackbar from 'components/Snackbar';
import './SnackbarContainer.scss';

const SnackbarContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const snackbars = useSnackbar((state) => state.snackbars);
  const dismissSnackbar = useSnackbar((state) => state.dismissSnackbar);

  return (
    <>
      {children}
      <div className="TDB-SnackbarContainer">
        <AnimatePresence>
          {snackbars.map((snackbar) => {
            return (
              <Snackbar
                key={snackbar.id}
                {...snackbar}
                onDismiss={dismissSnackbar}
              />
            );
          })}
        </AnimatePresence>
      </div>
    </>
  );
};

export default SnackbarContainer;
