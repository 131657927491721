import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM13.72 24.33H11.89V21.12H13.72C13.99 21.12 14.25 21.14 14.5 21.18C14.75 21.22 14.97 21.3 15.16 21.42C15.35 21.53 15.5 21.69 15.61 21.9C15.73 22.11 15.79 22.385 15.79 22.725C15.79 23.065 15.73 23.34 15.61 23.55C15.5 23.76 15.35 23.925 15.16 24.045C14.97 24.155 14.75 24.23 14.5 24.27C14.25 24.31 13.99 24.33 13.72 24.33ZM14.365 19.29H9.535V30H11.89V26.16H14.365C15.035 26.16 15.605 26.065 16.075 25.875C16.545 25.675 16.925 25.415 17.215 25.095C17.515 24.775 17.73 24.41 17.86 24C18 23.58 18.07 23.155 18.07 22.725C18.07 22.285 18 21.86 17.86 21.45C17.73 21.04 17.515 20.675 17.215 20.355C16.925 20.035 16.545 19.78 16.075 19.59C15.605 19.39 15.035 19.29 14.365 19.29ZM23.9949 28.02H21.8949V21.27H23.5749C24.1549 21.27 24.6399 21.355 25.0299 21.525C25.4299 21.685 25.7499 21.92 25.9899 22.23C26.2299 22.54 26.3999 22.915 26.4999 23.355C26.6099 23.785 26.6649 24.27 26.6649 24.81C26.6649 25.4 26.5899 25.9 26.4399 26.31C26.2899 26.72 26.0899 27.055 25.8399 27.315C25.5899 27.565 25.3049 27.745 24.9849 27.855C24.6649 27.965 24.3349 28.02 23.9949 28.02ZM24.1599 19.29H19.5399V30H24.1599C24.9799 30 25.6899 29.865 26.2899 29.595C26.8999 29.315 27.4049 28.935 27.8049 28.455C28.2149 27.975 28.5199 27.405 28.7199 26.745C28.9199 26.085 29.0199 25.365 29.0199 24.585C29.0199 23.695 28.8949 22.92 28.6449 22.26C28.4049 21.6 28.0649 21.05 27.6249 20.61C27.1949 20.17 26.6799 19.84 26.0799 19.62C25.4899 19.4 24.8499 19.29 24.1599 19.29ZM38.188 19.29H30.658V30H33.013V25.575H37.498V23.745H33.013V21.27H38.188V19.29Z"
  />,
  'pdf'
);
