import React, { ComponentType } from 'react';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import isStorageSupported from '../isStorageSupported';
import ChunkLoadError from 'utils/errors/ChunkLoadError';

const importOrReload = (
  componentImport: any
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    let hasRefreshed = false;
    const canWriteToSessionStorage = isStorageSupported('sessionStorage');
    if (canWriteToSessionStorage) {
      // check if the window has already been refreshed
      hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(sessionStorageKeys.LAZY_LOAD_REFRESHED) ||
          'false'
      );
    }
    // try to import the component
    componentImport()
      .then((component: any) => {
        if (canWriteToSessionStorage) {
          window.sessionStorage.setItem(
            sessionStorageKeys.LAZY_LOAD_REFRESHED,
            'false'
          ); // success so reset the refresh
        }
        resolve(component);
      })
      .catch((error: any) => {
        if (!isStorageSupported('sessionStorage')) {
          // Throw chunk load error if session storage is disabled
          throw new ChunkLoadError();
        } else {
          // If session storage is enabled set refreshed to true and reload page
          if (!hasRefreshed) {
            // not been refreshed yet
            window.sessionStorage.setItem(
              sessionStorageKeys.LAZY_LOAD_REFRESHED,
              'true'
            );

            // we are now going to refresh
            window.location.reload();
          } else {
            // Default error behaviour
            reject(error);
          }
        }
      });
  });
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyLoadComponent = (c: () => Promise<any>) =>
  React.lazy(() => importOrReload(c));

export default lazyLoadComponent;
