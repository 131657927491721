import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M23.8596 26.2245H21.0846L22.4796 22.2945H22.5096L23.8596 26.2245Z" />
    <path d="M33.5882 24.4995H31.0082V21.4845H33.5882C34.1282 21.4845 34.5332 21.6045 34.8032 21.8445C35.0732 22.0745 35.2082 22.4495 35.2082 22.9695C35.2082 23.5095 35.0732 23.8995 34.8032 24.1395C34.5332 24.3795 34.1282 24.4995 33.5882 24.4995Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM9.75164 21.6345H12.9616V30.3645H15.3166V21.6345H18.5266V19.6545H9.75164V21.6345ZM23.7246 19.6545H21.3096L17.2596 30.3645H19.6296L20.4696 27.9795H24.4746L25.2846 30.3645H27.7296L23.7246 19.6545ZM34.4282 19.6545H28.6532V30.3645H31.0082V26.1795H33.3632C33.9532 26.1795 34.3782 26.3095 34.6382 26.5695C34.8982 26.8295 35.0682 27.2395 35.1482 27.7995C35.2082 28.2295 35.2532 28.6795 35.2832 29.1495C35.3132 29.6195 35.3932 30.0245 35.5232 30.3645H37.8782C37.7682 30.2145 37.6832 30.0345 37.6232 29.8245C37.5732 29.6045 37.5332 29.3745 37.5032 29.1345C37.4832 28.8945 37.4682 28.6595 37.4582 28.4295C37.4482 28.1995 37.4382 27.9995 37.4282 27.8295C37.4082 27.5595 37.3682 27.2895 37.3082 27.0195C37.2582 26.7495 37.1732 26.5045 37.0532 26.2845C36.9332 26.0545 36.7782 25.8595 36.5882 25.6995C36.3982 25.5295 36.1582 25.4045 35.8682 25.3245V25.2945C36.4682 25.0545 36.8982 24.7045 37.1582 24.2445C37.4282 23.7845 37.5632 23.2395 37.5632 22.6095C37.5632 22.1995 37.4882 21.8195 37.3382 21.4695C37.1982 21.1095 36.9882 20.7945 36.7082 20.5245C36.4382 20.2545 36.1082 20.0445 35.7182 19.8945C35.3382 19.7345 34.9082 19.6545 34.4282 19.6545Z"
    />
  </>,
  'tar'
);
