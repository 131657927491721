import React, { useMemo } from 'react';
import { useUserContext } from 'store/UserContext';
import history from 'utils/helpers/history';
import { authLinks } from 'utils/links';

export const AuthGuard: React.FC<any> = (props) => {
  const { children } = props;
  const { isLoggedIn } = useUserContext();

  const comp = useMemo(() => {
    if (!isLoggedIn) {
      history.replace(authLinks.login());
      return null;
    }

    return children;
  }, [isLoggedIn, children]);

  return comp;
};

export default AuthGuard;
