import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M25.0442 28.3845H22.9442V21.6345H24.6242C25.2042 21.6345 25.6892 21.7195 26.0792 21.8895C26.4792 22.0495 26.7992 22.2845 27.0392 22.5945C27.2792 22.9045 27.4492 23.2795 27.5492 23.7195C27.6592 24.1495 27.7142 24.6345 27.7142 25.1745C27.7142 25.7645 27.6392 26.2645 27.4892 26.6745C27.3392 27.0845 27.1392 27.4195 26.8892 27.6795C26.6392 27.9295 26.3542 28.1095 26.0342 28.2195C25.7142 28.3295 25.3842 28.3845 25.0442 28.3845Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM11.826 19.6545H9.47104V30.3645H11.826V25.7445H16.161V30.3645H18.516V19.6545H16.161V23.7645H11.826V19.6545ZM25.2092 19.6545H20.5892V30.3645H25.2092C26.0292 30.3645 26.7392 30.2295 27.3392 29.9595C27.9492 29.6795 28.4542 29.2995 28.8542 28.8195C29.2642 28.3395 29.5692 27.7695 29.7692 27.1095C29.9692 26.4495 30.0692 25.7295 30.0692 24.9495C30.0692 24.0595 29.9442 23.2845 29.6942 22.6245C29.4542 21.9645 29.1142 21.4145 28.6742 20.9745C28.2442 20.5345 27.7292 20.2045 27.1292 19.9845C26.5392 19.7645 25.8992 19.6545 25.2092 19.6545ZM39.2374 19.6545H31.7074V30.3645H34.0624V25.9395H38.5474V24.1095H34.0624V21.6345H39.2374V19.6545Z"
    />
  </>,
  'hdf'
);
