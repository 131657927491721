import * as React from 'react';
import { Icon } from 'components/Svg';
import Typography from 'components/Typography';
import './WorkspaceOption.scss';

type Props = {
  icon: Icon;
  rightIcon?: Icon;
  label: string;
  onClick?: () => void;
};

const WorkspaceOption: React.FC<Props> = (props) => {
  const { icon: MainIcon, rightIcon: SecondaryIcon, label, onClick } = props;
  return (
    <div className="TDB-WorkspaceOption" onClick={onClick}>
      <div className="TDB-WorkspaceOption__content">
        <MainIcon size={16} colorToken="color-text-secondary-default" />
        <Typography fontWeight="600" fontSize="body-md" variant="default">
          {label}
        </Typography>
      </div>
      {SecondaryIcon && (
        <SecondaryIcon size={16} colorToken="color-text-secondary-default" />
      )}
    </div>
  );
};

export default WorkspaceOption;
