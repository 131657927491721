import {
  UserApi_getUserMetaStatus$Path,
  UserApi_patchUserMetaStatus,
  UserApi_patchUserMetaStatus$Params,
  UserApi_patchUserMetaStatus$Response,
} from 'api-client';
import { MutateResponse, useMutateRequest } from 'hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import queryClient from 'utils/helpers/queryClient';

const useUpdateUserMeta = (): MutateResponse<
  UserApi_patchUserMetaStatus$Params,
  UserApi_patchUserMetaStatus$Response
> => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    UserApi_patchUserMetaStatus,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          // Invalidate user meta get request after updating user meta (important)
          predicate: (query) =>
            query.queryKey[0] === UserApi_getUserMetaStatus$Path,
        });
      },
    }
  );

  return [
    useMutateRequest(mutateAsync),
    data,
    error as null | Error,
    isLoading,
  ];
};

export default useUpdateUserMeta;
