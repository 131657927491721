import { compose, defaultTo } from 'ramda';
import divideBy from '../divideBy';
import roundToNDecimals from '../roundToNDecimals';

const defaultToZero = defaultTo(0);
const divideBy1000 = divideBy(1000);
const COST_PER_CPU_PER_HOUR = 0.000016667;
const roundToTwoDecimals = roundToNDecimals(2);
const calculateNotebookCost = (
  uptime: number | undefined,
  millicpu: number | undefined
): number =>
  COST_PER_CPU_PER_HOUR *
  defaultToZero(uptime) *
  divideBy1000(defaultToZero(millicpu));

const calculateNotebookCostRounded = compose(
  roundToTwoDecimals,
  calculateNotebookCost
);

export default calculateNotebookCostRounded;
