/**
 * This is used to create svg icons.
 * Wrap an svg <path> with the `createSvgIcon` hoc to create an icon.
 */

import React from 'react';
import './Svg.scss';
import { TextColorToken } from 'components/Text/Text';
import classNames from 'classnames';

export type IconBackgroundColorToken =
  | 'background-secondary'
  | 'background-primary'
  | 'background-deco'
  // V2 tokens
  | 'color-icon-emphasis-default'
  | 'color-text-default'
  | 'color-text-disabled'
  | 'color-bg-fill-success-default'
  | 'color-bg-fill-info-icon-default'
  | 'color-bg-fill-info-default'
  | 'color-bg-fill-warning-icon-default'
  | 'color-bg-fill-emphasis-default'
  | 'color-bg-fill-warning-default'
  | 'color-text-secondary-default'
  | 'color-text-on-surface-secondary-info-default';

export interface SvgIconProps {
  size?: number | string;
  fill?: string;
  colorToken?: TextColorToken | IconBackgroundColorToken;
  className?: string;
}

export type Icon = React.ComponentType<SvgIconProps>;

interface CreateSvgIconProps extends SvgIconProps {
  title: string;
  children: React.ReactNode;
}

const Svg: React.FC<CreateSvgIconProps> = (props) => {
  const { children, title, size, colorToken, fill, className, ...rest } = props;
  const fillColor = colorToken ? `var(--${colorToken})` : fill;
  return (
    <svg
      width={size}
      height={size}
      fill={fillColor}
      focusable="false"
      viewBox="0 0 48 48"
      className={classNames('TDB-Svg', className)}
      {...rest}
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
};

Svg.defaultProps = {
  title: '',
  size: 24,
  fill: 'var(--text-main-secondary)',
};

export function createSvgIcon(
  path: React.ReactElement,
  title: string
): React.ComponentType<SvgIconProps> {
  const Icon: React.FC<SvgIconProps> = (props) => (
    <Svg title={title} {...props}>
      {path}
    </Svg>
  );
  return Icon;
}
