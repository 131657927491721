import * as React from 'react';
import classNames from 'classnames';
import Typography from 'components/Typography';
import HistoryItem from 'store/HistoryStack/HistoryItem';
import styles from './BreadcrumbItemTitle.module.scss';
import useAsync from 'hooks/useAsync';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { Link } from 'react-router-dom';
import ConditionalWrapper from 'components/ConditionalWrapper';

interface Props extends React.PropsWithChildren {
  item: HistoryItem;
  active: boolean;
  onClick?: () => void;
  variant?: 'block';
  hidden?: boolean;
  onWidthUpdate?: (values: { width: number; key: string }) => void;
}

const BreadcrumbItemTitle: React.FC<Props> = (props) => {
  const { active, item, children, onClick, variant, onWidthUpdate, hidden } =
    props;
  const { isLoading, data: title } = useAsync(item.getTitle as Promise<string>);

  const [containerRef, containerBounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  React.useEffect(() => {
    onWidthUpdate?.({ width: containerBounds.width, key: item.key });
  }, [
    containerBounds.width,
    item.title,
    onWidthUpdate,
    isLoading,
    item.pathname,
    item.key,
  ]);

  const onClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLParagraphElement>) => {
      if (!e.ctrlKey) {
        onClick?.();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onClick]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {
        [styles.block]: variant === 'block',
        [styles.hidden]: hidden,
      })}
    >
      {!isLoading && (
        <div className={classNames(styles.itemWrapper)}>
          <ConditionalWrapper
            left={(children) => <Link to={item.pathname}>{children}</Link>}
            right={(children) => children}
            condition={!active}
          >
            <Typography
              onClick={onClickHandler}
              ellipsis={active}
              className={classNames(styles.item, {
                [styles.active]: active,
                [styles.clickable]: !active && !!onClick,
              })}
              fontSize="body-sm"
              fontWeight="500"
            >
              {title}
            </Typography>
          </ConditionalWrapper>

          {children}
        </div>
      )}
    </div>
  );
};

export default BreadcrumbItemTitle;
