import './InboxContent.scss';
import React, { useMemo } from 'react';
import { queryKey as getNotificationsQueryKey } from 'hooks/api/Notifications/useGetNotifications';
import Text from 'components/Text';
import useInboxStore from 'store/InboxStore';
import pluralize from 'utils/helpers/string/pluralize';
import useMarkAllNotificationsAsRead from 'hooks/api/Notifications/useMarkAllNotificationsAsRead';
import { isNumber } from 'utils/helpers/number';
import LazyLoadListWrapper from 'components/LazyLoadListWrapper';
import NotificationItem from './NotificationItem';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  Notification,
  NotificationsApi_listNotifications,
  NotificationsApi_listNotifications$Response,
} from 'api-client';
import { chain } from 'ramda';
import { AxiosResponse } from 'axios';
import Button from 'components/v2/Button';
import { useUserContext } from 'store/UserContext';
import IconCheckFilled from 'components/Svg/icons/IconCheckFilled';
import { CustomErrorBoundary } from 'components/ErrorBoundary';
import IconBellFilled from 'components/Svg/icons/IconBellFilled';
import useUserPreferencesStore from 'store/UserPreferencesStore';
import IconBellFilledSnoozed from 'components/Svg/icons/IconBellFilledSnoozed';
import Tooltip from 'components/Tooltip';
import Placeholder from 'components/v2/Placeholder';

interface InboxContentProps {
  closeDialogFunc: () => void;
}

const InboxContent: React.FC<InboxContentProps> = (props) => {
  const { closeDialogFunc } = props;
  const { user } = useUserContext();
  const { notificationsSnoozed, setNotificationsSnoozed } =
    useUserPreferencesStore((state) => ({
      notificationsSnoozed: state.notificationsSnoozed,
      setNotificationsSnoozed: state.setNotificationsSnoozed,
    }));
  const unreadCount = useInboxStore((state) => state.unreadCount);
  const [markAllAsRead, , , markAllAsReadLoading] =
    useMarkAllNotificationsAsRead();
  const params = {
    page: 1,
    perPage: 10,
  };
  const response = useInfiniteQuery<any, Error>(
    [getNotificationsQueryKey, params], // cache
    ({ pageParam = 1 }) =>
      NotificationsApi_listNotifications({
        ...params,
        page: pageParam,
        namespace: user.id,
      }) as any,
    {
      getNextPageParam: (lastPage) => {
        /**
         * If there is another page to fetch, return it here
         * If the page returned is a truthy value, we can use directly the `fetchNextPage()` helper function
         * If it is falsy, fetchNextPage() will not run (since we reached the end of the pagination)
         */
        const { page, total_pages } = lastPage.data.pagination_metadata;
        if (page <= total_pages - 1) {
          // return next page
          return page + 1;
        }
        // we reached the end
        return undefined;
      },
    }
  );

  const { notifications, fetchMore, error, loading } = useMemo(() => {
    const fetchMore = response.fetchNextPage;
    const error = response.error;
    const notifications = chain<
      AxiosResponse<NotificationsApi_listNotifications$Response>,
      Notification
    >((page) => page.data?.notifications || [], response?.data?.pages || []);
    // show loader when fetching for the first time
    // or when fetching next page
    const loading =
      (notifications.length === 0 && response.isFetching) ||
      (notifications.length > 0 && response?.isFetchingNextPage);

    return { notifications, fetchMore, error, loading };
  }, [response]);

  return (
    <div className="TDB-InboxContent" data-testid="inbox-content">
      <Text
        variant="heading-2"
        fontWeight="bold"
        color="text-main-primary"
        className="mb-2"
        as="h5"
      >
        Notifications
      </Text>
      {error && (
        <Text as="p" fontWeight="semi-bold" align="center">
          An unexpected error occurred
        </Text>
      )}
      {!error && (
        <>
          <div className="TDB-InboxContent__header">
            <Text className="TDB-InboxContent__header__title">
              {isNumber(unreadCount) && unreadCount > 0 ? (
                <Text
                  variant="body-md"
                  fontWeight="medium"
                  color="text-main-primary"
                >
                  You have{' '}
                  <Text
                    variant="heading-2"
                    color="text-action-brand"
                    fontWeight="bold"
                  >
                    {unreadCount}
                  </Text>{' '}
                  unread{' '}
                  {pluralize(unreadCount, 'notification', 'notifications')}
                </Text>
              ) : (
                <Text variant="heading-2" fontWeight="semi-bold">
                  You&apos;re all caught up
                </Text>
              )}
            </Text>
            <hr />
            <div className="TDB-InboxContent__header__bulk-actions">
              {isNumber(unreadCount) && unreadCount > 0 && (
                <Button
                  variant="outlined"
                  size="medium"
                  icon={IconCheckFilled}
                  dataTestID="mark-all-inbox-as-read"
                  loading={markAllAsReadLoading}
                  onClick={() => markAllAsRead([{ namespace: user.id }])}
                >
                  Mark all as read
                </Button>
              )}
              <Tooltip
                content={`${
                  notificationsSnoozed ? 'Unsnooze' : 'Snooze'
                } notifications`}
              >
                <Button
                  variant="outlined"
                  size="medium"
                  dataTestID="snooze-notifications"
                  icon={
                    notificationsSnoozed
                      ? IconBellFilled
                      : IconBellFilledSnoozed
                  }
                  onClick={() => setNotificationsSnoozed(!notificationsSnoozed)}
                />
              </Tooltip>
            </div>
          </div>
          {!loading && !!notifications && notifications.length === 0 && (
            <div
              className="TDB-InboxContent__no-content"
              data-testid="inbox-no-content"
            >
              <Placeholder graphic="no-notifications" />
            </div>
          )}
          <div className="TDB-InboxContent__list">
            <LazyLoadListWrapper loading={loading} onScrollEnd={fetchMore}>
              {notifications?.map((n) => (
                <NotificationItem
                  key={n.id}
                  notification={n}
                  onClick={closeDialogFunc}
                />
              ))}
            </LazyLoadListWrapper>
          </div>
        </>
      )}
    </div>
  );
};

const InboxContentWithErrorBoundary: React.FC<InboxContentProps> = (props) => {
  return (
    <CustomErrorBoundary>
      <InboxContent {...props} />
    </CustomErrorBoundary>
  );
};

export default InboxContentWithErrorBoundary;
