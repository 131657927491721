import './AuthPromptPlaceholder.scss';
import React from 'react';
import Button from 'components/v2/Button';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import { authLinks } from 'utils/links';
import multicube from 'assets/images/multicube.png';

interface Props {
  message?: string;
}

const AuthPromptPlaceholder: React.FC<Props> = (props) => {
  const { message } = props;

  const msg = message || 'No registered or shared assets yet.';

  return (
    <div
      className="TDB-AuthPromptPlaceholder"
      data-testid="TDB-AuthPromptPlaceholder"
    >
      <Text
        as="h2"
        color="text-main-primary"
        fontWeight="bold"
        variant="heading-1"
        className="TDB-AuthPromptPlaceholder__title"
        align="center"
      >
        Get started!
      </Text>
      <Text
        as="p"
        color="text-main-secondary"
        variant="body-md"
        align="center"
        className="TDB-AuthPromptPlaceholder__description"
      >
        {msg} Create your account and get started with TileDB.
      </Text>
      <div className="TDB-AuthPromptPlaceholder__image">
        <img className="TDB-AuthTemplate__Cube" src={multicube} alt="cube" />
      </div>
      <div className="TDB-AuthPromptPlaceholder__buttons">
        <Link to={authLinks.signup()}>
          <Button size="large" variant="primary">
            Signup
          </Button>
        </Link>
        <Link to={authLinks.login()}>
          <Button size="large" variant="outlined">
            Login
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AuthPromptPlaceholder;
