import configExists from '../configExists';
import getJupyterURL from '../getJupyterURL';
import supportedRegions from 'utils/constants/supportedRegions';

/**
 * Renders an iframe to jupyterhub/hub/logout in order to logout user from jupyterhub
 */
const logoutJupyterHandler = (): void => {
  if (!configExists('JUPYTERHUB_URL')) {
    /**
     * Don't do anything if Jupyterhub url is not configured
     */
    return;
  }
  const regions = Object.keys(supportedRegions);

  if (!regions.length) {
    logoutFromRegion();
    return;
  }
  regions.forEach(logoutFromRegion);
};

const logoutFromRegion = (region?: string) => {
  const jupyterHostURL = getJupyterURL(region);
  const logoutURL = `${jupyterHostURL}/hub/logout`;

  const iframe = document.createElement('iframe');

  iframe.src = logoutURL;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.position = 'absolute';
  document.body.append(iframe);
};

export default logoutJupyterHandler;
