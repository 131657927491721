const externalLinks = {
  tiledbWebsite: 'https://tiledb.com/',
  tiledbAbout: 'https://tiledb.com/about',
  tiledbPricing: 'https://tiledb.com/pricing',
  tiledbContactSales: 'https://tiledb.com/contact-sales',
  tiledbPrivacyPolicy: 'https://tiledb.com/privacy-policy',
  tiledbTerms: 'https://tiledb.com/terms',
  tiledbContact: 'https://tiledb.com/contact',
  tiledbFeedback: 'https://feedback.tiledb.com',
  enterprise: {
    metrics:
      'https://docs.tiledb.com/enterprise/installation#kubernetes-components',
  },
  tiledbCloudDocs: {
    home: 'https://docs.tiledb.com/cloud/',
    user: {
      storagePathSettings:
        'https://docs.tiledb.com/cloud/how-to/account/set-up-default-storage-path',
      credentials:
        'https://docs.tiledb.com/cloud/how-to/account/set-up-aws-credentials',
    },
    organization: {
      storagePathSettings:
        'https://docs.tiledb.com/cloud/how-to/organizations/set-up-default-storage-path',
    },
  },
  docs: {
    install: 'https://docs.tiledb.com/main/how-to/installation/quick-install',
  },
  blogposts: {
    arrays101: 'https://tiledb.com/blog/tiledb-101-arrays',
    udfs: 'https://tiledb.com/blog/tiledb-cloud-user-defined-functions',
    taskgraphs: 'https://tiledb.com/blog/tiledb-cloud-task-graphs',
    vectorDB: 'https://tiledb.com/blog/why-tiledb-as-a-vector-database',
    geometries: 'https://tiledb.com/blog/why-tiledb-for-geometries',
    genomicsDataProblem:
      'https://tiledb.com/blog/population-genomics-is-a-data-management-problem',
    universalModel:
      'https://tiledb.com/blog/why-arrays-as-a-universal-data-model',
  },
};

export default externalLinks;
