import isString from '../isString';

function capitalizeFirstLetter(str: string): string;
function capitalizeFirstLetter(str: undefined): undefined;
function capitalizeFirstLetter(str: string | undefined): string | undefined {
  if (!isString(str)) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default capitalizeFirstLetter;
