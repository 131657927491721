import * as React from 'react';
import Fetching from 'components/v2/Fetching';
import Typography from 'components/Typography';
import HistoryItem from 'store/HistoryStack/HistoryItem';
import useAsync from 'hooks/useAsync';

interface Props {
  item: HistoryItem;
}

const BreadcrumbTitle: React.FC<Props> = (props) => {
  const { item } = props;
  const { isLoading, data: title } = useAsync(item.getTitle as Promise<string>);

  return (
    <Fetching isLoading={isLoading} skeleton={<></>}>
      <Typography fontSize="heading-xs" fontWeight="700">
        {title}
      </Typography>
    </Fetching>
  );
};

export default BreadcrumbTitle;
