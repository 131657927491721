const pointCloudRouterPaths = {
  root: '/point-cloud',
  details: '/point-cloud/:namespace/:groupId',
  overview: '/point-cloud/:namespace/:groupId/overview',
  sharing: '/point-cloud/:namespace/:groupId/sharing',
  settings: '/point-cloud/:namespace/:groupId/settings',
  contents: '/point-cloud/:namespace/:groupId/contents',
  preview: '/point-cloud/:namespace/:groupId/preview',
  metadata: '/point-cloud/:namespace/:groupId/metadata',
  activity: '/point-cloud/:namespace/:groupId/activity',
};

export default pointCloudRouterPaths;
