import {
  AssetsApi_listAssetSummaries,
  AssetsApi_listAssetSummaries$Params,
  AssetsApi_listAssetSummaries$Response,
} from 'api-client';
import { useQuery } from '@tanstack/react-query';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { AxiosResponse } from 'axios';

// Generic query key to invalidate all asset listings
export const queryKey = 'asset-summaries';

// Fetch the asset summary of a given namespace
const useListAssetSummaries: UseRequestType<
  AssetsApi_listAssetSummaries$Params,
  AssetsApi_listAssetSummaries$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<AssetsApi_listAssetSummaries$Response>
  >(
    [queryKey, queryData],
    useQueryRequest(AssetsApi_listAssetSummaries, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useListAssetSummaries;
