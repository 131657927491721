import { Icon } from 'components/Svg';
import IconDocumentation from 'components/Svg/icons/IconDocumentation';
import IconDollar from 'components/Svg/icons/IconDollar';
import React from 'react';
import { externalLinks } from 'utils/links';
import styles from './HelpDialog.module.scss';
import Typography from 'components/Typography';
import IconExternalLinkOutlined from 'components/Svg/icons/IconExternalLinkOutlined';
import IconChat from 'components/Svg/icons/IconChat';
import IconSparkles from 'components/Svg/icons/IconSparkles';
import IconSecureShield from 'components/Svg/icons/IconSecureShield';
import IconBook from 'components/Svg/icons/IconBook';

interface HelpDialogItemProps {
  label: string;
  icon: Icon;
  link: string;
}

const HelpDialogItem: React.FC<HelpDialogItemProps> = (props) => {
  const { link, label, icon: Icon } = props;
  return (
    <a className={styles.item} target="_blank" href={link} rel="noreferrer">
      <div className={styles.itemLabel}>
        <Icon size="16px" colorToken="color-text-secondary-default" />
        <Typography fontSize="body-md" fontWeight="500" variant="secondary">
          {label}
        </Typography>
      </div>
      <IconExternalLinkOutlined
        colorToken="color-icon-emphasis-default"
        size="14px"
      />
    </a>
  );
};

const HelpDialog: React.FC = () => {
  return (
    <div className={styles.container} data-testid="help-dialog">
      <HelpDialogItem
        label="Documentation"
        icon={IconDocumentation}
        link={externalLinks.tiledbCloudDocs.home}
      />
      <HelpDialogItem
        label="Pricing"
        icon={IconDollar}
        link={externalLinks.tiledbPricing}
      />
      <HelpDialogItem
        label="Contact"
        icon={IconChat}
        link={externalLinks.tiledbContact}
      />
      <HelpDialogItem
        label="Feature request"
        icon={IconSparkles}
        link={externalLinks.tiledbFeedback}
      />
      <HelpDialogItem
        label="Privacy policy"
        icon={IconSecureShield}
        link={externalLinks.tiledbPrivacyPolicy}
      />
      <HelpDialogItem
        label="Terms of use"
        icon={IconBook}
        link={externalLinks.tiledbTerms}
      />
    </div>
  );
};

export default HelpDialog;
