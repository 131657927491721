import { ArrayTask } from 'api-client';
import { AxiosResponse } from 'axios';
import { createPath as createGetTaskQueryKey } from 'hooks/api/Tasks/useGetTaskDetails';
import queryClient from 'utils/helpers/queryClient';

type Params = Pick<ArrayTask, 'id'>;

const getArrayTaskFromCache = (params: Params): ArrayTask | undefined => {
  const { id } = params;
  const queryKey = createGetTaskQueryKey({
    id,
  });

  const res = queryClient
    .getQueryCache()
    .find<unknown, unknown, AxiosResponse<ArrayTask>>([queryKey], {
      exact: false,
    });

  return res?.state.data?.data;
};

export default getArrayTaskFromCache;
