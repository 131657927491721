import * as React from 'react';
import classNames from 'classnames';
import styles from './Indicator.module.scss';

type Props = {
  variant: 'info' | 'warning';
  className?: string;
  active: boolean;
};

const Indicator: React.FC<Props> = (props) => {
  const { variant, className, active } = props;
  return (
    <div
      className={classNames(className, styles.container, {
        [styles.active]: active,
      })}
    >
      <div className={classNames(styles.inner, styles[variant])}></div>
    </div>
  );
};

export default Indicator;
