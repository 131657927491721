import './Console.scss';
import React, { ReactElement, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import AuthGuard from 'components/AuthGuard';
import Header from 'components/Header';
import ErrorBoundary from 'components/ErrorBoundary';
import useAuthValue from 'hooks/useAuthValue';
import {
  arrayRouterPaths,
  overviewRouterPaths,
  invitationRouterPaths,
  mlModelRouterPaths,
  notebookRouterPaths,
  udfRouterPaths,
  fileRouterPaths,
  settingsRouterPaths,
  activityRouterPaths,
  taskgraphLogsRouterPaths,
  dashboardRouterPaths,
} from 'utils/routerPaths';
import { marketplaceLinks, overviewLinks, rootPath } from 'utils/links';
import groupRouterPaths from 'utils/routerPaths/groupRouterPaths';
import ModalContainer from 'containers/ModalContainer';
import taskGraphRouterPaths from 'utils/routerPaths/taskGraphRouterPaths';
import WorkspaceMenu from 'components/WorkspaceMenu';
import { useUserContext } from 'store/UserContext';
import { NamespaceActions, NamespaceType } from 'api-client';
import PermissionsGuard from 'components/PermissionsGuard/PermissionsGuard';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import ConditionalWrapper from 'components/ConditionalWrapper';
import JupyterContainer from 'containers/JupyterContainer';
import isJupyterDisabled from 'utils/helpers/isJupyterDisabled';
import useSessionStorage from 'hooks/useSessionStorage';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import useMount from 'hooks/useMount';
import lazyLoadComponent from 'utils/helpers/lazyLoadComponent';
import biomedicalImagingRouterPaths from 'utils/routerPaths/biomedicalImagingRouterPaths';
import somaRouterPaths from 'utils/routerPaths/somaRouterPaths';
import vcfRouterPaths from 'utils/routerPaths/vcfRouterPaths';
import pointCloudRouterPaths from 'utils/routerPaths/pointCloudRouterPaths';
import geometryRouterPaths from 'utils/routerPaths/geometryRouterPaths';
import academyRouterPaths from 'utils/routerPaths/academyRouterPaths';
import rasterRouterPaths from 'utils/routerPaths/rasterRouterPaths';
import vectorSearchRouterPaths from 'utils/routerPaths/vectorSearchRouterPaths';
import MainMenu from 'components/MainMenu';
import assetsRouterPaths from 'utils/routerPaths/assetsRouterPaths';
import monitorRouterPaths from 'utils/routerPaths/monitorRouterPaths';
import MainContent from './MainContent';
import marketplaceRouterPaths from 'utils/routerPaths/marketplaceRouterPaths';
import Onboarding from 'components/Onboarding';
import tasksRouterPaths from 'utils/routerPaths/tasksRouterPaths';
import pushPathnameToToHistoryStack from 'utils/helpers/history/pushPathnameToToHistoryStack';
import appsRouterPaths from 'utils/routerPaths/appsRouterPaths';
import usePendingNotification from './usePendingNotification';

const Overview = lazyLoadComponent(() => import('./Overview'));
const Tasks = lazyLoadComponent(() => import('./Tasks'));
const ArrayDetails = lazyLoadComponent(() => import('./Arrays/ArrayDetails'));
const NotebookDetails = lazyLoadComponent(
  () => import('./Notebooks/NotebookDetails')
);
const DashboardDetails = lazyLoadComponent(
  () => import('./Dashboards/DashboardDetails')
);
const UDFDetails = lazyLoadComponent(() => import('./UDFs/UDFDetails'));
const MLModelDetails = lazyLoadComponent(
  () => import('./MLModels/MLModelDetails')
);
const Activity = lazyLoadComponent(() => import('./Activity'));
const Invitations = lazyLoadComponent(() => import('./Invitations'));
const FileDetails = lazyLoadComponent(() => import('./Files/FileDetails'));
const GroupDetails = lazyLoadComponent(() => import('./Groups/GroupDetails'));
const TaskgraphsDetails = lazyLoadComponent(
  () => import('./Taskgraphs/TaskgraphDetails')
);
const Settings = lazyLoadComponent(() => import('./Settings'));
const BiomedicalImagingDetails = lazyLoadComponent(
  () => import('./BiomedicalImaging/BiomedicalImagingDetails')
);
const SOMADetails = lazyLoadComponent(() => import('./SOMA/SOMADetails'));
const VCFDetails = lazyLoadComponent(() => import('./VCF/VCFDetails'));
const PointCloudDetails = lazyLoadComponent(
  () => import('./PointCloud/PointCloudDetails')
);
const GeometryDetails = lazyLoadComponent(
  () => import('./Geometry/GeometryDetails')
);
const RasterDetails = lazyLoadComponent(() => import('./Raster/RasterDetails'));
const VectorSearchDetails = lazyLoadComponent(
  () => import('./VectorSearch/VectorSearchDetails')
);
const Assets = lazyLoadComponent(() => import('./Assets'));
const Compute = lazyLoadComponent(() => import('./Monitor'));
const Academy = lazyLoadComponent(() => import('./Academy'));
const Store = lazyLoadComponent(() => import('./Marketplace'));
const Apps = lazyLoadComponent(() => import('./Apps'));
const TaskgraphLogs = lazyLoadComponent(() => import('./TaskgraphLogs'));

const Console: React.FC = () => {
  const redirectTo = useAuthValue<string>(
    overviewLinks.root(),
    marketplaceLinks.root()
  );
  const { pathname, key } = useLocation();

  useEffect(() => {
    pushPathnameToToHistoryStack(pathname, { exact: false, key: key });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const { isLoggedIn } = useUserContext();
  const { workspace } = useWorkspaceContext();
  const [, , removeInvalidTokenKey] = useSessionStorage(
    sessionStorageKeys.INVALID_TOKEN
  );

  useMount(() => {
    if (isLoggedIn) {
      removeInvalidTokenKey();
    }
  });

  // Check for any pending notifications and display the informational modal if needed
  usePendingNotification();

  return (
    <ModalContainer>
      <div className="TDB-Console__OuterWrapper">
        <MainMenu />
        {isLoggedIn && (
          <>
            <WorkspaceMenu />
            {/**
             * We may disable jupyter in local dev or in e2e tests
             * Jupyter doesn't work in those environments
             */}
            {!isJupyterDisabled() && <JupyterContainer />}
          </>
        )}
        <MainContent>
          <Header />
          <div className="TDB-Console__inner-content">
            <ErrorBoundary>
              <Onboarding />
              <Suspense fallback={null}>
                <Switch>
                  <Redirect path={rootPath} exact to={redirectTo} />
                  <Route
                    path={overviewRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Overview />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={assetsRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Assets />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={monitorRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Compute />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={tasksRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Tasks />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={taskgraphLogsRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <TaskgraphLogs />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={arrayRouterPaths.details}
                    render={(): React.ReactNode => <ArrayDetails />}
                  />
                  <Route
                    path={academyRouterPaths.root}
                    render={(): React.ReactNode => <Academy />}
                  />
                  <Route
                    path={activityRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <ConditionalWrapper
                          condition={
                            workspace?.type === NamespaceType.Organization
                          }
                          left={(children) => (
                            <PermissionsGuard
                              requiredPermission={NamespaceActions.Write}
                              permissions={workspace?.allowedActions}
                              showFallback
                            >
                              {children}
                            </PermissionsGuard>
                          )}
                          right={(children) => children}
                        >
                          <Activity />
                        </ConditionalWrapper>
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={notebookRouterPaths.details}
                    render={(): React.ReactNode => <NotebookDetails />}
                  />
                  <Route
                    path={dashboardRouterPaths.details}
                    render={(): React.ReactNode => <DashboardDetails />}
                  />
                  <Route
                    path={udfRouterPaths.details}
                    render={(): React.ReactNode => <UDFDetails />}
                  />
                  <Route
                    path={mlModelRouterPaths.details}
                    render={(): React.ReactNode => <MLModelDetails />}
                  />
                  <Route
                    path={fileRouterPaths.details}
                    render={(): React.ReactNode => <FileDetails />}
                  />
                  <Route
                    path={settingsRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Settings />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={invitationRouterPaths.root}
                    render={(): React.ReactNode => <Invitations />}
                  />
                  <Route
                    path={groupRouterPaths.details}
                    render={(): React.ReactNode => <GroupDetails />}
                  />
                  <Route
                    path={biomedicalImagingRouterPaths.details}
                    render={(): React.ReactNode => <BiomedicalImagingDetails />}
                  />
                  <Route
                    path={somaRouterPaths.details}
                    render={(): React.ReactNode => <SOMADetails />}
                  />
                  <Route
                    path={vcfRouterPaths.details}
                    render={(): React.ReactNode => <VCFDetails />}
                  />
                  <Route
                    path={pointCloudRouterPaths.details}
                    render={(): React.ReactNode => <PointCloudDetails />}
                  />
                  <Route
                    path={geometryRouterPaths.details}
                    render={(): React.ReactNode => <GeometryDetails />}
                  />
                  <Route
                    path={rasterRouterPaths.details}
                    render={(): React.ReactNode => <RasterDetails />}
                  />
                  <Route
                    path={vectorSearchRouterPaths.details}
                    render={(): React.ReactNode => <VectorSearchDetails />}
                  />
                  <Route
                    path={taskGraphRouterPaths.details}
                    render={(): React.ReactNode => <TaskgraphsDetails />}
                  />
                  <Route
                    path={appsRouterPaths.root}
                    render={(): React.ReactNode => (
                      <AuthGuard>
                        <Apps />
                      </AuthGuard>
                    )}
                  />
                  <Route
                    path={marketplaceRouterPaths.root}
                    render={(): React.ReactNode => <Store />}
                  />
                  <Route render={(): ReactElement => <NotFound />} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </div>
        </MainContent>
      </div>
    </ModalContainer>
  );
};

export default Console;
