import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M34.6664 31.4148C34.6894 28.2503 33.4494 25.7475 30.976 23.9801C29.0657 22.6193 26.7474 21.9095 24.5051 21.2274C19.8342 19.7988 18.4792 19.0464 18.4792 16.793C18.4792 14.2829 21.7127 13.3898 24.4808 13.3898C26.4967 13.3898 28.8161 14.0222 30.2564 14.9592L33.1121 10.4977C31.2296 9.26659 28.6531 8.42684 26.109 8.16619V4H20.8413V8.45834C16.1588 9.4975 13.209 12.5849 13.209 16.793C13.209 19.7401 14.4229 22.0773 16.8102 23.7257C18.6259 24.9842 20.8404 25.66 22.9795 26.3142C27.5611 27.7113 29.4216 28.5626 29.4004 31.3819L29.4003 31.4027C29.4003 33.769 26.2918 34.6102 23.6287 34.6102C21.1131 34.6102 18.3775 33.498 16.8269 31.8443L13 35.4961C14.9638 37.5914 17.8169 39.0728 20.8412 39.6534V44H26.109V39.747C31.3123 38.9956 34.6612 35.8354 34.6664 31.4148Z"
  />,
  'pricing'
);
