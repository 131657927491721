import useGetNotebookStatus from 'hooks/api/Notebooks/useGetNotebookStatus';
import React, { useRef } from 'react';
import { useUserContext } from 'store/UserContext';
import errorHasStatusCode from 'utils/helpers/errorHasStatusCode';
import useJupyter, {
  JupyterStatus,
  notebookStatusSelector,
} from 'store/JupyterStore';
import { AxiosError } from 'axios';
import { ModelError, PodStatus } from 'api-client';

declare const global: {
  __DEV__: boolean;
};

const usePollingNotebookStatus = () => {
  const shouldPingForStatusRef = useRef(!global.__DEV__); // In DEV mode we shouldn't call /status endpoint.
  const { user } = useUserContext();
  const {
    status,
    setNotebookStatuses,
    setNotebookStatusError,
    setNotebookStatusLoading,
    shutDownJupyterNotebook,
    setSelectedRegion,
    setStatus,
  } = useJupyter((state) => ({
    status: state.status,
    setStatus: state.setStatus,
    shutDownJupyterNotebook: state.shutDownJupyterNotebook,
    setNotebookStatuses: state.setNotebookStatuses,
    setNotebookStatusError: state.setNotebookStatusError,
    setNotebookStatusLoading: state.setNotebookStatusLoading,
    setSelectedRegion: state.setSelectedRegion,
  }));

  const [, response, error, loading] = useGetNotebookStatus(
    {
      namespace: user.username,
    },
    {
      noSnackbar: true,
    },
    {
      refetchInterval: 10_000,
      /**
       * Set retries to zero, otherwise react-query won't show errors until
       * it retries for certain amount (3 is the default).
       */
      retry: 0,
      refetchIntervalInBackground: true,
      enabled: shouldPingForStatusRef.current,
    }
  );

  /**
   * Stop pinging for notebook status if the user has been logged out (token expired).
   * If user leaves the tab open for more than 8 hours, token expires.
   */
  React.useEffect(() => {
    if (errorHasStatusCode(403, error)) {
      shouldPingForStatusRef.current = false;
    }
  }, [error]);

  const notebookStatus = useJupyter(notebookStatusSelector);

  /**
   * If selected region is shutting down, update store's status to "ShuttingDown"
   */
  React.useEffect(() => {
    if (
      status !== JupyterStatus.ShuttingDown &&
      notebookStatus?.pod_status === PodStatus.TERMINATING
    ) {
      shutDownJupyterNotebook(notebookStatus.region);
    }
  }, [
    notebookStatus?.pod_status,
    notebookStatus?.region,
    shutDownJupyterNotebook,
    status,
  ]);

  React.useEffect(() => {
    if (response?.data.length && !error) {
      setNotebookStatuses(response?.data);

      /**
       * If there is only 1 region currently running and we don't launch another one, set that as active
       * During launching we want to wait for the server to start and not switch to the new region.
       */
      if (
        response.data.length === 1 &&
        response.data[0].region &&
        response.data[0].pod_status !== PodStatus.TERMINATING &&
        status !== JupyterStatus.Launching
      ) {
        setSelectedRegion(response.data[0].region);
      }
    } else {
      setNotebookStatuses([]);
    }
  }, [
    response,
    setNotebookStatuses,
    setSelectedRegion,
    setStatus,
    status,
    error,
  ]);

  React.useEffect(() => {
    setNotebookStatusLoading(loading);
  }, [loading, setNotebookStatusLoading]);

  React.useEffect(() => {
    setNotebookStatusError(error as AxiosError<ModelError> | undefined);
  }, [error, setNotebookStatusError]);
};

export default usePollingNotebookStatus;
