const geometryRouterPaths = {
  root: '/geometry',
  browser: '/geometry/:category(me|shared|public)',
  details: '/geometry/:namespace/:groupId',
  overview: '/geometry/:namespace/:groupId/overview',
  sharing: '/geometry/:namespace/:groupId/sharing',
  settings: '/geometry/:namespace/:groupId/settings',
  contents: '/geometry/:namespace/:groupId/contents',
  metadata: '/geometry/:namespace/:groupId/metadata',
  activity: '/geometry/:namespace/:groupId/activity',
};

export default geometryRouterPaths;
