/**
 * Formats number to localeString
 */
function formatNumber<T>(value: number | T, maxDecimals?: number): string | T {
  if (!value || isNaN(value as number)) {
    return value as T;
  }

  if (maxDecimals) {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: maxDecimals,
    });
  }

  return Number(value).toLocaleString();
}

export default formatNumber;
