const assetsLinks = {
  root: () => '/assets',
  data: {
    root: () => '/assets/data',
    my: () => '/assets/data/my',
    shared: () => '/assets/data/shared',
  },
  code: {
    root: () => '/assets/code',
    my: () => '/assets/code/my',
    shared: () => '/assets/code/shared',
  },
  arrays: {
    root: () => '/assets/arrays',
    my: () => '/assets/arrays/my',
    shared: () => '/assets/arrays/shared',
  },
  files: {
    root: () => '/assets/files',
    my: () => '/assets/files/my',
    shared: () => '/assets/files/shared',
  },
  vcf: {
    root: () => '/assets/vcf',
    my: () => '/assets/vcf/my',
    shared: () => '/assets/vcf/shared',
  },
  soma: {
    root: () => '/assets/soma',
    my: () => '/assets/soma/my',
    shared: () => '/assets/soma/shared',
  },
  biomedicalImaging: {
    root: () => '/assets/biomedical-imaging',
    my: () => '/assets/biomedical-imaging/my',
    shared: () => '/assets/biomedical-imaging/shared',
  },
  pointCloud: {
    root: () => '/assets/point-cloud',
    my: () => '/assets/point-cloud/my',
    shared: () => '/assets/point-cloud/shared',
  },
  geometry: {
    root: () => '/assets/geometry',
    my: () => '/assets/geometry/my',
    shared: () => '/assets/geometry/shared',
  },
  raster: {
    root: () => '/assets/raster',
    my: () => '/assets/raster/my',
    shared: () => '/assets/raster/shared',
  },
  vectorSearch: {
    root: () => '/assets/vector-search',
    my: () => '/assets/vector-search/my',
    shared: () => '/assets/vector-search/shared',
  },
  notebooks: {
    root: () => '/assets/notebooks',
    my: () => '/assets/notebooks/my',
    shared: () => '/assets/notebooks/shared',
  },
  dashboards: {
    root: () => '/assets/dashboards',
    my: () => '/assets/dashboards/my',
    shared: () => '/assets/dashboards/shared',
  },
  udfs: {
    root: () => '/assets/udfs',
    my: () => '/assets/udfs/my',
    shared: () => '/assets/udfs/shared',
  },
  taskGraphs: {
    root: () => '/assets/task-graphs',
    my: () => '/assets/task-graphs/my',
    shared: () => '/assets/task-graphs/shared',
  },
  mlModels: {
    root: () => '/assets/ml-models',
    my: () => '/assets/ml-models/my',
    shared: () => '/assets/ml-models/shared',
  },
  groups: {
    root: () => '/assets/groups',
    my: () => '/assets/groups/my',
    shared: () => '/assets/groups/shared',
  },
};

export default assetsLinks;
