import useInterval from 'hooks/useInterval/useInterval';
import useJupyter, { JupyterStatus } from 'store/JupyterStore';
import useOnJupyterIncomingMessage from '../useOnJupyterIncomingMessage';

interface WaitForNotebookShutDown {
  callback: () => void;
  reRenderIframe: () => void;
}

type useWaitHook = (props: WaitForNotebookShutDown) => void;

/**
 * Hook that tracks when a notebook pod has shut down completely.
 * Even though we might receive 404 from status route, it doesn't mean the
 * pod shut down completely. That's why we use a hidden iframe and reload it
 * every 10sec, waiting when hub spawn options are ready again.
 * If message comes from the hidden iframe, it means we can safely reload
 * main iframe to show the spawn options page.
 */
const useWaitForJupyterhubToBeReadyAfterShutdown: useWaitHook = ({
  callback,
  reRenderIframe,
}) => {
  const status = useJupyter((state) => state.status);

  useOnJupyterIncomingMessage({
    onJupyterSpawnPageReady: () => {
      /**
       * When we get "spawn_options_ready" message during shutting down,
       * it means notebook has shut down completely.
       */
      if (status === JupyterStatus.ShuttingDown) {
        callback();
      }
    },
  });

  useInterval(() => {
    if (
      status === JupyterStatus.ShuttingDown ||
      status === JupyterStatus.NotRunning ||
      status === JupyterStatus.ServiceUnavailable ||
      status === JupyterStatus.ErrorEncountered
    ) {
      /**
       * Update key to force hidden iframe to reload, until pod
       * has shut down and hub options page can be displayed
       */
      reRenderIframe();
    }
  }, 10_000);
};

export default useWaitForJupyterhubToBeReadyAfterShutdown;
