const activityRouterPaths = {
  root: '/activity',
  tasks: '/activity/tasks',
  taskDetails: '/activity/tasks/:taskId',
  activityDetails: '/activity/:namespace/:arrayId/:activityId',
  taskGraphLogs: '/activity/taskgraphs',
  taskGraphLogDetails: '/activity/taskgraphs/:namespace/:taskGraphId',
};

export default activityRouterPaths;
