import { useCallback } from 'react';
import { Theme } from 'store/ThemeContext';
import IframeActions from 'utils/constants/IframeActions';

const useJupyterOutgoingMessages = (
  iframe: React.MutableRefObject<HTMLIFrameElement | null>
) => {
  const selectServerAndImageProfilesAndSubmit = useCallback(
    (
      fileProperties: Record<string, string>,
      callback: () => void,
      opts?: { preventSubmission?: boolean }
    ) => {
      const data = {
        action: IframeActions.TILEDB_AUTOLOAD_IMAGE,
        payload: {
          profile: fileProperties.size,
          image: fileProperties.image,
          preventSubmission: opts?.preventSubmission,
        },
      };
      iframe.current?.contentWindow?.postMessage(data, '*');
      callback();
    },
    [iframe]
  );

  const preSelectServerAndImageProfiles = useCallback(
    (fileProperties: Record<string, string>, callback?: () => void) => {
      const data = {
        action: IframeActions.TILEDB_AUTOLOAD_IMAGE,
        payload: {
          profile: fileProperties.size,
          image: fileProperties.image,
          preventSubmission: true,
        },
      };
      iframe.current?.contentWindow?.postMessage(data, '*');
      callback?.();
    },
    [iframe]
  );

  const sendMessageThatUserHasNoCredits = useCallback(() => {
    iframe.current?.contentWindow?.postMessage(
      IframeActions.TILEDB_NO_CREDITS,
      '*'
    );
  }, [iframe]);

  const sendMessageToRequestToHideSubmitButton = useCallback(() => {
    const data = {
      action: IframeActions.TILEDB_RENDER_NO_SUBMIT_BUTTON,
    };

    iframe.current?.contentWindow?.postMessage(data, '*');
  }, [iframe]);

  const sendMessageWithCurrentTheme = useCallback(
    (theme: Theme) => {
      iframe.current?.contentWindow?.postMessage(theme, '*');
    },
    [iframe]
  );

  const sendMessageToRequestUserSelection = useCallback(() => {
    const data = {
      action: IframeActions.TILEDB_REQUEST_SELECTIONS,
    };
    iframe.current?.contentWindow?.postMessage(data, '*');
  }, [iframe]);

  const sendMessageCredentialsUpdated = useCallback(() => {
    iframe.current?.contentWindow?.postMessage(
      IframeActions.TILEDB_UPDATED_CREDENTIALS,
      '*'
    );
  }, [iframe]);

  const sendMessageToRequestToOpenNotebook = useCallback(
    (payload: {
      notebookName: string;
      namespace: string;
      ownership: 'owned' | 'shared' | 'public';
    }) => {
      const { notebookName, namespace, ownership } = payload;

      iframe.current?.contentWindow?.postMessage(
        `tiledb_goto::cloud/${ownership}/${namespace}/${notebookName}.ipynb`,
        '*'
      );
    },
    [iframe]
  );

  return {
    selectServerAndImageProfilesAndSubmit,
    preSelectServerAndImageProfiles,
    sendMessageWithCurrentTheme,
    sendMessageToRequestToOpenNotebook,
    sendMessageThatUserHasNoCredits,
    sendMessageCredentialsUpdated,
    sendMessageToRequestUserSelection,
    sendMessageToRequestToHideSubmitButton,
  };
};

export default useJupyterOutgoingMessages;
