import React from 'react';
import UserOnboarding from './UserOnboarding';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import OrganizationOnboarding from './OrganizationOnboarding';
import useDeleteOnboardingItems from 'hooks/api/Onboarding/useDeleteOnboardingItems';
import { useUserContext } from 'store/UserContext';
import { useOnboardingContext } from 'store/OnboardingContext';

const Onboarding: React.FC = () => {
  const { user } = useUserContext();
  const { isUserWorkspace, isOrganizationWorkspace } = useWorkspaceContext();
  const [deleteOnboardingItems] = useDeleteOnboardingItems();
  const { onboardingLoading } = useOnboardingContext();

  // Prevent onboarding from rendering until all data are here
  if (onboardingLoading) {
    return null;
  }

  /**
   * create a global function to reset onboarding for testing & other purposes
   * we need to provide this (mostly for QA) since there is no other way to delete onboarding in the UI
   */
  global.resetOnboarding = () => {
    deleteOnboardingItems([{ namespace: user.username }])
      .then(() => {
        // eslint-disable-next-line
          console.log(`Onboarding for namespace "${user.username}" cleared!`);
      })
      .catch(() => {
        // eslint-disable-next-line
          console.error('Could not clear onboarding.');
      });
  };

  if (isUserWorkspace) {
    return <UserOnboarding />;
  }

  if (isOrganizationWorkspace) {
    return <OrganizationOnboarding />;
  }

  return null;
};

export default Onboarding;
