import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M37.8462 22.339V39.3846C37.8462 40.2328 37.1559 40.9231 36.3077 40.9231H8.61539C7.76718 40.9231 7.07692 40.2328 7.07692 39.3846V14.7692C7.07692 13.921 7.76718 13.2308 8.61539 13.2308H25.661C25.5887 12.7272 25.5385 12.2159 25.5385 11.6923C25.5385 11.1687 25.5887 10.6574 25.661 10.1538H8.61539C6.07077 10.1538 4 12.2246 4 14.7692V39.3846C4 41.9292 6.07077 44 8.61539 44H36.3077C38.8523 44 40.9231 41.9292 40.9231 39.3846V21.4113C39.9662 21.8667 38.9333 22.1831 37.8462 22.339Z" />
    <path d="M36.3405 19.319C40.5707 19.319 44 15.8897 44 11.6595C44 7.42927 40.5707 4 36.3405 4C32.1103 4 28.681 7.42927 28.681 11.6595C28.681 15.8897 32.1103 19.319 36.3405 19.319Z" />
  </>,
  'notification'
);
