import { AxiosResponse } from 'axios';
import {
  ArrayTasksApi_getArrayTask,
  ArrayTasksApi_getArrayTask$Params,
  ArrayTasksApi_getArrayTask$Path,
  ArrayTasksApi_getArrayTask$Response,
} from 'api-client';
import replacePathParams from 'utils/helpers/replacePathParams';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

export const createPath = (data: ArrayTasksApi_getArrayTask$Params): string =>
  replacePathParams(ArrayTasksApi_getArrayTask$Path, { id: data.id });

const useGetTaskDetails: UseRequestType<
  ArrayTasksApi_getArrayTask$Params,
  ArrayTasksApi_getArrayTask$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<ArrayTasksApi_getArrayTask$Response>,
    string
  >(
    [createPath(queryData), queryData],
    useQueryRequest(ArrayTasksApi_getArrayTask, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetTaskDetails;
