import * as Sentry from '@sentry/react';

const { REACT_APP_SENTRY_RELEASE } = process.env;

/**
 * Initiates Sentry
 */
function setupSentry(): void {
  const { SENTRY_DSN, SENTRY_ENVIRONMENT } = global; // from window object

  const isValidDSN = SENTRY_DSN.match(/^https:\/\/.*@sentry.io.*/);

  if (isValidDSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: REACT_APP_SENTRY_RELEASE,
      environment: SENTRY_ENVIRONMENT,
      ignoreErrors: [
        /**
         * Ignore exceptions for failed requests
         */
        /^Request failed with status code/,
      ],
    });
  }
}

export default setupSentry;
