import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.0174 4C13.6429 4 4 12.9375 4 24C4.01979 25.0052 4.08474 25.9825 4.20848 26.8497C4.58164 29.2937 5.90709 29.8662 7.00606 29.7689C7.56493 29.7194 8.06669 29.4597 8.39616 29.0391C8.70954 28.639 8.9037 28.0844 8.77842 27.2667C8.75219 27.1535 8.7425 27.0272 8.72632 26.9192C8.54479 25.982 8.41355 25.0058 8.41355 24C8.41355 15.4002 15.4194 8.43095 24.0174 8.43095C32.5972 8.43095 39.5865 15.4002 39.5865 24C39.5865 32.5816 32.599 39.5691 24.0174 39.5691C22.0816 39.5691 20.2465 39.2233 18.5613 38.596C15.7917 37.4239 13.988 41.1698 16.8063 42.6621C19.0014 43.6996 21.8391 43.9843 24.0174 44C35.0605 44 44 35.0626 44 24C43.8101 12.4069 33.8881 4.16408 24.0174 4Z"
  />,
  'load-circle'
);
