import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M43 8C43 5.79 41.21 4 39 4H13C10.79 4 9 5.79 9 8V10H7C5.896 10 5 10.896 5 12V16C5 17.104 5.896 18 7 18H9V30H7C5.896 30 5 30.896 5 32V36C5 37.104 5.896 38 7 38H9V40C9 42.21 10.79 44 13 44H39C41.21 44 43 42.21 43 40V8ZM31.728 16.726C32.372 16.082 33.382 16.03 34.088 16.566L34.272 16.728L40.272 22.728C40.918 23.372 40.972 24.382 40.432 25.088L40.272 25.272L34.272 31.272C33.568 31.976 32.43 31.976 31.728 31.272C31.082 30.628 31.028 29.618 31.568 28.912L31.728 28.728L36.454 24L31.728 19.272C31.082 18.628 31.028 17.618 31.568 16.912L31.728 16.726ZM18.128 16.726C18.832 16.026 19.97 16.026 20.672 16.726V16.728C21.318 17.372 21.372 18.382 20.832 19.088L20.672 19.272L15.946 24L20.672 28.728C21.318 29.372 21.372 30.382 20.832 31.088L20.672 31.272C20.028 31.918 19.018 31.972 18.312 31.432L18.128 31.272L12.128 25.272C11.482 24.628 11.428 23.618 11.968 22.912L12.128 22.726L18.128 16.726ZM28.636 14.2539C27.674 14.0119 26.694 14.5999 26.454 15.5639H26.452L22.452 31.5639L22.412 31.7859C22.304 32.6739 22.874 33.5239 23.764 33.7459C24.728 33.9879 25.706 33.3999 25.948 32.4359L29.948 16.4359L29.988 16.2139C30.096 15.3259 29.526 14.4759 28.636 14.2539Z"
  />,
  'notebook'
);
