const arrayRouterPaths = {
  root: '/auth',
  login: '/auth/login',
  signup: '/auth/signup',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset_password',
  emailNotConfirmed: '/auth/email-not-confirmed',
  confirmEmail: '/auth/confirm_email',
  companySSO: '/auth/sso/company',
  ssoProvider: '/auth/sso/callback/:provider',
  logout: '/logout',
};

export default arrayRouterPaths;
