import replacePathParams from 'utils/helpers/replacePathParams';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  GroupsApi_getGroup,
  GroupsApi_getGroup$Params,
  GroupsApi_getGroup$Path,
  GroupsApi_getGroup$Response,
} from 'api-client';

export const createPath = (data: GroupsApi_getGroup$Params): string =>
  replacePathParams(GroupsApi_getGroup$Path, {
    group_name: data.groupName,
    group_namespace: data.groupNamespace,
  });

const useGetGroup: UseRequestType<
  GroupsApi_getGroup$Params,
  GroupsApi_getGroup$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<GroupsApi_getGroup$Response>,
    GroupsApi_getGroup$Params
  >(
    [createPath(queryData), queryData],
    useQueryRequest(GroupsApi_getGroup, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetGroup;
