import React, { useState } from 'react';
import { Placement } from 'react-joyride';
import Tour from 'components/Tour';
import { useOnboardingContext } from 'store/OnboardingContext';
import useAddCompletedOnboardingItems from 'hooks/api/Onboarding/useAddCompletedOnboardingItems';
import { OnboardingItem } from 'api-client';
import { useUserContext } from 'store/UserContext';
import useModal from 'store/ModalStore';
import useMount from 'hooks/useMount';
import useWorkspaceStore from 'store/WorkspaceSelectedStore';

const steps = [
  {
    target: '[organization-basic-tour-step="0"]',
    title: 'Your org assets',
    content:
      'Store, catalog and govern all your assets in a single data platform. TileDB has all the functionality you need to build a single data infrastructure for your organization.',
    placement: 'right' as Placement,
  },
  {
    target: '[organization-basic-tour-step="1"]',
    title: 'Your organization profile',
    content:
      'All your profile info for your organizations are gathered in the profile tab.',
    placement: 'right' as Placement,
  },
];

const BasicTour = () => {
  const { user } = useUserContext();
  const { refetchCompletedOnboardingItems } = useOnboardingContext();
  const workspaceSelected = useWorkspaceStore(
    (state) => state.workspaceSelected
  );
  const [addOnboardingItems] = useAddCompletedOnboardingItems();
  const [tourVisible, setTourVisible] = useState(false);
  const [active, setActive] = useState(true);
  const showWelcomeToOrganizationModal = useModal(
    (state) => state.showWelcomeToOrganizationModal
  );
  const flushModals = useModal((state) => state.flushModals);
  const addOnboardingItemsData = {
    namespace: user.id,
    items: {
      entries: [OnboardingItem.OrganizationBasicTour],
    },
  };

  const onFinish = async () => {
    await addOnboardingItems([addOnboardingItemsData]);
    refetchCompletedOnboardingItems();
  };

  const onDismiss = async () => {
    setActive(false);
    await addOnboardingItems([addOnboardingItemsData]);
    refetchCompletedOnboardingItems();
  };

  useMount(() => {
    if (workspaceSelected?.state?.isNewOrganization) {
      showWelcomeToOrganizationModal({
        onSuccess: () => {
          setTourVisible(true);
          flushModals();
        },
      });
    } else {
      setTourVisible(true);
    }
  });

  if (!tourVisible) {
    return null;
  }

  return (
    <Tour
      steps={steps}
      active={active}
      onFinish={onFinish}
      onDismiss={onDismiss}
      startWithDelay={1000}
      dismissable
    />
  );
};

export default BasicTour;
