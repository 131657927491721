import React from 'react';
import useIsUserInJupyterPage from 'hooks/jupyter/useIsUserInJupyterPage';
import useModal, { Modals } from 'store/ModalStore';
import useJupyter, { JupyterStatus } from 'store/JupyterStore';
import inIframe from 'utils/helpers/inIframe';
import useDocumentVisibility from 'hooks/useDocumentVisibility';

/**
 * Hook that returns if the hidden jupyter iframe should render or not.
 * We hide this hidden iframe either in development mode, or when there is already a jupyter iframe
 * (e.g. when user opens a modal to select jupyter server to launch).
 * In the latter, we avoid rendering 2 iframes to fix issues with Oauth Jupyterhub login flows happening in parallel
 * and as a result jupyterhub returns "Oauth state mismatch" error.
 */
const useShouldRenderHiddenIframe = () => {
  const { userIsInJupyterNotebookOrDashaboard } = useIsUserInJupyterPage();
  const jupyterStatus = useJupyter((state) => state.status);
  const documentVisible = useDocumentVisibility();
  const modals = useModal((state) => state.modals);
  const pageIsRenderedInsideIframe = inIframe();
  /**
   * One of the modals that enable user to select a server profile to launch, is currently active
   */
  const modalLaunchServerIsActive = React.useMemo(() => {
    return modals.some(
      (m) =>
        m.name === Modals.ModalLaunchServer ||
        m.name === Modals.ModalSelectRegionAndServerProfile ||
        m.name === Modals.ModalSelectRegion
    );
  }, [modals]);

  if (
    pageIsRenderedInsideIframe ||
    (userIsInJupyterNotebookOrDashaboard &&
      jupyterStatus === JupyterStatus.Running) ||
    modalLaunchServerIsActive ||
    !documentVisible
  ) {
    return false;
  }

  return true;
};

export default useShouldRenderHiddenIframe;
