import { MutateResponse, useMutateRequest } from 'hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import {
  UserApi_setOnboardingItems,
  UserApi_setOnboardingItems$Params,
  UserApi_setOnboardingItems$Response,
} from 'api-client';

const useAddCompletedOnboardingItems = (): MutateResponse<
  UserApi_setOnboardingItems$Params,
  UserApi_setOnboardingItems$Response
> => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    UserApi_setOnboardingItems
  );

  return [
    useMutateRequest(mutateAsync),
    data,
    error as null | Error,
    isLoading,
  ];
};

export default useAddCompletedOnboardingItems;
