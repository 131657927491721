import { NotebookSize } from 'api-client';
import { stringify } from 'query-string';

export interface AppsSearchParams {
  name?: string;
  id?: string;
  namespace?: string;
  size?: NotebookSize;
  dashboard?: boolean;
}

const appsLinks = {
  root: () => '/apps',
  server: (
    params: {
      namespace: string;
      arrayId: string;
      arrayName: string;
      dashboard?: boolean;
      size?: NotebookSize;
    } | void
  ) => {
    if (!params) {
      return '/apps/server';
    }

    const searchParams: AppsSearchParams = {
      namespace: params.namespace,
      id: params.arrayId,
      name: params.arrayName,
      ...(params.dashboard
        ? {
            dashboard: true,
          }
        : {}),
    };

    const qString = stringify(searchParams);
    return `/apps/server?${qString}`;
  },
};

export default appsLinks;
