import CustomError from '../CustomError';

class NotFoundError extends CustomError {
  static type = 'NOT_FOUND';

  constructor(message?: string) {
    super(message);
    this._type = NotFoundError.type;
  }
}

export default NotFoundError;
