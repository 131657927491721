/* eslint-disable */
/*
 * Instantiates google analytics and hotjar (only in production)
 */

const { REACT_APP_TILEDB_ENV } = process.env;

function setupAnalytics() {
  /**
   * Do nothing in non-production environments
   */
  if (REACT_APP_TILEDB_ENV !== 'production') {
    return;
  }

  const { HOTJAR_ID, GOOGLE_ANALYTICS_ID } = global; // from window object

  if (HOTJAR_ID) {
    // Setup Hotjar
    (function(h, o, t, j, a, r) {
      h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
      h._hjSettings = { hjid: HOTJAR_ID, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  if (GOOGLE_ANALYTICS_ID) {
    // Google analytics integration
    (function() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', GOOGLE_ANALYTICS_ID, {
        send_page_view: true
      });
      window.gtag = gtag;
    })();
  }
}

export default setupAnalytics;
