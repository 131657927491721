const vectorSearchRouterPaths = {
  root: '/vector-search',
  details: '/vector-search/:namespace/:groupId',
  overview: '/vector-search/:namespace/:groupId/overview',
  sharing: '/vector-search/:namespace/:groupId/sharing',
  settings: '/vector-search/:namespace/:groupId/settings',
  contents: '/vector-search/:namespace/:groupId/contents',
  metadata: '/vector-search/:namespace/:groupId/metadata',
  activity: '/vector-search/:namespace/:groupId/activity',
};

export default vectorSearchRouterPaths;
