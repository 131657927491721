import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.5555 8.63711C22.9063 6.45169 25.0996 6.4569 26.4472 8.63711L43.1912 35.7269C44.9945 38.6445 43.6809 41.0096 40.259 41.0096H7.74375C4.32103 41.0096 3.0024 38.6539 4.81151 35.7269L21.5555 8.63711ZM24.0019 17.7218C23.1445 17.7218 22.4494 18.4252 22.4494 19.2697V28.594C22.4494 29.4489 23.1385 30.142 24.0019 30.142C24.8594 30.142 25.5545 29.4386 25.5545 28.594V19.2697C25.5545 18.4148 24.8653 17.7218 24.0019 17.7218ZM24.0019 36.352C24.8594 36.352 25.5545 35.6569 25.5545 34.7995C25.5545 33.9421 24.8594 33.247 24.0019 33.247C23.1445 33.247 22.4494 33.9421 22.4494 34.7995C22.4494 35.6569 23.1445 36.352 24.0019 36.352Z"
  />,
  'warning'
);
