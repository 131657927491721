import { GlobalVariable } from '../constants/GlobalVariable';
declare const global: any;

/**
 * Cleans up any variables in html that has not been set by golang
 *
 * example:
 * var n = "{{ .TemplateSyntax }}"
 * will become:
 * var n = ""
 *
 * This makes the value falsy
 */
const cleanupUnsetGlobalVariables = () => {
  Object.keys(GlobalVariable).forEach((key: string) => {
    const value = global[key];
    /**
     * Overwrite the runtime global variables that are not set, with the ones from env (if set)
     *
     * When running the UI with the dev server,
     * the runtimes global variables are not set, since the html is not served by GO
     * We can use environment variables to set valid values to those global variables for local development
     */
    if (value && value.startsWith('{{ .') && global.__DEV__) {
      global[key] = process.env[`REACT_APP_${key}`] || '';
    }
  });
};

const setDefaultGlobalVariables = () => {
  if (__DEV__) {
    /**
     * Disable jupyter in development mode since it doesn't work and causes bugs
     */
    if (!process.env.REACT_APP_LOCAL_JUPYTER) {
      global.JUPYTER_DISABLED = 'true';
    } else {
      global.JUPYTERHUB_URL = process.env.REACT_APP_LOCAL_JUPYTER;
    }

    /**
     * If we are in development mode and run with staging proxy, we override REST_SERVER_URL to staging,
     * otherwise use the local dev server.
     */
    if (
      process.env.REACT_APP_DEV_SERVER_PROXY_TARGET ===
      'https://console.dev.tiledb.io'
    ) {
      global.REST_SERVER_URL = 'https://api.dev.tiledb.io';
    } else {
      global.REST_SERVER_URL = 'http://rest-server:8181';
    }

    /**
     * Set Academy iframe url when developing locally
     */
    if (process.env.REACT_APP_ACADEMY_URL) {
      global.ACADEMY_URL =
        process.env.REACT_APP_ACADEMY_URL || global.ACADEMY_URL;
    }
  }
};

export { cleanupUnsetGlobalVariables, setDefaultGlobalVariables };
