import { create } from 'zustand';
import { SnackbarProps } from 'components/Snackbar';
import generateUniqueId from 'utils/helpers/generateUniqueId';

export interface ShowSnackbarProps
  extends Omit<Omit<SnackbarProps, 'id'>, 'onDismiss'> {
  timeout?: NodeJS.Timeout;
}

export interface StateSnackbarType extends ShowSnackbarProps {
  id: string;
}

export type ShowSnackbarType = (newSnackbar: ShowSnackbarProps) => void;
interface SnackbarState {
  snackbars: StateSnackbarType[];
  dismissSnackbar: (id: string) => void;
  showSnackbar: ShowSnackbarType;
}

const useStore = create<SnackbarState>((set, get) => ({
  snackbars: [],
  dismissSnackbar: (snackbarId: string) =>
    set((state: SnackbarState) => ({
      snackbars: state.snackbars.filter(
        (snackbar: StateSnackbarType) => snackbar.id !== snackbarId
      ),
    })),
  showSnackbar: (newSnackbar: ShowSnackbarProps) => {
    /**
     * Snackback expiry in ms. Note that this should match the animation in the
     * snackbar's CSS.
     */
    const timeout = 10_000;
    const snackbars = get().snackbars as StateSnackbarType[];
    const dismissSnackbar = get().dismissSnackbar;
    const existingSnackbar = snackbars.find(
      (sn) =>
        sn.title === newSnackbar.title &&
        sn.description === newSnackbar.description
    );

    if (!existingSnackbar) {
      const uniqueId = generateUniqueId();
      /**
       * Auto hide snackbar if dismiss mode is auto
       */
      const snackbar = { ...newSnackbar, id: uniqueId };

      if (newSnackbar.dismissMode !== 'manual') {
        snackbar.timeout = setTimeout(() => {
          dismissSnackbar(uniqueId);
        }, timeout);
      }

      snackbar.timesCalled = 0;

      return set((state: SnackbarState) => ({
        snackbars: [...state.snackbars, snackbar],
      }));
    }
    /**
     * Auto hide snackbar if dismiss mode is auto
     * If snackbar already exists clear old timeout and set a new one
     */
    if (existingSnackbar.timeout) {
      clearTimeout(existingSnackbar.timeout as NodeJS.Timeout);
      existingSnackbar.timeout = setTimeout(() => {
        dismissSnackbar(existingSnackbar.id);
      }, timeout);
    }
    /**
     * timesCalled is used as a key inside snackbar component
     * This will re-trigger the timer animation when setTimeout resets
     */
    existingSnackbar.timesCalled = (existingSnackbar.timesCalled as number) + 1;
    const restSnackBars = snackbars.filter(
      (sn) =>
        sn.title !== newSnackbar.title &&
        sn.description !== newSnackbar.description
    );

    return set((state) => ({
      snackbars: [...restSnackBars, existingSnackbar],
    }));
  },
}));

export default useStore;
