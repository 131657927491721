import React, { PureComponent } from 'react';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

function withErrorBoundary<P extends {}>(ChildComponent: React.FC<P>) {
  return class WithErrorBoundary extends PureComponent<P> {
    render(): JSX.Element {
      return (
        <ErrorBoundary>
          <ChildComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  };
}

export default withErrorBoundary;
