import { Notification, NotificationType } from 'api-client';
import { taskgraphLogsLinks } from 'utils/links';
import invitationLinks from 'utils/links/invitationLinks';

const getNotificationLink = (notification: Notification): string => {
  switch (notification.type) {
    case NotificationType.Invitation:
      return invitationLinks.accept({
        invitationId: notification.notification_event?.invitation
          ?.invitation_id as string,
      });
    case NotificationType.Taskgraph:
      return taskgraphLogsLinks.details({
        namespace: notification.notification_event?.taskgraph
          ?.namespace as string,
        taskGraphId: notification.notification_event?.taskgraph?.uuid as string,
      });
    default:
      return '';
  }
};

export default getNotificationLink;
