import { useState } from 'react';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';

function useSessionStorage(
  key: sessionStorageKeys,
  initialValue?: string
): [string | undefined, Function, Function] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: string): void => {
    try {
      setStoredValue(value);
      sessionStorage.setItem(key, value);
    } catch (error) {}
  };

  const removeValue = (): void => {
    try {
      setStoredValue(undefined);
      sessionStorage.removeItem(key);
    } catch (error) {}
  };

  return [storedValue, setValue, removeValue];
}

export default useSessionStorage;
