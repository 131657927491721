import * as React from 'react';
import Dialog from 'components/Dialog';
import WorkspaceOption from '../WorkspaceOption';
import IconTheme from 'components/Svg/icons/IconTheme';
import IconCarretRight from 'components/Svg/icons/IconCarretRight';
import { Icon } from 'components/Svg';
import Typography from 'components/Typography';
import IconCheckMark from 'components/Svg/icons/IconCheckMark';
import { Theme } from 'store/ThemeContext';
import useTheme from 'hooks/useTheme';
import { capitalizeFirstLetter } from 'utils/helpers/string';
import IconSun from 'components/Svg/icons/IconSun';
import IconMoon from 'components/Svg/icons/IconMoon';
import './WorkspaceThemeSelector.scss';

const WorkspaceThemeSelector: React.FC = () => {
  return (
    <Dialog
      className="TDB-WorkspaceThemeSelector"
      strategy="fixed"
      trigger="hover"
      interactive
      content={
        <div className="TDB-WorkspaceThemeSelector__menu">
          <WorkspaceThemeItem icon={IconSun} theme={Theme.Light} />
          <WorkspaceThemeItem icon={IconMoon} theme={Theme.Dark} />
          <WorkspaceThemeItem icon={IconTheme} theme={Theme.System} />
        </div>
      }
      placement="right-end"
    >
      <WorkspaceOption
        label="Theme"
        icon={IconTheme}
        rightIcon={IconCarretRight}
      />
    </Dialog>
  );
};

export default WorkspaceThemeSelector;

type ThemeItemProps = {
  theme: Theme;
  icon: Icon;
  onClick?: () => void;
};

const WorkspaceThemeItem: React.FC<ThemeItemProps> = (props) => {
  const { icon: MainIcon, theme, onClick } = props;
  const { themeTabSelected: currentTheme, changeTheme } = useTheme();
  const active = currentTheme === theme;

  return (
    <div
      onClick={() => {
        changeTheme(theme);
        onClick?.();
      }}
      className="TDB-WorkspaceThemeItem"
    >
      <div className="TDB-WorkspaceThemeItem__content">
        <MainIcon size={16} />
        <Typography>{capitalizeFirstLetter(theme)}</Typography>
      </div>
      {active && <IconCheckMark colorToken="color-text-default" size={16} />}
    </div>
  );
};
