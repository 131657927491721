import { create } from 'zustand';
import { NotificationStatus } from 'api-client';
import useGetNotificationCount from 'hooks/api/Notifications/useGetNotificationCount';
import { queryKey as listNotificationsQueryKey } from 'hooks/api/Notifications/useGetNotifications';
import { useEffect } from 'react';
import { useUserContext } from 'store/UserContext';
import useUserPreferencesStore from 'store/UserPreferencesStore';
import { isNumber } from 'utils/helpers/number';
import queryClient from 'utils/helpers/queryClient';

interface InboxStore {
  unreadCount: number | undefined;
  setUnreadCount: (count: number) => void;
}

const useStore = create<InboxStore>((set) => ({
  unreadCount: undefined,
  setUnreadCount: (count: number) => {
    return set((state: InboxStore) => ({
      unreadCount: count,
    }));
  },
}));

export const useInboxPolling = () => {
  const { isLoggedIn, user } = useUserContext();
  const notificationsSnoozed = useUserPreferencesStore(
    (state) => state.notificationsSnoozed
  );
  const unreadCount = useStore((state) => state.unreadCount);
  const setUnreadCount = useStore((state) => state.setUnreadCount);
  const shouldDoPolling =
    isLoggedIn && // non-logged in users have no notifications
    !notificationsSnoozed && // notifications may be snoozed by the user
    !process.env.REACT_APP_DISABLE_NOTIFICATION_POLLING; // for local development
  const [, unreadCountResponse] = useGetNotificationCount(
    {
      namespace: user.id,
      status: NotificationStatus.Unread,
    },
    {},
    {
      enabled: isLoggedIn,
      refetchInterval: shouldDoPolling ? 10_000 : false,
    }
  );

  // update count on response
  useEffect(() => {
    const newUnreadCount = unreadCountResponse?.data;
    if (isNumber(unreadCountResponse?.data)) {
      setUnreadCount(newUnreadCount as number);

      // invalidate list notification queries if new notification is available
      if (unreadCount !== newUnreadCount) {
        queryClient.invalidateQueries([listNotificationsQueryKey]);
      }
    }
  }, [unreadCount, setUnreadCount, unreadCountResponse?.data]);

  return null;
};

export default useStore;
