import React, { useRef, useEffect } from 'react';
import './Loader.scss';
import Lottie, { AnimationItem } from 'lottie-web';
import loaderDotsOnly from 'lotties/loader-dots-only.json';
import loaderBracketsT from 'lotties/loader-brackets-t.json';
import classNames from 'classnames';

type LoaderType = 'dots-only' | 'brackets-t';

export interface LoaderProps {
  size?: number | string;
  className?: string;
  speed?: number;
  type?: LoaderType;
  'data-testid'?: string;
}

export function getAnimationData(type: LoaderType): object {
  return {
    'dots-only': loaderDotsOnly,
    'brackets-t': loaderBracketsT,
  }[type];
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const { size, className, type = 'brackets-t', speed = 0 } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const lottie = useRef<AnimationItem>();

  useEffect(() => {
    lottie.current = Lottie.loadAnimation({
      container: divRef.current as HTMLDivElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: getAnimationData(type),
    });

    lottie.current.setSpeed(speed);
  }, [divRef, lottie, speed, type]);

  return (
    <div
      className={classNames('TDB-Loader', className)}
      data-testid="TDB-Loader"
      aria-busy="true"
      aria-live="polite"
      aria-label="Loading"
    >
      <div
        className="d-flex align-center justify-center"
        ref={divRef}
        style={{ width: size }}
      ></div>
    </div>
  );
};

Loader.defaultProps = {
  size: 64,
  speed: 1,
};

export default React.memo(Loader);
