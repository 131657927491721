import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import isStorageSupported from 'utils/helpers/isStorageSupported';

// resetChunkReloadState checks if window has reloaded because of a chunk error
// if it is refreshed we want to reset this state after some time
// since user may keep their window open until our next release
const resetChunkReloadState = () => {
  // do nothing if session storage is not supported or disabled
  if (!isStorageSupported('sessionStorage')) {
    return;
  }

  // get refresh state from session storage
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem(sessionStorageKeys.LAZY_LOAD_REFRESHED) ||
      'false'
  );

  if (hasRefreshed) {
    // reset state after 1 minute
    // this means that if they get a chunk error after a minute, their page should refresh again
    setTimeout(() => {
      window.sessionStorage.setItem(
        sessionStorageKeys.LAZY_LOAD_REFRESHED,
        'false'
      );
    }, 1000 * 60);
  }
};

export default resetChunkReloadState;
