const udfRouterPaths = {
  root: '/udfs',
  details: '/udfs/details/:namespace/:arrayId',
  sharing: '/udfs/details/:namespace/:arrayId/sharing',
  activity: '/udfs/details/:namespace/:arrayId/activity',
  overview: '/udfs/details/:namespace/:arrayId/overview',
  settings: '/udfs/details/:namespace/:arrayId/settings',
  preview: '/udfs/details/:namespace/:arrayId/preview',
};

export default udfRouterPaths;
