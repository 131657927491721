/**
 * Persistant store used for namespace switching
 */

import { NamespaceType } from 'api-client';
import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface WorkspaceSelected {
  namespace: string;
  id: string;
  namespaceType: NamespaceType;
  logo?: string;
  name?: string;
  state?: any;
  email?: string;
}

interface WorkspaceSelectedStore {
  workspaceSelected: WorkspaceSelected | undefined;
  setWorkspaceSelected: (ws: WorkspaceSelected) => void;
  clearWorkspaceSelectedStore: () => void;
}

const persistConfig = {
  name: 'workspace-storage',
};

const useStore = create(
  persist<WorkspaceSelectedStore>(
    (set, get) => ({
      workspaceSelected: undefined,
      setWorkspaceSelected: (ws: WorkspaceSelected | undefined) =>
        set({ workspaceSelected: ws }),
      clearWorkspaceSelectedStore: () => set({ workspaceSelected: undefined }),
    }),
    persistConfig
  )
);

export const clearWorkspaceSelectedStore = () => {
  unstable_batchedUpdates(() => {
    useStore.getState().clearWorkspaceSelectedStore();
  });
};

export default useStore;
