import { toPairs } from 'ramda';

interface NamesObject {
  [s: string]: any;
}
/**
 * Helper function that accepts a dynamic path (i.e. '/aws/{namespace}/{name}') and an object with the values
 * Returns the same path with the dynamic names replaced by valid values.
 */
function replacePathNames(path: string, names: NamesObject): string {
  let newPath = path;

  const entries = toPairs(names);
  for (const [name, value] of entries) {
    newPath = newPath.replace(`{${name}}`, value);
  }
  return newPath;
}

export default replacePathNames;
