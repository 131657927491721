import isNumber from 'utils/helpers/number/isNumber';

const epochToDate = (timestamp: number) => {
  if (!isNumber(timestamp)) {
    return new Date();
  }
  const dateObject = new Date(0);
  dateObject.setUTCSeconds(timestamp);
  return dateObject;
};

export default epochToDate;
