import React, { useRef, useState } from 'react';
import './Dialog.scss';
import { usePopper } from 'react-popper';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { PositioningStrategy } from '@popperjs/core';
import classNames from 'classnames';

type Placements =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

interface PoppperProps extends React.PropsWithChildren {
  content: React.ReactNode;
  strategy?: PositioningStrategy;
  trigger?: 'hover' | 'click' | 'manual';
  variant?: 'transparent';
  placement?: Placements;
  className?: string;
  dataCy?: string;
  handleCloseDialog?: () => void;
  visible?: boolean;
  interactive?: boolean;
  dataTestID?: string;
  preventOverflow?: boolean;
  preventCloseForClassName?: string;
}

const Dialog: React.FC<PoppperProps> = ({
  content,
  children,
  interactive,
  variant,
  placement = 'auto',
  trigger = 'hover',
  className,
  strategy,
  handleCloseDialog,
  visible,
  dataCy,
  preventCloseForClassName,
  dataTestID,
  preventOverflow = false,
}) => {
  const [referenceElement, setReferenceElement] = useState<
    HTMLElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = useState<
    HTMLElement | null | undefined
  >(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    strategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: preventOverflow,
        },
      },
    ],
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const manualMode = trigger === 'manual';
  const hoverMode = trigger === 'hover';
  const clickMode = trigger === 'click';
  const [showDialog, toggleDialog] = useState(false);

  useOnClickOutside(
    tooltipRef,
    () => {
      if ((visible && manualMode) || clickMode) {
        handleCloseDialog?.();
        toggleDialog(() => false);
      }
    },
    { ignoreClass: preventCloseForClassName }
  );

  if (!content) {
    return <>{children}</>;
  }

  return (
    <div
      data-testid={dataTestID}
      data-cy={dataCy}
      className={classNames('TDB-Dialog__Wrapper', className)}
      ref={tooltipRef}
      onMouseEnter={(): any =>
        hoverMode && interactive && toggleDialog(() => true)
      }
      onMouseLeave={(): any =>
        hoverMode && interactive && toggleDialog(() => false)
      }
    >
      <div
        ref={setReferenceElement}
        onClick={(): any =>
          clickMode && toggleDialog((visibility: any) => !visibility)
        }
        onMouseEnter={(): any =>
          hoverMode && !interactive && toggleDialog(() => true)
        }
        onMouseLeave={(): any =>
          hoverMode && !interactive && toggleDialog(() => false)
        }
      >
        {children}
      </div>
      {!(manualMode && !visible) &&
        !((hoverMode || clickMode) && !showDialog) && (
          <div
            className={classNames('TDB-Dialog', {
              'TDB-Dialog--transparent': variant === 'transparent',
            })}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
          </div>
        )}
    </div>
  );
};

export default Dialog;
