import React, { useEffect } from 'react';
import './Cookies.scss';
import Button from 'components/v2/Button';
import useLocalStorage from 'hooks/useLocalStorage';
import setupAnalytics from './setupAnalytics';

export const COOKIES_APPROVAL = 'COOKIES_APPROVAL';

export const Cookies: React.FC = () => {
  const [preference, setPreference] = useLocalStorage(COOKIES_APPROVAL);

  function setCookies(value: boolean): void {
    setPreference(value);
  }

  useEffect(() => {
    if (preference === undefined) {
      return;
    }
    if (preference) {
      /**
       * enable analytics
       */
      setupAnalytics();
    }
  }, [preference]);

  /**
   * Don't show dialog if preference in localstorage
   */
  if (preference !== undefined) {
    return null;
  }

  return (
    <div
      className="TDB-Cookies"
      data-testid="TDB-Cookies"
      role="dialog"
      aria-labelledby="TDB-Cookies__text"
    >
      <div
        id="TDB-Cookies__text"
        className="TDB-Cookies__text font-size-h1 mb-3 align-center"
      >
        We are using cookies to give you the best experience on our website.
      </div>
      <div className="TDB-Cookies__buttons d-flex align-center">
        <Button
          size="large"
          onClick={(): void => setCookies(true)}
          variant="primary"
          className="mr-3"
          ariaLabel="Accept cookies"
        >
          Accept
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={(): void => setCookies(false)}
          ariaLabel="Decline cookies"
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default Cookies;
