import { AxiosResponse } from 'axios';
import {
  TaskGraphLogsApi_getTaskGraphLog,
  TaskGraphLogsApi_getTaskGraphLog$Params,
  TaskGraphLogsApi_getTaskGraphLog$Path,
  TaskGraphLogsApi_getTaskGraphLog$Response,
} from 'api-client';
import replacePathParams from 'utils/helpers/replacePathParams';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

export const createPath = (
  data: TaskGraphLogsApi_getTaskGraphLog$Params
): string => replacePathParams(TaskGraphLogsApi_getTaskGraphLog$Path, data);

const useGetTaskGraphDetails: UseRequestType<
  TaskGraphLogsApi_getTaskGraphLog$Params,
  TaskGraphLogsApi_getTaskGraphLog$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<TaskGraphLogsApi_getTaskGraphLog$Response>,
    string
  >(
    [createPath(queryData), queryData],
    useQueryRequest(TaskGraphLogsApi_getTaskGraphLog, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetTaskGraphDetails;
