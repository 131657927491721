import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M40.001 38C40.001 39.1 39.101 40 38.001 40H10.001C8.88096 40 8.00096 39.1 8.00096 38V10C8.00096 8.88 8.88096 8 10.001 8H38.001C39.101 8 40.001 8.88 40.001 10V38ZM44.001 10C44.001 6.68 41.301 4 38.001 4H10.001C6.68096 3.998 4.00096 6.68 4.00096 10L3.99896 38C3.99896 41.3 6.67896 44 9.99896 44H37.999C41.299 44 43.999 41.3 43.999 38L44.001 10Z" />
    <path d="M18.001 17C18.001 17.54 17.541 18 17.001 18C16.441 18 16.001 17.54 16.001 17C16.001 16.44 16.441 16 17.001 16C17.541 16 18.001 16.44 18.001 17ZM22.001 17C22.001 14.22 19.761 12 17.001 12C14.221 12 12.001 14.22 12.001 17C12.001 19.76 14.221 22 17.001 22C19.761 22 22.001 19.76 22.001 17Z" />
    <path d="M43.401 28.58L33.401 18.58C32.601 17.78 31.341 17.78 30.561 18.58L8.56097 40.58C7.76097 41.36 7.76097 42.62 8.56097 43.4C9.34097 44.18 10.601 44.18 11.381 43.4L33.381 21.4H30.541L40.541 31.4C41.321 32.18 42.581 32.18 43.361 31.4C44.141 30.6 44.141 29.34 43.361 28.56L43.401 28.58Z" />
  </>,
  'jpeg'
);
