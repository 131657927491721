/**
 * This file contains the modal context & the ModalProvider component
 * ModalProvider provides all methods needed in order to use modals inside the app i.e. pushModal(), popModal(), flushModals()
 *
 * In order to add a new modal into the app, you must create a new component and extend the <ModalDialog /> component,
 * Then lazy import the new modal in this file and add it to the `availableModals`.
 */
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { ModalCropImageParams } from 'components/Modals/ModalCropImage';
import { ModalConfirmParams } from 'components/Modals/ModalConfirm';
import { ModalCreditCardParams } from 'components/Modals/ModalCreditCard';
import { ModalAPITokenParams } from 'components/Modals/ModalAPIToken';
import { ModalInvoiceParams } from 'components/Modals/ModalInvoice';
import { ModalRenameOrganizationParams } from 'components/Modals/ModalRenameOrganization';
import { ModalRegisterArrayParams } from 'components/Modals/ModalRegisterArray';
import { ModalRenameArrayParams } from 'components/Modals/ModalRenameArray';
import { ModalShareArrayParams } from 'components/Modals/ModalShareArray';
import { ModalInviteMemberToOrganizationParams } from 'components/Modals/ModalInviteMemberToOrganization';
import { ModalAddLicenseParams } from 'components/Modals/ModalAddLicense';
import { ModalAddNotebookParams } from 'components/Modals/ModalAddNotebook';
import { ModalTextParams } from 'components/Modals/ModalText';
import { ModalChangeCloudCredentialsParams } from 'components/Modals/ModalChangeCloudCredentials';
import { ModalChangeGroupCloudCredentialsParams } from 'components/Modals/ModalChangeGroupCloudCredentials';
import { ModalPublicShareArrayParams } from 'components/Modals/ModalPublicShareArray';
import { ModalMarkArrayAsReadOnlyParams } from 'components/Modals/ModalMarkArrayAsReadOnly';
import { ModalAddAssetsToGroupParams } from '../../components/Modals/ModalAddAssetsToGroup';
import { ModalCreateGroupParams } from '../../components/Modals/ModalCreateGroup';
import { ModalRegisterGroupParams } from '../../components/Modals/ModalRegisterGroup';
import { ModalRenameGroupParams } from '../../components/Modals/ModalRenameGroup';
import { ModalRemoveGroupParams } from '../../components/Modals/ModalRemoveGroup';
import { ModalShareGroupParams } from '../../components/Modals/ModalShareGroup';
import { ModalPublicShareGroupParams } from '../../components/Modals/ModalPublicShareGroup';
import { ModalSelectGroupParams } from '../../components/Modals/ModalSelectGroup';
import { availableModals } from 'containers/ModalContainer';
import { ModalSelectFileParams } from 'components/Modals/ModalSelectFile';
import { ModalUploadFileParams } from 'components/Modals/ModalUploadFile';
import { ModalIngestFileParams } from 'components/Modals/ModalIngestFile';
import { ModalSSODomainSetupParams } from 'components/Modals/ModalSSODomainSetup';
import { ModalCopyNotebookParams } from 'components/Modals/ModalCopyNotebook';
import { ModalCopyUDFParams } from 'components/Modals/ModalCopyUDF';
import { ModalUploadNotebookParams } from 'components/Modals/ModalUploadNotebook';
import { ModalCreateNotebookParams } from 'components/Modals/ModalCreateNotebook';
import { ModalAddGroupParams } from 'components/Modals/ModalAddGroup';
import { ModalAddAssetParams } from 'components/Modals/ModalAddAsset';
import { ModalChangeUsernameParams } from 'components/Modals/ModalChangeUsername';
import { ModalSelectCloudCredentialsProviderParams } from 'components/Modals/ModalSelectCloudCredentialsProvider';
import { ModalAWSCredentialsParams } from 'components/Modals/ModalAWSCredentials';
import { ModalSelectAWSCredentialsFormatParams } from 'components/Modals/ModalSelectAWSCredentialsFormat';
import { ModalARNAWSCredentialsSetupParams } from 'components/Modals/ModalARNAWSCredentialsSetup';
import { ModalARNAWSCredentialsRoleInfoParams } from 'components/Modals/ModalARNAWSCredentialsRoleInfo';
import { ModalARNAWSCredentialsParams } from 'components/Modals/ModalARNAWSCredentials';
import { ModalAzureCredentialsParams } from 'components/Modals/ModalAzureCredentials';
import { ModalApplyRootPathToStoragePathsParams } from 'components/Modals/ModalApplyRootPathToStoragePaths';
import { ModalAddSSOConnectionParams } from 'components/Modals/ModalAddSSOConnection';
import { ModalProfileSetupParams } from 'components/ModalsV2/Onboarding/ModalProfileSetup';
import { ModalLaunchServerParams } from 'components/Modals/ModalLaunchServer';
import { ModalConnectStorageIntroParams } from 'components/ModalsV2/Onboarding/ModalConnectStorageIntro';
import { ModalConnectStorageParams } from 'components/ModalsV2/Onboarding/ModalConnectStorage';
import { ModalAddAssetIntroParams } from 'components/ModalsV2/Onboarding/ModalAddAssetIntro';
import { ModalSkipOnboardingParams } from 'components/ModalsV2/Onboarding/ModalSkipOnboarding';
import { ModalCreateOrganizationParams } from 'components/Modals/ModalCreateOrganization/ModalCreateOrganization';
import { ModalWelcomeToOrganizationParams } from 'components/ModalsV2/Onboarding/ModalWelcomeToOrganization';
import { ModalRemoveAssetParams } from 'components/Modals/ModalRemoveAsset';
import { ModalSelectRegionParams } from 'components/Modals/ModalSelectRegion';
import { ModalAddCodeAssetsParams } from 'components/Modals/ModalAddCodeAssets';
import { ModalAddDataAssetsParams } from 'components/Modals/ModalAddDataAssets';
import { ModalAddFileParams } from 'components/Modals/ModalAddFile';
import { ModalIngestVCFParams } from 'components/Modals/ModalIngestVCF';
import { ModalImportVerticalAssetParams } from 'components/Modals/ModalImportVerticalAsset/ModalImportVerticalAsset';
import { ModalSelectAzureCredentialsFormatParams } from 'components/Modals/ModalSelectAzureCredentialsFormat';
import { ModalAzureTokenCredentialsParams } from 'components/Modals/ModalAzureTokenCredentials';
import { ModalSelectGCPCredentialsFormatParams } from 'components/Modals/ModalSelectGCPCredentialsFormat';
import { ModalGCPKeyIDCredentialsParams } from 'components/Modals/ModalGCPKeyIDCredentials';
import { ModalGCPServiceAccountCredentialsParams } from 'components/Modals/ModalGCPServiceAccountCredentials';
import { ModalIngestBiomedicalImagingParams } from 'components/Modals/ModalIngestBiomedicalImaging';
import { ModalLaunchAppParams } from 'components/Modals/ModalLaunchApp';
import { ModalSelectAssetToLaunchParams } from 'components/Modals/ModalSelectAssetToLaunch';
import { ModalSelectRegionAndServerProfileParams } from 'components/Modals/ModalSelectRegionAndServerProfile';
import { ModalIngestPointCloudParams } from 'components/Modals/ModalIngestPointCloud';
import { ModalServerMismatchParams } from 'components/Modals/ModalServerMismatch';
import { ModalIngestSOMAParams } from 'components/Modals/ModalIngestSOMA';
import { ModalBulkRemoveAssetsParams } from 'components/ModalsV2/ModalBulkRemoveAssets';
import { ModalBulkChangeAssetCredentialsParams } from 'components/ModalsV2/ModalBulkChangeAssetCredentials';
import { ModalUpdateAssetMetadataParams } from 'components/ModalsV2/ModalUpdateAssetMetadata';
import { ModalInfoParams } from 'components/ModalsV2/ModalInfo';

export enum Modals {
  ModalConfirm = 'ModalConfirm',
  ModalAddCodeAssets = 'ModalAddCodeAssets',
  ModalServerMismatch = 'ModalServerMismatch',
  ModalProductOverview = 'ModalProductOverview',
  ModalRootPath = 'ModalRootPath',
  ModalCloudStorage = 'ModalCloudStorage',
  ModalClaimCredits = 'ModalClaimCredits',
  ModalAddBiomedicalImaging = 'ModalAddBiomedicalImaging',
  ModalSelectRegionAndServerProfile = 'ModalSelectRegionAndServerProfile',
  ModalSelectAzureCredentialsFormat = 'ModalSelectAzureCredentialsFormat',
  ModalSelectGCPCredentialsFormat = 'ModalSelectGCPCredentialsFormat',
  ModalGCPServiceAccountCredentials = 'ModalGCPServiceAccountCredentials',
  ModalGCPKeyIDCredentials = 'ModalGCPKeyIDCredentials',
  ModalImportVerticalAsset = 'ModalImportVerticalAsset',
  ModalAzureTokenCredentials = 'ModalAzureTokenCredentials',
  ModalAddDataAssets = 'ModalAddDataAssets',
  ModalAddSOMA = 'ModalAddSOMA',
  ModalAddPointCloud = 'ModalAddPointCloud',
  ModalAddGeometries = 'ModalAddGeometries',
  ModalAddRaster = 'ModalAddRaster',
  ModalSelectRegion = 'ModalSelectRegion',
  ModalLaunchServer = 'ModalLaunchServer',
  ModalIngestVCF = 'ModalIngestVCF',
  ModalIngestSOMA = 'ModalIngestSOMA',
  ModalAddVCF = 'ModalAddVCF',
  ModalRenameArray = 'ModalRenameArray',
  ModalAddFile = 'ModalAddFile',
  ModalChangeCloudCredentials = 'ModalChangeCloudCredentials',
  ModalChangeGroupCloudCredentials = 'ModalChangeGroupCloudCredentials',
  ModalCropImage = 'ModalCropImage',
  ModalCreditCard = 'ModalCreditCard',
  ModalShareArray = 'ModalShareArray',
  ModalAddLicense = 'ModalAddLicense',
  ModalSSODomainSetup = 'ModalSSODomainSetup',
  ModalText = 'ModalText',
  ModalRegisterArray = 'ModalRegisterArray',
  ModalAddNotebook = 'ModalAddNotebook',
  ModalAPIToken = 'ModalAPIToken',
  ModalInvoice = 'ModalInvoice',
  ModalRenameOrganization = 'ModalRenameOrganization',
  ModalUserOnboarding = 'ModalUserOnboarding',
  ModalInviteMemberToOrganization = 'ModalInviteMemberToOrganization',
  ModalAddArrayPricing = 'ModalAddArrayPricing',
  ModalPublicShareArray = 'ModalPublicShareArray',
  ModalMarkArrayAsReadOnly = 'ModalMarkArrayAsReadOnly',
  ModalRequestCredits = 'ModalRequestCredits',
  ModalAddAssetsToGroup = 'ModalAddAssetsToGroup',
  ModalCreateGroup = 'ModalCreateGroup',
  ModalRegisterGroup = 'ModalRegisterGroup',
  ModalRenameGroup = 'ModalRenameGroup',
  ModalShareGroup = 'ModalShareGroup',
  ModalSelectGroup = 'ModalSelectGroup',
  ModalPublicShareGroup = 'ModalPublicShareGroup',
  ModalSelectFile = 'ModalSelectFile',
  ModalUploadFile = 'ModalUploadFile',
  ModalCopyNotebook = 'ModalCopyNotebook',
  ModalUploadNotebook = 'ModalUploadNotebook',
  ModalCreateNotebook = 'ModalCreateNotebook',
  ModalCopyUDF = 'ModalCopyUDF',
  ModalAddGroup = 'ModalAddGroup',
  ModalRemoveGroup = 'ModalRemoveGroup',
  ModalAddAsset = 'ModalAddAsset',
  ModalChangeUsername = 'ModalChangeUsername',
  ModalSelectCloudCredentialsProvider = 'ModalSelectCloudCredentialsProvider',
  ModalAWSCredentials = 'ModalAWSCredentials',
  ModalSelectAWSCredentialsFormat = 'ModalSelectAWSCredentialsFormat',
  ModalARNAWSCredentialsSetup = 'ModalARNAWSCredentialsSetup',
  ModalARNAWSCredentials = 'ModalARNAWSCredentials',
  ModalARNAWSCredentialsRoleInfo = 'ModalARNAWSCredentialsRoleInfo',
  ModalAzureCredentials = 'ModalAzureCredentials',
  ModalApplyRootPathToStoragePaths = 'ModalApplyRootPathToStoragePaths',
  ModalAddSSOConnection = 'ModalAddSSOConnection',
  ModalWelcome = 'ModalWelcome',
  ModalProfileSetup = 'ModalProfileSetup',
  ModalFinishUserOnboarding = 'ModalFinishUserOnboarding',
  ModalConnectStorageIntro = 'ModalConnectStorageIntro',
  ModalConnectStorage = 'ModalConnectStorage',
  ModalAddAssetIntro = 'ModalAddAssetIntro',
  ModalSkipOnboarding = 'ModalSkipOnboarding',
  ModalCreateOrganization = 'ModalCreateOrganization',
  ModalWelcomeToOrganization = 'ModalWelcomeToOrganization',
  ModalFinishOrganizationOnboarding = 'ModalFinishOrganizationOnboarding',
  ModalRemoveAsset = 'ModalRemoveAsset',
  ModalIngestBiomedicalImaging = 'ModalIngestBiomedicalImaging',
  ModalIngestFile = 'ModalIngestFile',
  ModalLaunchApp = 'ModalLaunchApp',
  ModalSelectAssetToLaunch = 'ModalSelectAssetToLaunch',
  ModalIngestPointCloud = 'ModalIngestPointCloud',
  ModalBulkRemoveAssets = 'ModalBulkRemoveAssets',
  ModalErrorBoundary = 'ModalErrorBoundary',
  ModalBulkChangeAssetCredentials = 'ModalBulkChangeAssetCredentials',
  ModalUpdateAssetMetadata = 'ModalUpdateAssetMetadata',
  ModalInfo = 'ModalInfo',
}

/**
 * Concatenate Modal params
 */
type ModalParams =
  | ModalCropImageParams
  | ModalSelectRegionParams
  | ModalCopyNotebookParams
  | ModalCopyUDFParams
  | ModalLaunchServerParams
  | ModalTextParams
  | ModalConfirmParams
  | ModalCreditCardParams
  | ModalAPITokenParams
  | ModalInvoiceParams
  | ModalUploadNotebookParams
  | ModalIngestSOMAParams
  | ModalCreateNotebookParams
  | ModalIngestVCFParams
  | ModalAddNotebookParams
  | ModalRenameOrganizationParams
  | ModalGCPKeyIDCredentialsParams
  | ModalRegisterArrayParams
  | ModalRenameArrayParams
  | ModalSelectRegionAndServerProfileParams
  | ModalShareArrayParams
  | ModalSSODomainSetupParams
  | ModalAddFileParams
  | ModalUploadFileParams
  | ModalInviteMemberToOrganizationParams
  | ModalAddLicenseParams
  | ModalChangeCloudCredentialsParams
  | ModalChangeGroupCloudCredentialsParams
  | ModalPublicShareArrayParams
  | ModalMarkArrayAsReadOnlyParams
  | ModalCreateGroupParams
  | ModalRegisterGroupParams
  | ModalRenameGroupParams
  | ModalGCPServiceAccountCredentialsParams
  | ModalAddAssetsToGroupParams
  | ModalShareGroupParams
  | ModalPublicShareGroupParams
  | ModalSelectFileParams
  | ModalSelectGroupParams
  | ModalAddGroupParams
  | ModalAddAssetParams
  | ModalChangeUsernameParams
  | ModalSelectCloudCredentialsProviderParams
  | ModalAWSCredentialsParams
  | ModalARNAWSCredentialsParams
  | ModalSelectAzureCredentialsFormatParams
  | ModalARNAWSCredentialsSetupParams
  | ModalServerMismatchParams
  | ModalARNAWSCredentialsRoleInfoParams
  | ModalAzureCredentialsParams
  | ModalAzureTokenCredentialsParams
  | ModalApplyRootPathToStoragePathsParams
  | ModalAddSSOConnectionParams
  | ModalProfileSetupParams
  | ModalConnectStorageIntroParams
  | ModalConnectStorageParams
  | ModalAddAssetIntroParams
  | ModalSkipOnboardingParams
  | ModalCreateOrganizationParams
  | ModalWelcomeToOrganizationParams
  | ModalRemoveAssetParams
  | ModalIngestBiomedicalImagingParams
  | ModalLaunchAppParams
  | ModalSelectAssetToLaunchParams
  | ModalBulkRemoveAssetsParams
  | ModalBulkChangeAssetCredentialsParams
  | ModalUpdateAssetMetadataParams
  | ModalInfoParams;

export type ModalType = ModalParams & {
  name: Modals;
  // Optionally make a popup non-dismissable with Escape key
  noDismissOnEscape?: boolean;
};
export interface ModalState {
  modals: ModalType[];
  pushModal: (name: Modals, modal: ModalParams) => void;
  flushModals: () => void;
  popModal: () => void;
  goToModal: (name: Modals) => void;

  // Modals
  showCropImageModal: (params: ModalCropImageParams) => void;
  showServerMismatchModal: (params: ModalServerMismatchParams) => void;
  showImportVerticalModal: (params: ModalImportVerticalAssetParams) => void;
  showCopyNotebookModal: (params: ModalCopyNotebookParams) => void;
  showAddCodeAssetsModal: (params: ModalAddCodeAssetsParams) => void;
  showAddFileModal: (params: ModalAddFileParams) => void;
  showCopyUDFModal: (params: ModalCopyUDFParams) => void;
  showSelectFileModal: (params: ModalSelectFileParams) => void;
  showConfirmModal: (params: ModalConfirmParams) => void;
  showCreditCardModal: (params: ModalCreditCardParams) => void;
  showIngestVCFModal: (params: ModalIngestVCFParams) => void;
  showUploadNotebookModal: (params: ModalUploadNotebookParams) => void;
  showClaimCreditsModal: () => void;
  showCreateNotebookModal: (params: ModalCreateNotebookParams) => void;
  showAddDataAssetsModal: (params: ModalAddDataAssetsParams) => void;
  showLicenseModal: (params: ModalAddLicenseParams) => void;
  showSelectRegionModal: (params: ModalSelectRegionParams) => void;
  showTextModal: (params: ModalTextParams) => void;
  showAPITokenModal: (params: ModalAPITokenParams) => void;
  showInvoiceModal: (params: ModalInvoiceParams) => void;
  showUploadFileModal: (params: ModalUploadFileParams) => void;
  showOrganizationRenameModal: (params: ModalRenameOrganizationParams) => void;
  showRegisterArrayModal: (params: ModalRegisterArrayParams) => void;
  showArrayRenameModal: (params: ModalRenameArrayParams) => void;
  showShareArrayModal: (params: ModalShareArrayParams) => void;
  showInviteMemberToOrganizationModal: (
    params: ModalInviteMemberToOrganizationParams
  ) => void;
  showAddNotebookModal: (params: ModalAddNotebookParams) => void;
  showChangeCloudCredentialsModal: (
    params: ModalChangeCloudCredentialsParams
  ) => void;
  showChangeGroupCloudCredentialsModal: (
    params: ModalChangeGroupCloudCredentialsParams
  ) => void;
  showPublicShareArrayModal: (params: ModalPublicShareArrayParams) => void;
  showMarkArrayAsReadOnlyModal: (
    params: ModalMarkArrayAsReadOnlyParams
  ) => void;
  showRequestCreditsModal: () => void;
  showAddAssetsToGroupModal: (params: ModalAddAssetsToGroupParams) => void;
  showSelectRegionAndServerProfileModal: (
    params: ModalSelectRegionAndServerProfileParams
  ) => void;
  showCreateGroupModal: (params: ModalCreateGroupParams) => void;
  showRegisterGroupModal: (params: ModalRegisterGroupParams) => void;
  showRenameGroupModal: (params: ModalRenameGroupParams) => void;
  showRemoveGroupModal: (params: ModalRemoveGroupParams) => void;
  showShareGroupModal: (params: ModalShareGroupParams) => void;
  showPublicShareGroupModal: (params: ModalPublicShareGroupParams) => void;
  showSelectGroupModal: (params: ModalSelectGroupParams) => void;
  showAddGroupModal: (params: ModalAddGroupParams) => void;
  showAddAssetModal: (params: ModalAddAssetParams) => void;
  showChangeUsernameModal: (params: ModalChangeUsernameParams) => void;
  showSelectCloudCredentialsProviderModal: (
    params: ModalSelectCloudCredentialsProviderParams
  ) => void;
  showAzureCredentialsModal: (params: ModalAzureCredentialsParams) => void;
  showAzureTokenCredentialsModal: (
    params: ModalAzureTokenCredentialsParams
  ) => void;
  showAWSCredentialsModal: (params: ModalAWSCredentialsParams) => void;
  showSelectAWSCredentialsFormatModal: (
    params: ModalSelectAWSCredentialsFormatParams
  ) => void;
  showSelectGCPCredentialsFormatModal: (
    params: ModalSelectGCPCredentialsFormatParams
  ) => void;
  showSelectGCPServiceAccountModal: (
    params: ModalGCPServiceAccountCredentialsParams
  ) => void;
  showSelectAzureCredentialsFormatModal: (
    params: ModalSelectAzureCredentialsFormatParams
  ) => void;
  showARNAWSCredentialsSetupModal: (
    params: ModalARNAWSCredentialsSetupParams
  ) => void;
  showARNAWSCredentialsRoleInfoModal: (
    params: ModalARNAWSCredentialsRoleInfoParams
  ) => void;
  showSSoDomainSetupModal: (params: ModalSSODomainSetupParams) => void;
  showARNAWSCredentialsModal: (params: ModalARNAWSCredentialsParams) => void;
  showGCPKeyIDCredentialsModal: (
    params: ModalGCPKeyIDCredentialsParams
  ) => void;
  showApplyRootPathToStoragePathsModal: (
    params: ModalApplyRootPathToStoragePathsParams
  ) => void;
  showAddSSOConnectionModal: (params: ModalAddSSOConnectionParams) => void;
  showLaunchServerModal: (params: ModalLaunchServerParams) => void;
  showWelcomeModal: () => void;
  showProfileSetupModal: (params: ModalProfileSetupParams) => void;
  showFinishUserOnboardingModal: () => void;
  showConnectStorageIntroModal: (
    params: ModalConnectStorageIntroParams
  ) => void;
  showConnectStorageModal: (params: ModalConnectStorageParams) => void;
  showAddAssetIntroModal: (params: ModalAddAssetIntroParams) => void;
  showSkipOnboardingModal: (params: ModalSkipOnboardingParams) => void;
  showCreateOrganizationModal: (params: ModalCreateOrganizationParams) => void;
  showWelcomeToOrganizationModal: (
    params: ModalWelcomeToOrganizationParams
  ) => void;
  showFinishOrganizationOnboardingModal: () => void;
  showRemoveAssetModal: (params: ModalRemoveAssetParams) => void;
  showIngestBiomedicalImagingModal: (
    params: ModalIngestBiomedicalImagingParams
  ) => void;
  showIngestSOMAModal: (params: ModalIngestSOMAParams) => void;
  showIngestFileModal: (params: ModalIngestFileParams) => void;
  showLaunchAppModal: (params: ModalLaunchAppParams) => void;
  showSelectAssetToLaunchModal: (
    params: ModalSelectAssetToLaunchParams
  ) => void;
  showIngestPointCloudModal: (params: ModalIngestPointCloudParams) => void;
  showUserOnboardingModal: (params: {}) => void;
  showBulkRemoveAssetsModal: (params: ModalBulkRemoveAssetsParams) => void;
  showBulkChangeAssetCredentialsModal: (
    params: ModalBulkChangeAssetCredentialsParams
  ) => void;
  showUpdateAssetMetadataModal: (
    params: ModalUpdateAssetMetadataParams
  ) => void;
  showInfoModal: (params: ModalInfoParams) => void;
}

const useStore = createWithEqualityFn<ModalState>(
  (set, get) => ({
    modals: [],
    pushModal: (name: Modals, modal: ModalParams) => {
      const activeModals = get().modals;
      if (!availableModals[name]) {
        // eslint-disable-next-line
      console.warn(
          `Tried to push a non existing modal \`${name}\` into the Modal stack.`
        );
        return;
      }

      if (activeModals.some((m: ModalType) => m.name === name)) {
        // Prevent duplicates
        return;
      }

      const modalWithName = { name, ...modal };

      return set((state: ModalState) => ({
        modals: [...state.modals, modalWithName],
      }));
    },

    popModal: () =>
      set((state) => ({
        modals: state.modals.slice(0, -1),
      })),

    flushModals: () =>
      set(() => ({
        modals: [],
      })),

    goToModal: (modalName: Modals) =>
      set((state) => {
        const modalIndex = state.modals.findIndex((m) => m.name === modalName);
        return {
          modals: state.modals.slice(0, modalIndex + 1),
        };
      }),

    showServerMismatchModal: (params: ModalServerMismatchParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalServerMismatch, params);
    },

    showUserOnboardingModal: (params: ModalServerMismatchParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalUserOnboarding, {
        ...params,
        noDismissOnEscape: true,
      });
    },

    showConfirmModal: (params: ModalConfirmParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalConfirm, params);
    },

    showClaimCreditsModal: () => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalClaimCredits, {});
    },

    showImportVerticalModal: (params: ModalImportVerticalAssetParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalImportVerticalAsset, params);
    },

    showAddDataAssetsModal: (params: ModalAddDataAssetsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddDataAssets, params);
    },

    showIngestVCFModal: (params: ModalIngestVCFParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalIngestVCF, params);
    },

    showIngestSOMAModal: (params: ModalIngestSOMAParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalIngestSOMA, params);
    },

    showAddCodeAssetsModal: (params: ModalAddAssetParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddCodeAssets, params);
    },

    showSelectRegionAndServerProfileModal: (
      params: ModalSelectRegionAndServerProfileParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectRegionAndServerProfile, params);
    },

    showAddFileModal: (params: ModalAddFileParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddFile, params);
    },

    showArrayRenameModal: (params: ModalRenameArrayParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRenameArray, params);
    },

    showChangeCloudCredentialsModal: (
      params: ModalChangeCloudCredentialsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalChangeCloudCredentials, params);
    },

    showChangeGroupCloudCredentialsModal: (
      params: ModalChangeGroupCloudCredentialsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalChangeGroupCloudCredentials, params);
    },

    showSelectRegionModal: (params: ModalSelectRegionParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectRegion, params);
    },

    showCropImageModal: (params: ModalCropImageParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCropImage, params);
    },

    showCreditCardModal: (params: ModalCreditCardParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCreditCard, params);
    },

    showShareArrayModal: (params: ModalShareArrayParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalShareArray, params);
    },

    showLicenseModal: (params: ModalAddLicenseParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddLicense, params);
    },

    showTextModal: (params: ModalTextParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalText, params);
    },

    showRegisterArrayModal: (params: ModalRegisterArrayParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRegisterArray, params);
    },

    showAddNotebookModal: (params: ModalAddNotebookParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddNotebook, params);
    },

    showAPITokenModal: (params: ModalAPITokenParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAPIToken, params);
    },

    showInvoiceModal: (params: ModalInvoiceParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalInvoice, params);
    },

    showOrganizationRenameModal: (params: ModalRenameOrganizationParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRenameOrganization, params);
    },

    showInviteMemberToOrganizationModal: (
      params: ModalInviteMemberToOrganizationParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalInviteMemberToOrganization, params);
    },

    showPublicShareArrayModal: (params: ModalPublicShareArrayParams) => {
      const pushModal = get().pushModal;
      pushModal(Modals.ModalPublicShareArray, params);
    },

    showMarkArrayAsReadOnlyModal: (params: ModalMarkArrayAsReadOnlyParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalMarkArrayAsReadOnly, params);
    },

    showRequestCreditsModal: () => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRequestCredits, {});
    },

    showAddAssetsToGroupModal: (params: ModalAddAssetsToGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddAssetsToGroup, params);
    },

    showCreateGroupModal: (params: ModalCreateGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCreateGroup, params);
    },

    showRegisterGroupModal: (params: ModalRegisterGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRegisterGroup, params);
    },

    showRenameGroupModal: (params: ModalRenameGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRenameGroup, params);
    },

    showShareGroupModal: (params: ModalShareGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalShareGroup, params);
    },

    showPublicShareGroupModal: (params: ModalPublicShareGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalPublicShareGroup, params);
    },

    showSelectGroupModal: (params: ModalSelectGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectGroup, params);
    },

    showSelectFileModal: (params: ModalSelectFileParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectFile, params);
    },

    showUploadFileModal: (params: ModalUploadFileParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalUploadFile, params);
    },

    showCopyNotebookModal: (params: ModalCopyNotebookParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCopyNotebook, params);
    },

    showUploadNotebookModal: (params: ModalUploadNotebookParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalUploadNotebook, params);
    },

    showCreateNotebookModal: (params: ModalCreateNotebookParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCreateNotebook, params);
    },

    showCopyUDFModal: (params: ModalCopyUDFParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCopyUDF, params);
    },

    showAddGroupModal: (params: ModalAddGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddGroup, params);
    },
    showRemoveGroupModal: (params: ModalRemoveGroupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRemoveGroup, params);
    },
    showAddAssetModal: (params: ModalAddAssetParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddAsset, params);
    },
    showChangeUsernameModal: (params: ModalChangeUsernameParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalChangeUsername, params);
    },
    showSelectCloudCredentialsProviderModal: (
      params: ModalSelectCloudCredentialsProviderParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectCloudCredentialsProvider, params);
    },
    showAWSCredentialsModal: (params: ModalAWSCredentialsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAWSCredentials, params);
    },
    showSelectAWSCredentialsFormatModal: (
      params: ModalSelectAWSCredentialsFormatParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectAWSCredentialsFormat, params);
    },

    showSelectGCPCredentialsFormatModal: (
      params: ModalSelectGCPCredentialsFormatParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectGCPCredentialsFormat, params);
    },

    showSelectGCPServiceAccountModal: (
      params: ModalGCPServiceAccountCredentialsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalGCPServiceAccountCredentials, params);
    },

    showGCPKeyIDCredentialsModal: (params: ModalGCPKeyIDCredentialsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalGCPKeyIDCredentials, params);
    },

    showSelectAzureCredentialsFormatModal: (
      params: ModalSelectAzureCredentialsFormatParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectAzureCredentialsFormat, params);
    },
    showARNAWSCredentialsSetupModal: (
      params: ModalARNAWSCredentialsSetupParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalARNAWSCredentialsSetup, params);
    },
    showARNAWSCredentialsModal: (params: ModalARNAWSCredentialsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalARNAWSCredentials, params);
    },
    showARNAWSCredentialsRoleInfoModal: (
      params: ModalARNAWSCredentialsRoleInfoParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalARNAWSCredentialsRoleInfo, params);
    },
    showAzureCredentialsModal: (params: ModalAzureCredentialsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAzureCredentials, params);
    },
    showAzureTokenCredentialsModal: (
      params: ModalAzureTokenCredentialsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAzureTokenCredentials, params);
    },
    showApplyRootPathToStoragePathsModal: (
      params: ModalApplyRootPathToStoragePathsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalApplyRootPathToStoragePaths, params);
    },
    showLaunchServerModal: (params: ModalLaunchServerParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalLaunchServer, params);
    },
    showAddSSOConnectionModal: (params: ModalAddSSOConnectionParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddSSOConnection, params);
    },
    showSSoDomainSetupModal: (params: ModalSSODomainSetupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSSODomainSetup, params);
    },
    showWelcomeModal: () => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalWelcome, { noDismissOnEscape: true });
    },

    showProfileSetupModal: (params: ModalProfileSetupParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalProfileSetup, {
        ...params,
        noDismissOnEscape: true,
      });
    },
    showFinishUserOnboardingModal: () => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalFinishUserOnboarding, {});
    },
    showConnectStorageIntroModal: (params: ModalConnectStorageIntroParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalConnectStorageIntro, params);
    },
    showConnectStorageModal: (params: ModalConnectStorageParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalConnectStorage, params);
    },
    showAddAssetIntroModal: (params: ModalAddAssetIntroParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalAddAssetIntro, params);
    },
    showSkipOnboardingModal: (params: ModalSkipOnboardingParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSkipOnboarding, params);
    },
    showCreateOrganizationModal: (params: ModalCreateOrganizationParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalCreateOrganization, params);
    },
    showWelcomeToOrganizationModal: (
      params: ModalWelcomeToOrganizationParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalWelcomeToOrganization, params);
    },
    showFinishOrganizationOnboardingModal: () => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalFinishOrganizationOnboarding, {});
    },
    showRemoveAssetModal: (params: ModalRemoveAssetParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalRemoveAsset, params);
    },
    showIngestBiomedicalImagingModal: (
      params: ModalIngestBiomedicalImagingParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalIngestBiomedicalImaging, params);
    },
    showIngestFileModal: (params: ModalIngestFileParams) => {
      const pushModal = get().pushModal;
      pushModal(Modals.ModalIngestFile, params);
    },
    showLaunchAppModal: (params: ModalLaunchAppParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalLaunchApp, params);
    },
    showSelectAssetToLaunchModal: (params: ModalSelectAssetToLaunchParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalSelectAssetToLaunch, params);
    },
    showIngestPointCloudModal: (params: ModalIngestPointCloudParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalIngestPointCloud, params);
    },
    showBulkRemoveAssetsModal: (params: ModalBulkRemoveAssetsParams) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalBulkRemoveAssets, params);
    },
    showBulkChangeAssetCredentialsModal: (
      params: ModalBulkChangeAssetCredentialsParams
    ) => {
      const pushModal = get().pushModal;

      pushModal(Modals.ModalBulkChangeAssetCredentials, params);
    },
    showUpdateAssetMetadataModal: (params: ModalUpdateAssetMetadataParams) => {
      const pushModal = get().pushModal;
      pushModal(Modals.ModalUpdateAssetMetadata, params);
    },
    showInfoModal: (params: ModalInfoParams) => {
      const pushModal = get().pushModal;
      pushModal(Modals.ModalInfo, params);
    },
  }),
  shallow
);

export default useStore;
