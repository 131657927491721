import axios from 'axios';
import prependBasePath from 'utils/helpers/prependBasePath';

function setupAxios(): void {
  // Prepend BASE_PATH to support serving the UI from a subpath i.e. /ui (for enterprise)
  axios.defaults.baseURL = prependBasePath('/v1/api');
  axios.defaults.withCredentials = true;
  axios.defaults.timeout = 120000;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = global.CSRF_TOKEN;
}

export default setupAxios;
