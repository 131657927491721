import { MutateResponse, useMutateRequest } from 'hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import {
  UserApi_deleteOnboardingItems,
  UserApi_deleteOnboardingItems$Params,
  UserApi_deleteOnboardingItems$Response,
} from 'api-client';

const useDeleteCompletedOnboardingItems = (): MutateResponse<
  UserApi_deleteOnboardingItems$Params,
  UserApi_deleteOnboardingItems$Response
> => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    UserApi_deleteOnboardingItems
  );

  return [
    useMutateRequest(mutateAsync),
    data,
    error as null | Error,
    isLoading,
  ];
};

export default useDeleteCompletedOnboardingItems;
