import {
  UserApi_checkLoginCookie,
  UserApi_checkLoginCookie$Params,
  UserApi_checkLoginCookie$Response,
  UserApi_checkLoginCookie$Path,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

export const checkLoginKey = UserApi_checkLoginCookie$Path;

const useCheckLoginCookie: UseRequestType<
  UserApi_checkLoginCookie$Params,
  UserApi_checkLoginCookie$Response
> = (__, config, queryConfig) => {
  const { isFetching, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_checkLoginCookie$Response>,
    UserApi_checkLoginCookie$Params
  >(
    [checkLoginKey],
    useQueryRequest(UserApi_checkLoginCookie, config),
    queryConfig
  );

  return [refetch, data, error, isFetching];
};

export default useCheckLoginCookie;
