import { useUserContext } from 'store/UserContext';
import {
  formatTimezonedDate,
  relativeTimeInWordsToNow,
} from 'utils/helpers/date';
import fragmentTimestampToDateTime from 'utils/helpers/date/fragmentTimestampToDateTime';

export interface UseDate {
  dateTime: (value: string | number | Date) => string;
  relativeTimeInWordsToNow: (value: string | number | Date) => string;
  formatDate: (value: string | number | Date, dateFormat?: string) => string;
  fragmentTimestampToDateTime: (value: number, dateFormat?: string) => string;
}

/**
 * Returns date helper functions which will convert date to user's timezone
 */
function useDate(): UseDate {
  const { user } = useUserContext();

  const timezone =
    user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    relativeTimeInWordsToNow,
    dateTime: (value: string | number | Date): string =>
      formatTimezonedDate(timezone, value, 'd MMM yyyy h:mma'),
    formatDate: (
      value: string | number | Date,
      dateFormat = 'dd MMM yyyy'
    ): string => formatTimezonedDate(timezone, value, dateFormat),
    fragmentTimestampToDateTime: (timestamp: number): string =>
      fragmentTimestampToDateTime(timestamp, timezone),
  };
}

export default useDate;
