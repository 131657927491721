// Taken from https://dev.to/pranay_rauthu/uuid-in-javascript-obc

/**
 * Returns a unique id string
 */
const generateUniqueId = () => {
  const firstItem: any = {
    value: '0',
  };
  /*length can be increased for lists with more items.*/
  let counter = '123456789'.split('').reduce((acc, curValue, curIndex, arr) => {
    const curObj: any = {};
    curObj.value = curValue;
    curObj.prev = acc;

    return curObj;
  }, firstItem);
  firstItem.prev = counter;

  return function () {
    let now: any = Date.now();
    if (
      typeof performance === 'object' &&
      typeof performance.now === 'function'
    ) {
      now = performance.now().toString().replace('.', '');
    }
    counter = counter.prev;
    return `${now}${Math.random().toString(16).substring(2)}${counter.value}`;
  };
};

export default generateUniqueId();
