const mlModelLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/overview`,

  metadata: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/metadata`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/sharing`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/settings`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/activity`,

  preview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/mlmodels/details/${namespace}/${arrayId}/preview`,
};

export default mlModelLinks;
