import * as React from 'react';
import classNames from 'classnames';
import HistoryItem from 'store/HistoryStack/HistoryItem';
import styles from './BreadcrumbHiddenContent.module.scss';
import Dialog from 'components/Dialog';
import IconBulletsHorizontal from 'components/Svg/icons/IconBulletsHorizontal';
import IconCarretRight from 'components/Svg/icons/IconCarretRight';
import BreadcrumbItemTitle from '../BreadcrumbItemTitle';

export type HistoryItemWithkeyAndPosition = HistoryItem & {
  key: string;
  position: number;
};

type Props = {
  items: HistoryItemWithkeyAndPosition[];
  goBack: (n: number) => void;
};

const BreadcrumbHiddenContent: React.FC<Props> = (props) => {
  const { goBack, items } = props;

  return (
    <div
      data-testid="breadcrumb-hidden-items"
      className={classNames(styles.itemWrapper, styles.clickable)}
    >
      <Dialog
        trigger="click"
        placement="bottom-start"
        variant="transparent"
        content={
          <div className={styles.popover}>
            {items
              .sort((a, b) => {
                if (a.position > b.position) {
                  return 1;
                }
                return -1;
              })
              .map((item, i) => (
                <BreadcrumbItemTitle
                  variant="block"
                  key={`${item.pathname}-hidden-${item.key}`}
                  item={item}
                  onClick={() => goBack(item.position)}
                  active={false}
                />
              ))}
          </div>
        }
      >
        <IconBulletsHorizontal size={14} />
      </Dialog>
      <IconCarretRight
        colorToken="color-text-disabled"
        className={styles.separator}
      />
    </div>
  );
};

export default BreadcrumbHiddenContent;
