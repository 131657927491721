import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import reactNodeToString from 'react-node-to-string';
import './Text.scss';

type Variant =
  | 'display-1'
  | 'heading-1'
  | 'heading-2'
  | 'body-lg'
  | 'body-md'
  | 'subtext'
  | 'subtext-small';

export type TextColorToken =
  | 'text-main-primary'
  | 'text-main-secondary'
  | 'text-main-tertiary'
  | 'text-main-disabled'
  | 'text-main-subtle'
  | 'text-action-primary'
  | 'text-action-subdued'
  | 'text-action-alternative'
  | 'text-action-brand'
  | 'text-deco-primary'
  | 'text-deco-secondary'
  | 'text-error';

type FontWeight = 'normal' | 'medium' | 'semi-bold' | 'bold';

export interface TextProps extends React.HTMLProps<HTMLParagraphElement> {
  variant?: Variant;
  fontWeight?: FontWeight;
  color?: TextColorToken;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  to?: string;
  align?: 'left' | 'center' | 'right';
  hoverEffect?: 'underline';
  dataTestId?: string;
  dataCy?: string;
  ellipsis?: boolean;
  noWrap?: boolean;
}

const Text: React.FC<TextProps> = ({
  children,
  className,
  as = 'span',
  to,
  color = 'text-main-secondary',
  fontWeight,
  align,
  href,
  style,
  hoverEffect,
  variant,
  dataTestId,
  dataCy,
  ellipsis,
  noWrap,
  ...rest
}) => {
  const elementRef = React.useRef<any>();
  const Component: keyof JSX.IntrinsicElements | React.FC<any> = to ? Link : as;
  const variantClassName = variant ? `TDB-Text--${variant}` : '';
  const fontWeightClassName = fontWeight ? `TDB-Text--fw-${fontWeight}` : '';
  const hoverEffectClassName = hoverEffect
    ? `TDB-Text--hover-${hoverEffect}`
    : '';

  const textIsTruncated = React.useMemo(() => {
    const element = elementRef.current;
    if (!ellipsis || !element) {
      return false;
    }

    if (element.clientWidth < element.scrollWidth) {
      return true;
    }

    return false;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [ellipsis, elementRef.current]);

  return (
    <Component
      ref={elementRef}
      data-testid={dataTestId}
      data-cy={dataCy}
      style={{ textAlign: align, color: `var(--${color})`, ...style }}
      title={textIsTruncated ? reactNodeToString(children) : null}
      className={classNames(
        'TDB-Text',
        fontWeightClassName,
        hoverEffectClassName,
        variantClassName,
        className,
        {
          'TDB-Text--ellipsis': ellipsis,
          'TDB-Text--truncated': textIsTruncated,
          'TDB-Text--no-wrap': noWrap,
        }
      )}
      to={to}
      href={href}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Text;
