const somaRouterPaths = {
  root: '/soma',
  details: '/soma/:namespace/:groupId',
  overview: '/soma/:namespace/:groupId/overview',
  sharing: '/soma/:namespace/:groupId/sharing',
  settings: '/soma/:namespace/:groupId/settings',
  contents: '/soma/:namespace/:groupId/contents',
  metadata: '/soma/:namespace/:groupId/metadata',
  activity: '/soma/:namespace/:groupId/activity',
};

export default somaRouterPaths;
