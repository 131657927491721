const somaLinks = {
  details: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}`,

  overview: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/overview`,

  sharing: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/sharing`,

  settings: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/settings`,

  contents: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/contents`,

  metadata: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/metadata`,

  activity: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/soma/${namespace}/${groupId}/activity`,
};

export default somaLinks;
