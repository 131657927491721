import epochToDate from '../epochToDate';
import millisecondsToSeconds from '../millisecondsToSeconds';

/**
 * Fragment timestamps are in milliseconds
 * BUT
 * Due to a bug in REST some timestamps were saved in seconds
 * As a result we have to support both
 */
const fragmentTimestampToDate = (ts: number) => {
  let timestamp = ts;

  /**
   * Get current unix timestamp (UTC) and check against the input
   * Check if timestamp is off by 1000 and convert it to seconds
   */
  const utcDate = new Date().toUTCString();
  const currentUTCTimestamp = new Date(utcDate).getTime();
  const tsDiff = currentUTCTimestamp / timestamp;
  if (tsDiff > 0.1 && tsDiff < 10) {
    timestamp = millisecondsToSeconds(timestamp);
  }

  return epochToDate(timestamp);
};

export default fragmentTimestampToDate;
