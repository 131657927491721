import { GroupInfo } from 'api-client';
import { AxiosResponse } from 'axios';
import { createPath as createGetGroupQueryKey } from 'hooks/api/Groups/useGetGroup';
import queryClient from 'utils/helpers/queryClient';

type Params = Pick<GroupInfo, 'id' | 'namespace'>;

const getGroupInfoFromCache = (params: Params): GroupInfo | undefined => {
  const { id, namespace } = params;
  const queryKey = createGetGroupQueryKey({
    groupNamespace: namespace,
    groupName: id,
  });

  const res = queryClient
    .getQueryCache()
    .find<unknown, unknown, AxiosResponse<GroupInfo>>([queryKey], {
      exact: false,
    });

  return res?.state.data?.data;
};

export default getGroupInfoFromCache;
