function setupEnv(): void {
  (global as any).__DEV__ = process.env.REACT_APP_TILEDB_ENV === 'development';
  (global as any).__PROD__ = process.env.REACT_APP_TILEDB_ENV === 'production';
  (global as any).__STAGE__ = process.env.REACT_APP_TILEDB_ENV === 'staging';
  (global as any).__TEST__ = process.env.REACT_APP_TILEDB_ENV === 'test';
  (global as any).__ENTERPRISE__ = process.env.REACT_APP_ENTERPRISE === 'true';
  (global as any).__CI__ = process.env.REACT_APP_CI === 'true';
}

export default setupEnv;
