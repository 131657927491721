import isString from 'utils/helpers/string/isString';
declare const global: any;

/**
 * Indicates whether a config is set from golang
 * Checking if the value is truthy and does not start with go's template syntax "{{ ." i.e. {{ .Var }}
 */
const configExists = (variableName: string) => {
  const value = global[variableName] as string;

  if (isString(value) && value.startsWith('{{ .')) {
    return false;
  }

  return !!value;
};

export default configExists;
