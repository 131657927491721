import './Snackbar.scss';
import React from 'react';
import Text from 'components/Text';
import IconClose from 'components/Svg/icons/IconClose';
import IconCheckFilled from 'components/Svg/icons/IconCheckFilled';
import IconClosefilled from 'components/Svg/icons/IconClosefilled';
import { motion } from 'framer-motion';
import Button from 'components/v2/Button';
import { Icon } from 'components/Svg';
import classNames from 'classnames';

export type SnackbarVariant = 'success' | 'error';

export type SnackbarDismissMode = 'auto' | 'manual';

interface SnackbarActionBase {
  text: string;
  icon?: Icon;
  iconRight?: boolean;
}

interface SnackbarActionWithOnClick extends SnackbarActionBase {
  onClick: () => void;
  to?: never;
}

interface SnackbarActionWithLink extends SnackbarActionBase {
  onClick?: never;
  to: string;
}

export type SnackbarAction = SnackbarActionWithOnClick | SnackbarActionWithLink;

export interface SnackbarProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  variant?: SnackbarVariant;
  id: string;
  onDismiss: (id: string) => void;
  dismissMode?: SnackbarDismissMode;
  timesCalled?: number;
  action?: SnackbarAction;
}

const Snackbar: React.FC<SnackbarProps> = (props) => {
  const {
    variant = 'success',
    title,
    description,
    id,
    onDismiss,
    dismissMode = 'auto',
    timesCalled,
    action,
  } = props;

  return (
    <motion.div
      variants={{
        initial: { x: '-100%' },
        animate: { x: 0 },
        exit: { x: '-100%' },
      }}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.2 }}
      className={classNames('TDB-Snackbar', `TDB-Snackbar--${variant}`, {
        'TDB-Snackbar--auto-dismiss': dismissMode === 'auto',
      })}
      role={variant === 'error' ? 'alert' : 'status'}
    >
      <div className="TDB-Snackbar__ContentWrapper">
        <div className="TDB-Snackbar__Gradient" />
        <div className="TDB-Snackbar__Content">
          <div className="TDB-Snackbar__Icon">
            {variant === 'success' && (
              <IconCheckFilled colorToken="text-action-brand" size="3rem" />
            )}
            {variant === 'error' && (
              <IconClosefilled colorToken="text-error" size="3rem" />
            )}
          </div>
          <div className="TDB-Snackbar__Text">
            <Text
              color="text-main-primary"
              fontWeight="bold"
              className="TDB-Snackbar__Title"
              dataTestId="snackbar-title"
            >
              {title}
            </Text>
            {description && (
              <Text
                color="text-main-secondary"
                className="TDB-Snackbar__Description"
                fontWeight="semi-bold"
                variant="subtext"
                dataTestId="snackbar-description"
              >
                {description}
              </Text>
            )}
          </div>
          {action && (
            <Button
              variant="plain"
              size="large"
              onClick={action.onClick}
              icon={!action.iconRight ? action.icon : undefined}
              iconRight={!!action.iconRight ? action.icon : undefined}
            >
              {action.text}
            </Button>
          )}
        </div>
        <button
          className="TDB-Snackbar__DismissButton"
          onClick={() => onDismiss(id)}
          data-testid="dismiss-snackbar-button"
        >
          <IconClose size="2rem" colorToken="text-main-secondary" />
        </button>
        {dismissMode === 'auto' && (
          <div className="TDB-Snackbar__Timer" key={timesCalled}>
            <div className="TDB-Snackbar__Timer__Indicator" />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Snackbar;
