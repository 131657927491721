import React from 'react';
import Cookies from 'components/Cookies';
import Routes from './pages/Routes';
import { Router, BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import history from 'utils/helpers/history';
import queryClient from 'utils/helpers/queryClient';
import { ThemeProvider } from 'store/ThemeContext/ThemeContext';
import { QueryClientProvider } from '@tanstack/react-query';
import SnackbarContainer from 'containers/SnackbarContainer';
import 'App.scss';
import { UserProvider } from 'store/UserContext';
import { updateRedirectToAfterLogin } from 'utils/helpers/history/history';
import useMount from 'hooks/useMount';

function App(): JSX.Element {
  useMount(() => {
    // Saves the initial pathname visited to session storage
    // This value will be retrieved on a successful login to redirect the user to that path
    updateRedirectToAfterLogin(
      history.location.pathname,
      history.location.search
    );
  });

  /* Prepend BASE_PATH to support serving the UI from a subpath i.e. /ui (for enterprise) */
  return (
    <BrowserRouter basename={global.BASE_PATH}>
      <Router history={history}>
        <ThemeProvider>
          <SnackbarContainer />
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <Routes />
                <Cookies />
              </UserProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Router>
    </BrowserRouter>
  );
}

export default App;
