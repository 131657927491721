import isAxiosError from '../isAxiosError';

const errorHasStatusCode = (statusCode: number, err: unknown): boolean => {
  if (isAxiosError(err)) {
    return err?.response?.status === statusCode;
  }
  return false;
};

export default errorHasStatusCode;
