import { useEffect } from 'react';
import useJupyter, {
  JupyterStatus,
  userHasNoCreditsSelector,
} from 'store/JupyterStore';
import useJupyterOutgoingMessages from '../useJupyterOutgoingMessages';

export interface AutoLaunchOptions {
  fileProperties?: Record<string, string>;
  iframe: React.MutableRefObject<HTMLIFrameElement | null>;
  enabled: boolean;
}

/**
 * Hook that sends a message to the jupyterlab iframe
 * in order to autolaunch a notebook. Jupyterlab upon receiving this message,
 * selects the appropriate server profile & image and clicks the submit button
 * instantaneously without the user having to see the spawn page at all.
 *
 * Before sending the message we have to check a few parameters,
 * e.g. we need to wait for spawn page to load, check if we are in autolaunching mode
 *
 * Example of a message sent
 * {
 *   action: 'TILEDB_AUTOLOAD_IMAGE',
 *    payload: { image: 'genomics_geospatial', profile: 'large' },
 *  }
 */
const useAutoLaunch = (options: AutoLaunchOptions) => {
  const { enabled, iframe, fileProperties } = options;
  const setActiveServerImage = useJupyter(
    (state) => state.setActiveServerImage
  );
  const setLaunchingProgress = useJupyter(
    (state) => state.setLaunchingProgress
  );
  const { selectServerAndImageProfilesAndSubmit } =
    useJupyterOutgoingMessages(iframe);
  const status = useJupyter((state) => state.status);
  const userHasNoCredits = useJupyter(userHasNoCreditsSelector);
  useEffect(() => {
    if (
      !userHasNoCredits &&
      status === JupyterStatus.Idle &&
      enabled &&
      fileProperties?.size &&
      fileProperties.image
    ) {
      selectServerAndImageProfilesAndSubmit(fileProperties, () => {
        setLaunchingProgress(0);
        setActiveServerImage(fileProperties.image);
      });
    }
  }, [
    enabled,
    fileProperties,
    selectServerAndImageProfilesAndSubmit,
    setActiveServerImage,
    setLaunchingProgress,
    status,
    userHasNoCredits,
  ]);
};

export default useAutoLaunch;
