import * as React from 'react';

type Child = React.ReactNode | React.ComponentClass | React.FunctionComponent;

interface Props<T extends Child> {
  children: T;
  condition: boolean;
  left: (props: T) => any;
  right?: (props: T) => any;
}

function ConditionalWrapper<T extends Child>(props: Props<T>): JSX.Element {
  const { children, condition, left, right } = props;

  return <>{condition ? left(children) : right && right(children)}</>;
}

export default ConditionalWrapper;
