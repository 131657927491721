const taskGraphRouterPaths = {
  root: '/taskgraphs',
  details: '/taskgraphs/details/:namespace/:arrayId',
  overview: '/taskgraphs/details/:namespace/:arrayId/overview',
  sharing: '/taskgraphs/details/:namespace/:arrayId/sharing',
  settings: '/taskgraphs/details/:namespace/:arrayId/settings',
  preview: '/taskgraphs/details/:namespace/:arrayId/preview',
  activity: '/taskgraphs/details/:namespace/:arrayId/activity',
};

export default taskGraphRouterPaths;
