import { useState, useEffect } from 'react';

type AsyncState<T> = {
  data: T | null;
  error: Error | null;
  isLoading: boolean;
};

const useAsync = <T>(asyncPromise: Promise<T>): AsyncState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    asyncPromise
      .then((result) => {
        setData(result);
      })
      .catch((err) => {
        setError(err as any);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [asyncPromise]);

  return { data, error, isLoading };
};

export default useAsync;
