import { ArrayInfo } from 'api-client';
import { AxiosResponse } from 'axios';
import { createPath as createGetArrayInfoQueryKey } from 'hooks/api/Arrays/useGetArray';
import queryClient from 'utils/helpers/queryClient';

type Params = Pick<ArrayInfo, 'id' | 'namespace'>;

const getArrayInfoFromCache = (params: Params): ArrayInfo | undefined => {
  const { id, namespace } = params;
  const queryKey = createGetArrayInfoQueryKey({
    namespace,
    array: id,
  });

  const res = queryClient
    .getQueryCache()
    .find<unknown, unknown, AxiosResponse<ArrayInfo>>([queryKey], {
      exact: false,
    });

  return res?.state.data?.data;
};

export default getArrayInfoFromCache;
