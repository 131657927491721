import * as React from 'react';
import HiddenJupyterIframe from './HiddenJupyterIframe';
import useJupyter, { JupyterStatus } from 'store/JupyterStore';
import useSnackbar from 'store/SnackbarStore';
import usePollingNotebookStatus from 'hooks/jupyter/usePollingNotebookStatus';
import './JupyterContainer.scss';

type Props = {};

const JupyterContainer: React.FC<Props> = (props) => {
  const showSnackbar = useSnackbar((state) => state.showSnackbar);
  const { status, progress, server, image } = useJupyter((state) => ({
    status: state.status,
    progress: state.progress,
    server: state.server,
    image: state.image,
  }));
  /**
   * User would have started the launching process by selecting an image & server profile.
   */
  const userHasStartedLaunchingProcess = server && image;
  usePollingNotebookStatus();

  React.useEffect(() => {
    if (!userHasStartedLaunchingProcess) {
      return;
    }

    if (status === JupyterStatus.Running) {
      showSnackbar({
        title: 'Application launched',
        variant: 'success',
        description: 'The application will appear on your screen momentarily.',
      });
    }
  }, [status, progress, userHasStartedLaunchingProcess, showSnackbar]);

  return (
    <div className="TDB-JupyterContainer">
      <HiddenJupyterIframe />
    </div>
  );
};

export default JupyterContainer;
