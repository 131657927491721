import React, { Suspense, useEffect } from 'react';
import classNames from 'classnames';
import useModal, { ModalType } from 'store/ModalStore';
import './ModalContainer.scss';
import lazyLoadComponent from 'utils/helpers/lazyLoadComponent';
/**
 * Lazy load modals
 */
const ModalCropImage = lazyLoadComponent(
  () => import('../../components/Modals/ModalCropImage')
);
const ModalSelectRegion = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectRegion')
);
const ModalText = lazyLoadComponent(
  () => import('../../components/Modals/ModalText')
);
const ModalAddNotebook = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddNotebook')
);
const ModalAddFile = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddFile')
);
const ModalAddCodeAssets = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddCodeAssets')
);
const ModalConfirm = lazyLoadComponent(
  () => import('../../components/Modals/ModalConfirm')
);
const ModalAddLicense = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddLicense')
);
const ModalServerMismatch = lazyLoadComponent(
  () => import('../../components/Modals/ModalServerMismatch')
);
const ModalCreditCard = lazyLoadComponent(
  () => import('../../components/Modals/ModalCreditCard')
);
const ModalClaimCredits = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalClaimCredits')
);
const ModalAPIToken = lazyLoadComponent(
  () => import('../../components/Modals/ModalAPIToken')
);
const ModalInvoice = lazyLoadComponent(
  () => import('../../components/Modals/ModalInvoice')
);
const ModalRenameOrganization = lazyLoadComponent(
  () => import('../../components/Modals/ModalRenameOrganization')
);
const ModalRegisterArray = lazyLoadComponent(
  () => import('../../components/Modals/ModalRegisterArray')
);
const ModalSelectFile = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectFile')
);
const ModalRenameArray = lazyLoadComponent(
  () => import('../../components/Modals/ModalRenameArray')
);
const ModalUploadFile = lazyLoadComponent(
  () => import('../../components/Modals/ModalUploadFile')
);
const ModalShareArray = lazyLoadComponent(
  () => import('../../components/Modals/ModalShareArray')
);
const ModalInviteMemberToOrganization = lazyLoadComponent(
  () => import('../../components/Modals/ModalInviteMemberToOrganization')
);
const ModalChangeCloudCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalChangeCloudCredentials')
);
const ModalChangeGroupCloudCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalChangeGroupCloudCredentials')
);
const ModalPublicShareArray = lazyLoadComponent(
  () => import('../../components/Modals/ModalPublicShareArray')
);
const ModalMarkArrayAsReadOnly = lazyLoadComponent(
  () => import('../../components/Modals/ModalMarkArrayAsReadOnly')
);
const ModalRequestCredits = lazyLoadComponent(
  () => import('../../components/Modals/ModalRequestCredits')
);
const ModalAddAssetsToGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddAssetsToGroup')
);
const ModalAddDataAssets = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddDataAssets')
);
const ModalCreateGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalCreateGroup')
);
const ModalRegisterGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalRegisterGroup')
);
const ModalRenameGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalRenameGroup')
);
const ModalRemoveGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalRemoveGroup')
);
const ModalShareGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalShareGroup')
);
const ModalPublicShareGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalPublicShareGroup')
);
const ModalSelectGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectGroup')
);
const ModalCopyNotebook = lazyLoadComponent(
  () => import('../../components/Modals/ModalCopyNotebook')
);
const ModalCopyUDF = lazyLoadComponent(
  () => import('../../components/Modals/ModalCopyUDF')
);
const ModalUploadNotebook = lazyLoadComponent(
  () => import('../../components/Modals/ModalUploadNotebook')
);
const ModalCreateNotebook = lazyLoadComponent(
  () => import('../../components/Modals/ModalCreateNotebook')
);
const ModalAddGroup = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddGroup')
);
const ModalIngestVCF = lazyLoadComponent(
  () => import('../../components/Modals/ModalIngestVCF')
);
const ModalIngestSOMA = lazyLoadComponent(
  () => import('../../components/Modals/ModalIngestSOMA')
);
const ModalAddAsset = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddAsset')
);
const ModalChangeUsername = lazyLoadComponent(
  () => import('../../components/Modals/ModalChangeUsername')
);
const ModalAWSCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalAWSCredentials')
);
const ModalSelectCloudCredentialsProvider = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectCloudCredentialsProvider')
);
const ModalSelectAWSCredentialsFormat = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectAWSCredentialsFormat')
);
const ModalSelectAzureCredentialsFormat = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectAzureCredentialsFormat')
);
const ModalSelectGCPCredentialsFormat = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectGCPCredentialsFormat')
);
const ModalGCPKeyIDCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalGCPKeyIDCredentials')
);
const ModalGCPServiceAccountCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalGCPServiceAccountCredentials')
);
const ModalARNAWSCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalARNAWSCredentials')
);
const ModalARNAWSCredentialsSetup = lazyLoadComponent(
  () => import('../../components/Modals/ModalARNAWSCredentialsSetup')
);
const ModalARNAWSCredentialsRoleInfo = lazyLoadComponent(
  () => import('../../components/Modals/ModalARNAWSCredentialsRoleInfo')
);
const ModalLaunchServer = lazyLoadComponent(
  () => import('../../components/Modals/ModalLaunchServer')
);
const ModalImportVerticalAsset = lazyLoadComponent(
  () => import('../../components/Modals/ModalImportVerticalAsset')
);
const ModalAzureCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalAzureCredentials')
);
const ModalAzureTokenCredentials = lazyLoadComponent(
  () => import('../../components/Modals/ModalAzureTokenCredentials')
);
const ModalApplyRootPathToStoragePaths = lazyLoadComponent(
  () => import('../../components/Modals/ModalApplyRootPathToStoragePaths')
);
const ModalAddSSOConnection = lazyLoadComponent(
  () => import('../../components/Modals/ModalAddSSOConnection')
);
const ModalSSODomainSetup = lazyLoadComponent(
  () => import('../../components/Modals/ModalSSODomainSetup')
);
const ModalSelectRegionAndServerProfile = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectRegionAndServerProfile')
);
const ModalWelcome = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalWelcome')
);
const ModalProfileSetup = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalProfileSetup')
);
const ModalConnectStorageIntro = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalConnectStorageIntro')
);
const ModalConnectStorage = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalConnectStorage')
);
const ModalAddAssetIntro = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalAddAssetIntro')
);
const ModalSkipOnboarding = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalSkipOnboarding')
);
const ModalUserOnboarding = lazyLoadComponent(
  () => import('../../components/ModalsV2/Onboarding/ModalUserOnboarding')
);
const ModalCreateOrganization = lazyLoadComponent(
  () => import('../../components/Modals/ModalCreateOrganization')
);
const ModalWelcomeToOrganization = lazyLoadComponent(
  () =>
    import('../../components/ModalsV2/Onboarding/ModalWelcomeToOrganization')
);
const ModalRemoveAsset = lazyLoadComponent(
  () => import('../../components/Modals/ModalRemoveAsset')
);
const ModalIngestBiomedicalImaging = lazyLoadComponent(
  () => import('../../components/Modals/ModalIngestBiomedicalImaging')
);
const ModalIngestFile = lazyLoadComponent(
  () => import('../../components/Modals/ModalIngestFile')
);
const ModalLaunchApp = lazyLoadComponent(
  () => import('../../components/Modals/ModalLaunchApp')
);
const ModalSelectAssetToLaunch = lazyLoadComponent(
  () => import('../../components/Modals/ModalSelectAssetToLaunch')
);
const ModalIngestPointCloud = lazyLoadComponent(
  () => import('../../components/Modals/ModalIngestPointCloud')
);
const ModalBulkRemoveAssets = lazyLoadComponent(
  () => import('../../components/ModalsV2/ModalBulkRemoveAssets')
);
const ModalBulkChangeAssetCredentials = lazyLoadComponent(
  () => import('../../components/ModalsV2/ModalBulkChangeAssetCredentials')
);
const ModalUpdateAssetMetadata = lazyLoadComponent(
  () => import('../../components/ModalsV2/ModalUpdateAssetMetadata')
);
const ModalInfo = lazyLoadComponent(
  () => import('../../components/ModalsV2/ModalInfo')
);
/**
 * Declare here any modals available for rendering
 * See Provider's returned JSX
 */
export const availableModals: {
  [name: string]: React.LazyExoticComponent<any>;
} = {
  ModalCropImage,
  ModalSelectRegion,
  ModalConfirm,
  ModalServerMismatch,
  ModalText,
  ModalCreditCard,
  ModalClaimCredits,
  ModalUploadNotebook,
  ModalImportVerticalAsset,
  ModalCreateNotebook,
  ModalUserOnboarding,
  ModalAddDataAssets,
  ModalAPIToken,
  ModalAddNotebook,
  ModalAddCodeAssets,
  ModalIngestSOMA,
  ModalIngestVCF,
  ModalInvoice,
  ModalLaunchServer,
  ModalAddLicense,
  ModalRenameOrganization,
  ModalRegisterArray,
  ModalRenameArray,
  ModalShareArray,
  ModalInviteMemberToOrganization,
  ModalChangeCloudCredentials,
  ModalChangeGroupCloudCredentials,
  ModalAddFile,
  ModalPublicShareArray,
  ModalCopyNotebook,
  ModalCopyUDF,
  ModalMarkArrayAsReadOnly,
  ModalRequestCredits,
  ModalUploadFile,
  ModalGCPKeyIDCredentials,
  ModalAddAssetsToGroup,
  ModalCreateGroup,
  ModalRegisterGroup,
  ModalRenameGroup,
  ModalShareGroup,
  ModalGCPServiceAccountCredentials,
  ModalPublicShareGroup,
  ModalSelectFile,
  ModalSelectGroup,
  ModalAddGroup,
  ModalRemoveGroup,
  ModalAddAsset,
  ModalChangeUsername,
  ModalSelectCloudCredentialsProvider,
  ModalSelectAzureCredentialsFormat,
  ModalSelectGCPCredentialsFormat,
  ModalAzureTokenCredentials,
  ModalAWSCredentials,
  ModalSelectAWSCredentialsFormat,
  ModalARNAWSCredentials,
  ModalARNAWSCredentialsSetup,
  ModalARNAWSCredentialsRoleInfo,
  ModalAzureCredentials,
  ModalSelectRegionAndServerProfile,
  ModalApplyRootPathToStoragePaths,
  ModalAddSSOConnection,
  ModalSSODomainSetup,
  ModalWelcome,
  ModalProfileSetup,
  ModalConnectStorageIntro,
  ModalConnectStorage,
  ModalAddAssetIntro,
  ModalSkipOnboarding,
  ModalCreateOrganization,
  ModalWelcomeToOrganization,
  ModalRemoveAsset,
  ModalIngestBiomedicalImaging,
  ModalIngestFile,
  ModalLaunchApp,
  ModalSelectAssetToLaunch,
  ModalIngestPointCloud,
  ModalBulkRemoveAssets,
  ModalBulkChangeAssetCredentials,
  ModalUpdateAssetMetadata,
  ModalInfo,
};

const ModalContainer: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const modals = useModal((state) => state.modals);
  const flushModals = useModal((state) => state.flushModals);
  const popModal = useModal((state) => state.popModal);

  /**
   * Prevent scroll on body when a popup is active
   */
  useEffect(() => {
    if (modals.length) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.classList.add('noscroll');
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.classList.remove('noscroll');
      document.body.style.paddingRight = '';
    }
  }, [modals]);

  useEffect(() => {
    // Add event listener on the Escape button
    // Check if the last modal in the stack (the active one) is dismissable
    // If it is dismissable, pop it and go back to the previous modal.
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        const lastModal = modals[modals.length - 1];
        if (lastModal && !lastModal.noDismissOnEscape) {
          popModal();
        }
      }
    };

    // Remove the listener on update and set the new one
    window.removeEventListener('keydown', handler);
    window.addEventListener('keydown', handler);
    return (): void => {
      // Remove listener on unmount
      window.removeEventListener('keydown', handler);
    };
  }, [modals, popModal]);

  return (
    <>
      {children}
      <Suspense fallback={null}>
        <div
          className={classNames('TDB-Modal', {
            'TDB-Modal--visible': modals.length,
          })}
        >
          <div
            className="TDB-Modal__Background"
            onClick={flushModals}
            title="Dismiss modal"
          />

          {/* <AnimatePresence> */}
          {modals.map((modalParams: ModalType, i: number) => {
            // Passing active prop to css-hide modals but keep their internal state
            const { name, ...rest } = modalParams;
            const active = i === modals.length - 1;
            return React.createElement(availableModals[name], {
              params: rest,
              active,
              key: `modal-${name}`,
            });
          })}
          {/* </AnimatePresence> */}
        </div>
      </Suspense>
    </>
  );
};

export default ModalContainer;
