import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM7.95756 19.6545L11.4076 24.7545L7.67256 30.3645H10.3126L12.6976 26.6595L15.0376 30.3645H17.8426L14.1076 24.7695L17.5426 19.6545H14.9626L12.7876 23.0745L10.6876 19.6545H7.95756ZM22.1024 19.6545H18.7874V30.3645H20.9924V22.8495H21.0224L23.6474 30.3645H25.4624L28.0874 22.7745H28.1174V30.3645H30.3224V19.6545H27.0074L24.6374 27.0195H24.6074L22.1024 19.6545ZM34.7508 19.6545H32.3958V30.3645H39.9708V28.3845H34.7508V19.6545Z"
  />,
  'xml'
);
