const taskgraphLogsLinks = {
  root: () => '/taskgraphlogs',
  details: ({
    namespace,
    taskGraphId,
  }: {
    namespace: string;
    taskGraphId: string;
  }) => `/taskgraphlogs/${namespace}/${taskGraphId}`,
};

export default taskgraphLogsLinks;
