import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M44 39H39V44H44V39Z" />
    <path d="M39 34H34V39H39V34Z" />
    <path d="M44 29H39V34H44V29Z" />
    <path d="M39 24H34V29H39V24Z" />
    <path d="M44 19H39V24H44V19Z" />
    <path d="M39 14H34V19H39V14Z" />
    <path d="M44 9H39V14H44V9Z" />
    <path d="M39 4H34V9H39V4Z" />
    <path d="M34 9H29V14H34V9Z" />
    <path d="M29 4H24V9H29V4Z" />
    <path d="M24 9H19V14H24V9Z" />
    <path d="M19 4H14V9H19V4Z" />
    <path d="M14 9H9V14H14V9Z" />
    <path d="M9 4H4V9H9V4Z" />
    <path d="M9 14H4V19H9V14Z" />
    <path d="M14 19H9V24H14V19Z" />
    <path d="M9 24H4V29H9V24Z" />
    <path d="M14 29H9V34H14V29Z" />
    <path d="M9 34H4V39H9V34Z" />
    <path d="M14 39H9V44H14V39Z" />
    <path d="M19 34H14V39H19V34Z" />
    <path d="M24 39H19V44H24V39Z" />
    <path d="M29 34H24V39H29V34Z" />
    <path d="M34 39H29V44H34V39Z" />
    <path d="M24 29H19V34H24V29Z" />
    <path d="M19 24H14V29H19V24Z" />
    <path d="M24 19H19V24H24V19Z" />
    <path d="M19 14H14V19H19V14Z" />
    <path d="M29 14H24V19H29V14Z" />
    <path d="M29 24H24V29H29V24Z" />
    <path d="M34 29H29V34H34V29Z" />
    <path d="M34 19H29V24H34V19Z" />
  </>,
  'png'
);
