import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AssetListSummariesResponseData,
  AssetLocations,
  AssetType,
  EnabledFeature,
  NamespaceActions,
  NamespaceType,
  StorageLocation,
} from 'api-client';
import useWorkspaceStore from 'store/WorkspaceSelectedStore';
import { useUserContext } from 'store/UserContext';
import useGetOrganization from 'hooks/api/Organizations/useGetOrganization';
import { AssetLocationKey } from 'utils/constants/AssetLocationKey';
import useListAssetSummaries from 'hooks/api/Assets/useListAssetSummaries';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import { captureException } from '@sentry/browser';
import Loader from 'components/Loader';

/**
 * Initial store
 */
export const store: any = {};

const loadedWorkspaceChannel = 'LOADED_WORKSPACE';
// Channel to broadcast and sync workspace data across all tabs
const tabChannel = new BroadcastChannel(loadedWorkspaceChannel);

/**
 * Create workspace context
 */
const WorkspaceContext = React.createContext(store);

interface WorkspaceProviderProps extends React.PropsWithChildren {
  // for testing only
  __dangerousDefaultWorkspace?: Workspace;
  __dangerousDefaultSummaries?: AssetListSummariesResponseData;
}

export interface Workspace {
  type: NamespaceType;
  namespace: string;
  name: string;
  id: string;
  logo: string | undefined;
  enabledFeatures: EnabledFeature[];
  defaultS3PathCredentialsName: string | undefined;
  defaultS3Path: string | undefined;
  allowedActions: NamespaceActions[];
  assetLocations: AssetLocations | undefined;
  assumeRoleArn?: string;
  assetSummaries?: AssetListSummariesResponseData;
}

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = ({
  children,
  __dangerousDefaultWorkspace,
  __dangerousDefaultSummaries,
}) => {
  const workspaceSelected = useWorkspaceStore(
    (state) => state.workspaceSelected
  );

  const clearWorkspaceSelectedStore = useWorkspaceStore(
    (state) => state.clearWorkspaceSelectedStore
  );

  const setWorkspaceSelected = useWorkspaceStore(
    (store) => store.setWorkspaceSelected
  );
  const { user, isLoggedIn } = useUserContext();
  const [, orgResponse, orgError] = useGetOrganization(
    {
      organization: workspaceSelected?.id as string,
    },
    { noSnackbar: true },
    { enabled: workspaceSelected?.namespaceType === NamespaceType.Organization }
  );

  const [workspace, setWorkspace] = useState<Workspace | undefined>(
    __dangerousDefaultWorkspace
  );

  const [, assetSummariesResponse, assetSummariesError] = useListAssetSummaries(
    { namespace: workspace?.namespace as string },
    {},
    { enabled: !!workspace && !__dangerousDefaultSummaries }
  );
  const assetSummaries =
    __dangerousDefaultSummaries || assetSummariesResponse?.data?.data;

  useDidUpdateEffect(() => {
    if (assetSummariesError) {
      captureException(assetSummariesError);
    }
  }, [assetSummariesError]);

  /**
   * Set user as the current workspace
   * Used in situations like "Delete organization".
   */
  const resetWorkspace = useCallback(() => {
    setWorkspace(undefined);
    clearWorkspaceSelectedStore();
  }, [clearWorkspaceSelectedStore, setWorkspace]);

  /**
   * Delete user data from store on log out
   */
  useEffect(() => {
    if (!isLoggedIn && !workspaceSelected) {
      resetWorkspace();
      return;
    }

    if (
      workspaceSelected?.namespaceType === NamespaceType.Organization &&
      orgError
    ) {
      resetWorkspace();
      return;
    }

    if (user && !workspaceSelected) {
      if (__dangerousDefaultWorkspace) {
        // For Unit tests only
        // if __dangerousDefaultWorkspace exists, it is already in localstorage
        return;
      }

      // save fallback workspace to localstorage
      // this will trigger a rerender and will set workspace to current state
      setWorkspaceSelected({
        id: user.id,
        namespace: user.username,
        namespaceType: NamespaceType.User,
      });
      return;
    }

    if (
      user &&
      (workspaceSelected?.namespaceType === NamespaceType.User ||
        !workspaceSelected)
    ) {
      // If no workspace is set but user exists, set user as the selected workspace
      setWorkspace({
        type: NamespaceType.User,
        id: user.id,
        namespace: user.username,
        name: user.name || '',
        logo: user.logo,
        enabledFeatures: user.enabled_features || [],
        defaultS3Path: user.default_s3_path || '',
        defaultS3PathCredentialsName:
          user.default_s3_path_credentials_name || '',
        allowedActions: [],
        assetLocations: user.asset_locations,
      });
      return;
    }

    if (workspaceSelected?.namespaceType === NamespaceType.Organization) {
      if (orgError) {
        // Set workspace to user on organization error
        setWorkspace({
          type: NamespaceType.User,
          id: user.id,
          namespace: user.username,
          name: user.name || '',
          logo: user.logo,
          enabledFeatures: user.enabled_features || [],
          defaultS3Path: user.default_s3_path || '',
          defaultS3PathCredentialsName:
            user.default_s3_path_credentials_name || '',
          allowedActions: [],
          assetLocations: user.asset_locations,
        });
        return;
      }

      const org = orgResponse?.data;

      if (org) {
        setWorkspace({
          type: NamespaceType.Organization,
          id: org.id,
          namespace: org.name,
          name: org.name,
          logo: org.logo,
          enabledFeatures: org.enabled_features || [],
          defaultS3Path: org.default_s3_path || '',
          defaultS3PathCredentialsName:
            org.default_s3_path_credentials_name || '',
          allowedActions: org.allowed_actions || [],
          assetLocations: org.asset_locations,
          assumeRoleArn: org.assume_role_arn,
        });
      }
      return;
    }
  }, [
    isLoggedIn,
    user,
    workspaceSelected,
    orgResponse,
    orgError,
    setWorkspaceSelected,
    __dangerousDefaultWorkspace,
    resetWorkspace,
  ]);

  /**
   * Sync user data from other tabs
   */
  tabChannel.onmessage = (ev: MessageEvent): void => {
    setWorkspace(ev.data);
  };

  function hasEnabledFeature(feature: EnabledFeature): boolean | undefined {
    return workspace?.enabledFeatures?.includes(feature);
  }

  const getStorageLocationByKey = useCallback(
    (key: AssetLocationKey): StorageLocation | undefined =>
      workspace?.assetLocations?.[key],
    [workspace]
  );

  const getDefaultStorageLocation = useCallback(
    (key?: AssetLocationKey): StorageLocation | undefined => {
      const rootStorageLocation = {
        path: workspace?.defaultS3Path,
        credentials_name: workspace?.defaultS3PathCredentialsName,
      };
      if (!key) {
        return rootStorageLocation;
      }
      return workspace?.assetLocations?.[key] || rootStorageLocation;
    },
    [workspace]
  );

  const isUserWorkspace = workspace?.type === NamespaceType.User;
  const isOrganizationWorkspace =
    workspace?.type === NamespaceType.Organization;

  const hasEditPermissions = useMemo(
    () =>
      isUserWorkspace ||
      (isOrganizationWorkspace &&
        workspace?.allowedActions.includes(NamespaceActions.EditOrganization)),
    [workspace, isUserWorkspace, isOrganizationWorkspace]
  );

  // Checks the existence of the givenAssetType in both owned and shared asset summaries
  const hasAssetsOfType = useCallback(
    (assetType: AssetType) => {
      if (!assetSummaries) {
        return false;
      }
      const ownedOfType = assetSummaries?.owned?.[assetType] || 0;
      const sharedOfType = assetSummaries?.shared?.[assetType] || 0;
      return ownedOfType + sharedOfType > 0;
    },
    [assetSummaries]
  );

  /**
   * Publish values to be used inside our components
   */
  const providerValue = {
    workspace: workspace,
    hasEnabledFeature,
    resetWorkspace,
    isUserWorkspace,
    isOrganizationWorkspace,
    getStorageLocationByKey,
    getDefaultStorageLocation,
    hasEditPermissions,
    assetSummaries,
    hasAssetsOfType,
  };

  if (!workspace && isLoggedIn) {
    return <Loader />;
  }

  return (
    <WorkspaceContext.Provider value={providerValue}>
      {children}
    </WorkspaceContext.Provider>
  );
};

interface WorkspaceContextType {
  workspace: Workspace;
  hasEnabledFeature: (feature: EnabledFeature) => boolean;
  resetWorkspace: () => void;
  isUserWorkspace: boolean;
  isOrganizationWorkspace: boolean;
  /**
   * Returns the storage location for a specific asset type
   * If storage location not found, it returns undefined
   */
  getStorageLocationByKey: (
    key: AssetLocationKey
  ) => StorageLocation | undefined;
  /**
   * Returns the default storage location, either by key or root
   * If storage location by key not found, it fallbacks to root storage location
   */
  getDefaultStorageLocation: (
    key?: AssetLocationKey
  ) => StorageLocation | undefined;
  hasEditPermissions: boolean;
  assetSummaries: AssetListSummariesResponseData | undefined;
  hasAssetsOfType: (assetType: AssetType) => boolean;
}

/**
 * Export workspace pre-defined context
 */
export function useWorkspaceContext(): WorkspaceContextType {
  return useContext(WorkspaceContext);
}

export default WorkspaceContext;
