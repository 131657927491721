import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M12.9446 12.5906H35.8816V35.7486H12.9446V12.5906Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41309 44.1692V4.16919H44.4131V44.1692H4.41309ZM8.67938 39.9584H40.1468V8.37994H8.67938V39.9584Z"
    />
  </>,
  'raster'
);
