import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useModal from 'store/ModalStore';
import { useOnboardingContext } from 'store/OnboardingContext';
import academyRouterPaths from 'utils/routerPaths/academyRouterPaths';

const GetStarted: React.FC = () => {
  const { refetchCompletedOnboardingItems } = useOnboardingContext();
  const showUserOnboardingModal = useModal(
    (state) => state.showUserOnboardingModal
  );
  const { pathname } = useLocation();
  /**
   * Onboarding flow is disabled in the academy page.
   * The user during the onboarding should be able to browse the academy (there is a link for that).
   */
  const shouldShowModalFlow = useMemo(() => {
    return !pathname.startsWith(academyRouterPaths.root);
  }, [pathname]);

  const flushModals = useModal((state) => state.flushModals);
  useEffect(() => {
    if (shouldShowModalFlow) {
      showUserOnboardingModal({
        onSuccess: () => {
          flushModals();
          refetchCompletedOnboardingItems();
        },
      });
    }
  }, [
    flushModals,
    refetchCompletedOnboardingItems,
    shouldShowModalFlow,
    showUserOnboardingModal,
  ]);

  return null;
};

export default GetStarted;
