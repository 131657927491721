import { AssetType } from 'api-client';

const getAssetTypeName = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.Array:
      return 'array';
    case AssetType.Notebook:
      return 'notebook';
    case AssetType.UserDefinedFunction:
      return 'UDF';
    case AssetType.Dashboard:
      return 'dashboard';
    case AssetType.MlModel:
      return 'ML model';
    case AssetType.File:
      return 'file';
    case AssetType.Group:
      return 'group';
    case AssetType.RegisteredTaskGraph:
      return 'task graph';
    case AssetType.Bioimg:
      return 'biomedical imaging';
    case AssetType.Vcf:
      return 'VCF';
    case AssetType.Soma:
      return 'SOMA';
    case AssetType.Pointcloud:
      return 'point cloud';
    case AssetType.Geometry:
      return 'geometry';
    case AssetType.Raster:
      return 'raster';
    case AssetType.VectorSearch:
      return 'vector search';
  }
};

export default getAssetTypeName;
