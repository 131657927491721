const academyLinks = {
  root: () => '/academy',
  whatIsTiledb: () => '/academy/what-is-tiledb',
  getStarted: () => '/academy/get-started/',
  structureImportance: () => '/academy/structure/why-structure-is-important/',
  arraysIntro: () => '/academy/structure/arrays/introduction/',
  cloudCredentials: () =>
    '/academy/accounts/individual/profile/cloud-credentials',
  rootPath: () => `/academy/accounts/individual/profile/storage-paths/`,
  tablesIntro: () => '/academy/structure/tables/introduction/',
  bioimagingIntro: () => '/academy/structure/life-sciences/biomedical-imaging/',
  ml: () => '/academy/structure/ai-ml/ml-models/introduction/',
  vectorSearch: () => '/academy/structure/ai-ml/vector-search/introduction/',
  populationGenomics: () =>
    '/academy/structure/life-sciences/population-genomics/introduction/',
  singleCell: () =>
    '/academy/structure/life-sciences/single-cell/introduction/',
  catalog: () => `/academy/catalog/`,
  catalogSearch: () => `/academy/catalog/search/`,
  monitor: () => `/academy/collaborate/logging/`,
  control: () => `/academy/collaborate/organizations/`,
  share: () => `/academy/collaborate/access-control/share-assets/`,
  groups: () => `/academy/catalog/groups/`,
  comply: () => `/academy/collaborate/introduction/`,
  preview: () => `/academy/analyze/preview-widgets`,
  query: () => `/academy/analyze/notebooks/`,
  visualize: () => `/academy/analyze/dashboards/`,
  scale: () => `/academy/scale/`,
};

export default academyLinks;
