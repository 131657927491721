import React, { Component } from 'react';
import './ErrorBoundary.scss';
import { ForbiddenError, NotFoundError } from 'utils/errors';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { rootPath } from 'utils/links';
import ChunkLoadError from 'utils/errors/ChunkLoadError';
import Placeholder from 'components/v2/Placeholder';

export class ErrorBoundary extends Component<any, {}> {
  state = {
    hasError: false,
    message: '',
    type: '',
    eventId: undefined,
  };

  componentDidCatch(error: any, info: any): void {
    this.setState({
      hasError: true,
      message: error.message,
      type: error.type,
    });
    // send to sentry
    if (!error.type) {
      Sentry.withScope((scope) => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  handleResolve(callback: () => void): void {
    callback();
    this.setState({
      hasError: false,
      message: '',
      type: '',
    });
  }
  render(): any {
    const { hasError, message, type, eventId } = this.state;
    const { history } = this.props;

    if (hasError) {
      if (type === ForbiddenError.type) {
        return (
          <Placeholder
            graphic="unauthorized-access"
            title="Access denied"
            description={
              message || "You don't have permissions to access this page."
            }
            actions={[
              {
                variant: 'primary',
                size: 'large',
                children: 'Back to home',
                onClick: (): void =>
                  this.handleResolve((): void => history.push(rootPath)),
              },
            ]}
          />
        );
      } else if (type === NotFoundError.type) {
        return (
          <Placeholder
            graphic="no-search-results"
            title="Page not found"
            description={
              message ||
              "The page you are looking for might have been removed, had it's name changed or is temporary unavailable."
            }
            actions={[
              {
                variant: 'primary',
                size: 'large',
                children: 'Back to home',
                onClick: (): void =>
                  this.handleResolve((): void => history.push(rootPath)),
              },
            ]}
          />
        );
      } else if (type === ChunkLoadError.type) {
        return (
          <>
            <Placeholder
              graphic="unexpected-error"
              title="New version available. You should refresh your page."
              description="This should happen automatically but you have disabled your session storage and as a result you have to do this manually."
              actions={[
                {
                  variant: 'primary',
                  size: 'large',
                  children: 'Refresh page',
                  onClick: (): void => window.location.reload(),
                },
              ]}
            />
          </>
        );
      } else {
        return (
          <Placeholder
            graphic="unexpected-error"
            title={'Uh oh, something went wrong!'}
            description={
              message ||
              'An unexpected error occurred. Please try again later or contact our team.'
            }
            actions={[
              {
                variant: 'primary',
                size: 'large',
                children: 'Refresh page',
                onClick: (): void => window.location.reload(),
              },
              {
                variant: 'secondary',
                size: 'large',
                children: 'Report issue',
                onClick: (): void => Sentry.showReportDialog({ eventId }),
              },
            ]}
          />
        );
      }
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
