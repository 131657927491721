import { useRef, useEffect } from 'react';

/**
 * Custom hook to use setTimeout in functional components without memory leaks
 */
function useTimeout(callback: Function, delay: number, args: Array<any>): void {
  const timeout = useRef<number>();
  useEffect(() => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(callback, delay);

    return (): void => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, [delay, ...args]);
}

export default useTimeout;
