/**
 * Function to prepend base path if specified
 * It solved a problem in development mode:
 * When running from the devserver, the {{ .BasePath }} in Document does not get replaced by an actual value like GO does
 * This function will tell if the global BASE_PATH is a valid path (starting with "/")
 */
const prependBasePath = (path: string) => {
  const basePath = global.BASE_PATH;

  return basePath.startsWith('/') ? basePath + path : path;
};

export default prependBasePath;
