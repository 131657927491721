import { curry } from 'ramda';
import { DateTime, DurationObject } from 'luxon';
import { isString } from '../string';
import { isNumber } from '../number';
import { formatDistance } from './formatDistance';
import { captureException } from '@sentry/react';

export function isDateTime(date: any): date is DateTime {
  return date instanceof DateTime;
}

export function isDate(date: any): date is Date {
  return date instanceof Date;
}

export const getDateTimeInstanceFromDate = (
  date: string | Date | DateTime | number
): DateTime => {
  if (isString(date)) {
    return DateTime.fromISO(date);
  } else if (isDate(date)) {
    return DateTime.fromJSDate(date);
  } else if (isNumber(date)) {
    return DateTime.fromMillis(date);
  } else if (isDateTime(date)) {
    return date;
  }
  // If date is undefined send error to sentry and provide a default Datetime
  if (!date) {
    captureException(
      new Error(`getDateTimeInstanceFromDate: Input date was ${date}`)
    );
  }

  return DateTime.now();
};

export const plus = (
  obj: DurationObject,
  date: string | Date | DateTime | number
): Date => {
  const dt = getDateTimeInstanceFromDate(date);

  return dt.plus(obj).toJSDate();
};

type Units =
  | 'year'
  | 'month'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

export const endOf = (
  unit: Units,
  date: string | Date | DateTime | number
): Date => {
  const dt = getDateTimeInstanceFromDate(date);

  return dt.endOf(unit).toJSDate();
};

export const startOf = (
  unit: Units,
  date: string | Date | DateTime | number
): Date => {
  const dt = getDateTimeInstanceFromDate(date);

  return dt.startOf(unit).toJSDate();
};

export function dateToEpochSeconds(date: Date | DateTime | number): any {
  if (isDate(date)) {
    return Math.round(date.getTime() / 1000);
  } else if (isDateTime(date)) {
    return date.toSeconds();
  } else if (isNumber(date)) {
    return Math.round(date / 1000);
  }

  return date;
}

export function unixToJSTimestamp(timestamp: number): number {
  return timestamp * 1000;
}

export function formatDate(
  date: string | Date | DateTime,
  dateFormat = 'dd MMM yyyy'
): string {
  return getDateTimeInstanceFromDate(date).toFormat(dateFormat);
}

export const formatTimezonedDate = curry(
  (
    timezone: string,
    date: string | Date | DateTime | number,
    dateFormat: string
  ): string => {
    const fallbackTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = timezone || fallbackTimezone;

    return getDateTimeInstanceFromDate(date).setZone(tz).toFormat(dateFormat);
  }
);

export const relativeTimeInWordsToNow = (
  value: string | number | Date
): string => {
  const date = new Date(value);

  return formatDistance(new Date(Date.now()), date);
};

// Check if the duration (finish time - start time) is more than X milliseconds
export const durationMoreThan =
  (millis: number) =>
  (startTime: string, finishTime: string): boolean => {
    const endDateTime = DateTime.fromISO(finishTime);
    const duration = endDateTime.diff(DateTime.fromISO(startTime));

    return Math.abs(duration.valueOf()) > millis;
  };
