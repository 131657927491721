import React from 'react';
import { useFormikContext, FormikProps } from 'formik';
import Button, { ButtonProps } from './Button';

type Props = Partial<ButtonProps>;

function FormikSubmitButton(
  props: React.PropsWithChildren<Props>
): JSX.Element {
  const { children } = props;

  const context: FormikProps<any> = useFormikContext();

  return (
    <Button
      loading={context.isSubmitting}
      variant="primary"
      size="large"
      {...props}
      type="submit"
    >
      {children}
    </Button>
  );
}

export default FormikSubmitButton;
