import { create } from 'zustand';
import HistoryItem from './HistoryItem';

export interface HistoryStackState {
  historyItems: HistoryItem[];
  push: (item: HistoryItem) => void;
  flush: () => void;
  peek: (n?: number) => HistoryItem | undefined;
  isEmpty: () => boolean;
  pop: () => HistoryItem | undefined;
  goBack: (n?: number) => HistoryItem | undefined;
}

const useHistoryStore = create<HistoryStackState>((set, get) => ({
  historyItems: [],
  push: (item: HistoryItem) => {
    const items = get().historyItems;
    const concatenatedItems = [...items, item];

    set((state: HistoryStackState) => ({
      historyItems: concatenatedItems,
    }));
  },
  pop: () => {
    return get().historyItems.pop();
  },
  flush: () => {
    set(() => ({
      historyItems: [],
    }));
  },
  peek: (n = 1) => {
    const items = get().historyItems;
    return items[items.length - n];
  },
  isEmpty: () => {
    return get().historyItems.length === 0;
  },
  goBack: (n = 1) => {
    const historyItems = get().historyItems;
    const removeNItems = historyItems.slice(0, n * -1);

    set(() => ({
      historyItems: removeNItems,
    }));

    return get().peek();
  },
}));

export const useGetNavigationItemsSinceRoot = (state: HistoryStackState) => {
  const { historyItems, peek } = state;
  /**
   * Get index of the last "root" pathname, to get navigation.
   * Imagine history stack as (0) /overview | (1) /assets | (2) /arrays/demo-username/demo-array
   * The navigation starts from index 1, where the last root pathname is located.
   */
  const rootIndex = historyItems.findLastIndex((item: any) => {
    return item.isRoot;
  });
  const lastItem = peek();
  /**
   * Get current (last) route key
   */
  const lastItemKey = lastItem?.key;

  const historyNavigation = historyItems.slice(
    -1 * (historyItems.length - rootIndex)
  );

  /**
   * Current navigation should be from latest "root" pathname until we find last item's key
   * In case of user is using browser history, there might be multiple items with the same key
   * e.g. /assets -> /groups/demo-username/demo-group -> /arrays/demo-username/demo-array -> /groups/demo-username/demo-group₁
   *
   * ₁ User used browser history to move back. The navigation still should be until the first occurrence of the last item:
   * /assets -> /groups/demo-username/demo-group
   * *
   */
  if (lastItemKey) {
    const itemWithSameKey = historyNavigation.findIndex(
      (item: any) => item?.key === lastItemKey
    );

    return historyNavigation.slice(0, itemWithSameKey + 1);
  }

  return historyNavigation;
};

export const peekNItemInNavigationSelector = (
  state: HistoryStackState,
  n: number
) => {
  //eslint-disable-next-line react-hooks/rules-of-hooks
  const navigationItems = useGetNavigationItemsSinceRoot(state);

  return navigationItems[navigationItems.length - n];
};

export default useHistoryStore;

(window as any).a = useHistoryStore.getState;
