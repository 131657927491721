import React, { ReactNode } from 'react';
import { Notification, TaskGraphEventType } from 'api-client';

const getDescriptionForTaskgraph = (notification: Notification): ReactNode => {
  const taskgraphName = notification.notification_event?.taskgraph?.name;
  switch (notification.notification_event?.taskgraph?.task_graph_event_type) {
    case TaskGraphEventType.Failed:
      return (
        <>
          Taskgraph <strong>{taskgraphName}</strong> has failed.
        </>
      );
    case TaskGraphEventType.Cancelled:
      return (
        <>
          Taskgraph <strong>{taskgraphName}</strong> was cancelled.
        </>
      );
    case TaskGraphEventType.Succeeded:
      return (
        <>
          Taskgraph <strong>{taskgraphName}</strong> has finished successfully.
        </>
      );

    default:
      return '';
  }
};

export default getDescriptionForTaskgraph;
