const pluralize = (
  count: number,
  nonPlural: string,
  plural: string
): string => {
  if (count === 1) {
    return nonPlural;
  }

  return plural;
};

export default pluralize;
