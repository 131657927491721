import React from 'react';
import { createRoot } from 'react-dom/client';
import './polyfills';
import App from './App';
import * as serviceWorker from './serviceWorker';
import setupAxios from './utils/setup/axios';
import setupEnv from './utils/setup/env';
import setupSentry from './utils/setup/sentry';
import resetChunkReloadState from './utils/setup/resetChunkReloadState';
import {
  cleanupUnsetGlobalVariables,
  setDefaultGlobalVariables,
} from './utils/setup/globalVariables';

setupEnv();
setupAxios();
setupSentry();
cleanupUnsetGlobalVariables();
setDefaultGlobalVariables();
resetChunkReloadState();

const rootContainer = document.getElementById('app') as HTMLElement;
const root = createRoot(rootContainer);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
