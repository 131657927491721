const isStorageSupported = (
  webStorageType: 'localStorage' | 'sessionStorage' = 'localStorage'
): boolean => {
  let storage: Storage | undefined;
  try {
    storage = window[webStorageType];
    if (!storage) {
      return false;
    }
    const x = `__storage_test__`;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (err) {
    return false;
  }
};

export default isStorageSupported;
