import { useState } from 'react';

function useLocalStorage(
  key: string,
  initialValue?: string
): [string | undefined, Function] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: string): void => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, value);
    } catch (error) {}
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
