import React from 'react';
import { useOnboardingContext } from 'store/OnboardingContext';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import GetStarted from './GetStarted';
import BasicTour from './BasicTour';

const UserOnboarding: React.FC = () => {
  const { isUserWorkspace } = useWorkspaceContext();
  const {
    completedOnboardingItems,
    isIntroComplete,
    isProfileSetupComplete,
    isBasicTourComplete,
    isUserOnboardingSkipped,
    onboardingState,
  } = useOnboardingContext();

  if (!isUserWorkspace) {
    /**
     * Hide onboarding for non-user workspaces
     */
    return null;
  }

  if (!completedOnboardingItems || !onboardingState) {
    /**
     * Return nothing until completedOnboardingItems are present
     */
    return null;
  }

  if (isUserOnboardingSkipped) {
    /**
     * Return nothing if user has skipped their onboarding
     */
    return null;
  }

  if (!isIntroComplete || !isProfileSetupComplete) {
    return <GetStarted />;
  }

  if (!isBasicTourComplete) {
    return <BasicTour />;
  }

  return null;
};

export default UserOnboarding;
