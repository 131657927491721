import { useEffect, useRef } from 'react';

/**
 * Hook that runs functions ONCE on mount
 * @param args comma separated functions
 */
function useMount(...args: Function[]): void {
  const result = useRef();
  useEffect(() => {
    args.forEach((arg) => {
      if (typeof arg === 'function') {
        result.current = arg();
      } else {
        // istanbul ignore next
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.warn(`Argument ${arg} passed in useMount is not a function.`);
        }
      }
    });

    if (typeof result.current === 'function') {
      return result.current;
    }
    // eslint-disable-next-line
  }, []);
}

export default useMount;
