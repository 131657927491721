import React, { useRef, useState } from 'react';
import './Tooltip.scss';
import { usePopper } from 'react-popper';
import useOnClickOutside from 'hooks/useOnClickOutside';
import Text from 'components/Text';
import { PositioningStrategy } from '@popperjs/core';
import classNames from 'classnames';

type Placements =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

interface PoppperProps extends React.PropsWithChildren {
  content: React.ReactNode | React.ReactNode[];
  trigger?: 'hover' | 'click' | 'manual';
  placement?: Placements;
  className?: string;
  dataCy?: string;
  handleCloseTooltip?: () => void;
  visible?: boolean;
  interactive?: boolean;
  strategy?: PositioningStrategy;
  dataTestID?: string;
  noArrow?: boolean;
  backgroundColor?: string;
}

const Tooltip: React.FC<PoppperProps> = ({
  content,
  children,
  interactive = true,
  placement = 'auto',
  trigger = 'hover',
  className,
  handleCloseTooltip,
  visible,
  dataCy,
  strategy,
  dataTestID,
  noArrow,
  backgroundColor,
}) => {
  const [referenceElement, setReferenceElement] = useState<
    HTMLElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = useState<
    HTMLElement | null | undefined
  >(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const manualMode = trigger === 'manual';
  const hoverMode = trigger === 'hover';
  const clickMode = trigger === 'click';
  const [showTooltip, toggleTooltip] = useState(false);

  useOnClickOutside(tooltipRef, () => {
    if ((visible && manualMode) || clickMode) {
      handleCloseTooltip?.();
      toggleTooltip(() => false);
    }
  });

  if (!content) {
    return <>{children}</>;
  }

  return (
    <div
      data-testid={dataTestID}
      data-cy={dataCy}
      className={classNames('TDB-Tooltip__Wrapper', className)}
      ref={tooltipRef}
      onMouseEnter={(): any =>
        hoverMode && interactive && toggleTooltip(() => true)
      }
      onMouseLeave={(): any =>
        hoverMode && interactive && toggleTooltip(() => false)
      }
    >
      <div
        className="TDB-Tooltip__Wrapper__inner"
        ref={setReferenceElement}
        onClick={(): any =>
          clickMode && toggleTooltip((visibility: any) => !visibility)
        }
        onMouseEnter={(): any =>
          hoverMode && !interactive && toggleTooltip(() => true)
        }
        onMouseLeave={(): any =>
          hoverMode && !interactive && toggleTooltip(() => false)
        }
      >
        {children}
      </div>
      {!(manualMode && !visible) &&
        !((hoverMode || clickMode) && !showTooltip) && (
          <div
            className="TDB-Tooltip"
            role="tooltip"
            id="tooltip"
            ref={setPopperElement}
            style={{ ...styles.popper, backgroundColor }}
            {...attributes.popper}
            onClick={(): any => clickMode && toggleTooltip(() => false)}
          >
            {!noArrow && <div data-testid="tooltip-arrow" data-popper-arrow />}
            {Array.isArray(content) ? (
              <div className="TDB-Tooltip__List">
                {content instanceof Array &&
                  content.map((s: React.ReactNode, i: number) => (
                    <TooltipText key={i}>{s}</TooltipText>
                  ))}
              </div>
            ) : (
              <TooltipText>{content}</TooltipText>
            )}
          </div>
        )}
    </div>
  );
};

const TooltipText: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Text
      className="TDB-Tooltip__Text"
      color="text-action-alternative"
      fontWeight="semi-bold"
      as="div"
      variant="subtext"
    >
      {children}
    </Text>
  );
};

export default Tooltip;
