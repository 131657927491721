import React, { useState, useEffect, useMemo, useRef } from 'react';
import './InputText.scss';
import useFocus from 'hooks/useFocus';
import IconEye from 'components/Svg/icons/IconEye';
import IconEyeHidden from 'components/Svg/icons/IconEyeHidden';
import { Icon } from 'components/Svg';
import debounce from 'utils/helpers/debounce';
import classNames from 'classnames';

export interface InputTextProps {
  className?: string;
  placeholder?: string;
  error?: boolean | string;
  disabled?: boolean;
  initialValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  value?: string;
  autocomplete?: string;
  Icon?: Icon;
  type?: string;
  ariaLabel?: string;
  debounceDelay?: number;
  size?: 'small';
  id?: string;
}

export const InputText: React.FC<InputTextProps> = (props) => {
  const {
    className,
    placeholder,
    error,
    disabled,
    initialValue,
    onChange,
    name,
    autoFocus,
    size,
    tabIndex,
    autocomplete = 'off',
    Icon,
    type,
    ariaLabel,
    debounceDelay = 0,
    id,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputType, setInputType] = useState(type);
  const [inputRef] = useFocus(autoFocus);
  const [hasFocus, setFocus] = useState(autoFocus);
  const isPasswordType = useMemo(() => inputType === 'password', [inputType]);
  const debouncedOnChange = useRef(
    debounce(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
      },
      debounceDelay,
      debounceDelay === 0 // call immediately if no debounce passed
    )
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);
    if (debounceDelay) {
      debouncedOnChange(e);
    } else {
      onChange?.(e);
    }
  };

  const toggleType = (): void => {
    setInputType(isPasswordType ? 'text' : 'password');
  };

  /**
   * Allow input to be controlled
   */
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={classNames('TDB-InputText', className, {
        'TDB-InputText--small': size === 'small',
        'has-error': error,
        'has-button': type === 'password',
        'has-icon': Icon,
        'has-focus': hasFocus,
        'is-disabled': disabled,
      })}
      data-testid="TDB-InputText"
    >
      {Icon && (
        <Icon
          className="TDB-InputText__Icon"
          size={16}
          colorToken="color-text-secondary-default"
        />
      )}
      <input
        ref={inputRef}
        placeholder={placeholder}
        type={inputType}
        disabled={disabled}
        value={inputValue}
        name={name}
        id={id || name}
        tabIndex={tabIndex}
        autoComplete={autocomplete}
        {...rest}
        required={undefined}
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={error ? `${name}__error` : undefined}
        aria-label={ariaLabel as string}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={handleChange}
      />
      {type === 'password' && (
        <>
          <button
            type="button"
            onClick={toggleType}
            className="TDB-InputText__Button"
            aria-label={isPasswordType ? 'Show password' : 'Hide password'}
          >
            {isPasswordType ? <IconEye /> : <IconEyeHidden />}
          </button>
        </>
      )}
    </div>
  );
};

InputText.defaultProps = {
  type: 'text',
  initialValue: '',
};

export default InputText;
