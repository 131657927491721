const arrayRouterPaths = {
  root: '/arrays',
  details: '/arrays/details/:namespace/:arrayId',
  sharing: '/arrays/details/:namespace/:arrayId/sharing',
  overview: '/arrays/details/:namespace/:arrayId/overview',
  schema: '/arrays/details/:namespace/:arrayId/schema',
  metadata: '/arrays/details/:namespace/:arrayId/metadata',
  activity: '/arrays/details/:namespace/:arrayId/activity',
  settings: '/arrays/details/:namespace/:arrayId/settings',
};

export default arrayRouterPaths;
