import useHistoryStore from 'store/HistoryStack';
import HistoryItem from 'store/HistoryStack/HistoryItem';

interface Options {
  exact?: boolean;
  key?: string;
  search?: string;
}

const pushPathnameToToHistoryStack = (pathname: string, opts?: Options) => {
  const exact = opts?.exact;
  const key = opts?.key;
  const historyItem = new HistoryItem({
    pathName: pathname,
    exact: exact ?? undefined,
    search: opts?.search,
    key: key,
  });

  if (historyItem.hasTitle) {
    useHistoryStore.getState().push(historyItem);
  }
};

export default pushPathnameToToHistoryStack;
