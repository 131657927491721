import {
  UserApi_getUser,
  UserApi_getUser$Params,
  UserApi_getUser$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

const useGetUser: UseRequestType<
  UserApi_getUser$Params,
  UserApi_getUser$Response
> = (__, config, queryConfig) => {
  const { isFetching, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_getUser$Response>,
    UserApi_getUser$Params
  >(['/user'], useQueryRequest(UserApi_getUser, config), queryConfig);

  return [refetch, data, error, isFetching];
};

export default useGetUser;
