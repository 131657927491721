import React, { createContext, useContext } from 'react';
import useGetOrganization from 'hooks/api/Organizations/useGetOrganization';
import {
  EnabledFeature,
  Organization,
  OrganizationRoles,
  NamespaceType,
  NamespaceActions,
} from 'api-client';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import hasPermissions from 'utils/helpers/permissions/hasPermissions';

interface OrganizationContextType {
  organization: Organization;
  hasEnabledFeature: (feature: EnabledFeature) => boolean;
  actions: {
    useGetOrganization: ReturnType<typeof useGetOrganization>;
  };
  isAdmin: boolean;
  isOwner: boolean;
  canEditOrganization: boolean;
}

/**
 * Initial store
 */
export const store: any = {};

const hasEditOrganizationPermissions = hasPermissions(
  NamespaceActions.EditOrganization
);

/**
 * Create organization context
 */
const OrganizationContext = createContext(store);

interface OrganizationContextProps extends React.PropsWithChildren {
  /**
   * For testing purposes only
   */
  __unsafeMockOrganization?: Organization;
}

export const OrganizationProvider: React.FC<OrganizationContextProps> = (
  props
) => {
  const { children, __unsafeMockOrganization } = props;
  const { workspace } = useWorkspaceContext();
  const [getOrganization, response, error, loading] = useGetOrganization(
    {
      organization: workspace?.id as string,
    },
    {},
    {
      enabled:
        workspace?.type === NamespaceType.Organization &&
        !__unsafeMockOrganization,
    }
  );

  const organization = response?.data || __unsafeMockOrganization;

  function hasEnabledFeature(feature: EnabledFeature): boolean | undefined {
    return organization?.enabled_features?.includes(feature);
  }

  const isAdmin = organization?.role === OrganizationRoles.Admin;
  const isOwner = organization?.role === OrganizationRoles.Owner;

  const providedValue = {
    organization,
    hasEnabledFeature,
    actions: {
      useGetOrganization: [getOrganization, response, error, loading],
    },
    isAdmin,
    isOwner,
    canEditOrganization: hasEditOrganizationPermissions(
      organization?.allowed_actions || []
    ),
  };

  if (loading) {
    return null;
  }

  return (
    <OrganizationContext.Provider value={providedValue}>
      {children}
    </OrganizationContext.Provider>
  );
};

export function useOrganizationContext(): OrganizationContextType {
  return useContext(OrganizationContext);
}

export default OrganizationProvider;
