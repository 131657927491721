import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M26.8543 24.6945H25.0243V21.4845H26.8543C27.1243 21.4845 27.3843 21.5045 27.6343 21.5445C27.8843 21.5845 28.1043 21.6645 28.2943 21.7845C28.4843 21.8945 28.6343 22.0545 28.7443 22.2645C28.8643 22.4745 28.9243 22.7495 28.9243 23.0895C28.9243 23.4295 28.8643 23.7045 28.7443 23.9145C28.6343 24.1245 28.4843 24.2895 28.2943 24.4095C28.1043 24.5195 27.8843 24.5945 27.6343 24.6345C27.3843 24.6745 27.1243 24.6945 26.8543 24.6945Z" />
    <path d="M36.2742 22.5345H36.3192V26.1795H33.5592L36.2742 22.5345Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM12.3759 19.6545H9.06088V30.3645H11.2659V22.8495H11.2959L13.9209 30.3645H15.7359L18.3609 22.7745H18.3909V30.3645H20.5959V19.6545H17.2809L14.9109 27.0195H14.8809L12.3759 19.6545ZM27.4993 19.6545H22.6693V30.3645H25.0243V26.5245H27.4993C28.1693 26.5245 28.7393 26.4295 29.2093 26.2395C29.6793 26.0395 30.0593 25.7795 30.3493 25.4595C30.6493 25.1395 30.8643 24.7745 30.9943 24.3645C31.1343 23.9445 31.2043 23.5195 31.2043 23.0895C31.2043 22.6495 31.1343 22.2245 30.9943 21.8145C30.8643 21.4045 30.6493 21.0395 30.3493 20.7195C30.0593 20.3995 29.6793 20.1445 29.2093 19.9545C28.7393 19.7545 28.1693 19.6545 27.4993 19.6545ZM31.8792 27.9345H36.3192V30.3645H38.3442V27.9345H39.7392V26.1795H38.3442V19.8645H36.4392L31.8792 25.9845V27.9345Z"
    />
  </>,
  'mp4'
);
