import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <g>
    <path d="M31.2727 24.3636C35.2894 24.3636 38.5455 21.1075 38.5455 17.0909C38.5455 13.0743 35.2894 9.81818 31.2727 9.81818C27.2561 9.81818 24 13.0743 24 17.0909C24 21.1075 27.2561 24.3636 31.2727 24.3636Z" />
    <path d="M15.8182 20.7273C19.3327 20.7273 22.1818 17.8782 22.1818 14.3636C22.1818 10.8491 19.3327 8 15.8182 8C12.3036 8 9.45454 10.8491 9.45454 14.3636C9.45454 17.8782 12.3036 20.7273 15.8182 20.7273Z" />
    <path d="M24.9091 28H37.6364C39.3241 28 40.9427 28.6705 42.1362 29.8639C43.3296 31.0573 44 32.6759 44 34.3636V38.9091C44 39.3913 43.8085 39.8538 43.4675 40.1947C43.1265 40.5357 42.664 40.7273 42.1818 40.7273H20.3637C19.8814 40.7273 19.419 40.5357 19.078 40.1947C18.737 39.8538 18.5455 39.3913 18.5455 38.9091V34.3636C18.5455 32.6759 19.2159 31.0573 20.4093 29.8639C21.6028 28.6705 23.2214 28 24.9091 28Z" />
    <path d="M17.9691 26.8618C17.073 27.7101 16.3596 28.7323 15.8725 29.8659C15.3854 30.9996 15.1349 32.2207 15.1364 33.4546V37.0909H5.81818C5.33597 37.0909 4.87351 36.8994 4.53253 36.5584C4.19156 36.2174 4 35.755 4 35.2727V30.7273C4 29.0395 4.67045 27.4209 5.86387 26.2275C7.05728 25.0341 8.6759 24.3636 10.3636 24.3636H16.9745C17.2641 24.3638 17.547 24.4507 17.7866 24.6133C18.0262 24.7759 18.2115 25.0066 18.3186 25.2756C18.4257 25.5446 18.4497 25.8396 18.3874 26.1224C18.3251 26.4052 18.1794 26.6627 17.9691 26.8618Z" />
  </g>,
  'members'
);
