import React from 'react';
import './NotFound.scss';
import { useHistory } from 'react-router-dom';
import Page from 'components/Page';
import { rootPath } from 'utils/links';
import Placeholder from 'components/v2/Placeholder';

function NotFound(): JSX.Element {
  const history = useHistory();

  return (
    <Page
      name="TDB-NotFound"
      className="d-flex column align-center justify-center"
      title="Not found"
      width="100%"
    >
      <Placeholder
        dataTestId="not-found-error"
        graphic="unexpected-error"
        title="Page not found"
        description="The page you are looking for might have been removed, had its name changed or is temporary unavailable."
        actions={[
          {
            variant: 'primary',
            size: 'large',
            children: 'Go to home page',
            onClick: (): void => {
              history.push(rootPath);
            },
          },
        ]}
      />
    </Page>
  );
}

export default NotFound;
