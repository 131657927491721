import React from 'react';
import { useOnboardingContext } from 'store/OnboardingContext';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import BasicTour from './BasicTour';

const OrganizationOnboarding: React.FC = () => {
  const { isOrganizationWorkspace } = useWorkspaceContext();
  const {
    completedOnboardingItems,
    isOrganizationBasicTourComplete,

    isOrganizationOnboardingSkipped,
  } = useOnboardingContext();

  if (!isOrganizationWorkspace) {
    return null;
  }

  if (!completedOnboardingItems) {
    /**
     * Return nothing until completedOnboardingItems are present
     */
    return null;
  }

  if (isOrganizationOnboardingSkipped) {
    /**
     * Return nothing if user has skipped onboarding for this organization
     */
    return null;
  }

  if (!isOrganizationBasicTourComplete) {
    /**
     * All user roles should see the basic tour
     */
    return <BasicTour />;
  }

  return null;
};

export default OrganizationOnboarding;
