import * as Sentry from '@sentry/browser';

let regions: Record<string, string> = {};
try {
  regions = JSON.parse(window.SUPPORTED_REGIONS || '{}');
} catch (error) {
  Sentry.captureException(error);
}

export default regions;
