import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M40.0584 16.4286C40.1156 16.6429 40.1441 16.8571 40.187 17.0714L40.2298 17.3V17.3286C40.3727 18.2857 40.4441 19.2286 40.4441 20.2143C40.4441 21.7 40.2727 23.1429 39.9441 24.5286C38.3156 31.5857 32.7441 37.1571 25.687 38.7857C24.3013 39.1143 22.8584 39.2857 21.3727 39.2857C20.4584 39.2857 19.5156 39.2143 18.5013 39.0571L17.987 39.1714C21.3727 41.3143 25.5013 42.1571 29.587 41.4143L41.1013 43.9714C41.2156 43.9857 41.3156 44 41.4156 44C41.8441 44 42.2441 43.8143 42.5156 43.4714C42.8584 43.0571 42.9441 42.4857 42.7156 42L39.687 35.1C41.7298 32.3429 42.8441 28.9571 42.8441 25.4714C42.8441 22.2143 41.8441 19.0571 40.0584 16.4286Z" />
    <path d="M18.687 36.2C19.587 36.3429 20.4727 36.4286 21.3727 36.4286C30.3156 36.4286 37.587 29.1571 37.587 20.2143C37.587 19.3 37.5156 18.4286 37.3727 17.5571V17.5429C37.0156 15.4286 36.2441 13.4429 35.1584 11.6857C32.3013 7.07143 27.187 4 21.3727 4C12.4298 4 5.15841 11.2714 5.15841 20.2143C5.15841 23.7 6.2727 27.0857 8.31555 29.8429L5.2727 36.7286C5.05841 37.2286 5.14412 37.8 5.48698 38.2143C5.75841 38.5571 6.15841 38.7429 6.58698 38.7429C6.68698 38.7429 6.78698 38.7286 6.90127 38.7L15.1298 36.8857L18.4156 36.1571C18.5013 36.1714 18.6013 36.1857 18.687 36.2ZM28.287 18.7571C29.0727 18.7571 29.7156 19.4 29.7156 20.1857C29.7156 20.9714 29.0727 21.6143 28.287 21.6143C27.487 21.6143 26.8584 20.9714 26.8584 20.1857C26.8584 19.4 27.487 18.7571 28.287 18.7571ZM21.287 18.7571C22.0727 18.7571 22.7156 19.4 22.7156 20.1857C22.7156 20.9714 22.0727 21.6143 21.287 21.6143C20.487 21.6143 19.8584 20.9714 19.8584 20.1857C19.8584 19.4 20.487 18.7571 21.287 18.7571ZM14.287 21.6143C13.487 21.6143 12.8584 20.9714 12.8584 20.1857C12.8584 19.4 13.487 18.7571 14.287 18.7571C15.0727 18.7571 15.7156 19.4 15.7156 20.1857C15.7156 20.9714 15.0727 21.6143 14.287 21.6143Z" />
  </>,
  'chat'
);
