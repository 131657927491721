import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import Loader from 'components/Loader';
import { oauth2RouterPaths } from 'utils/routerPaths';
import lazyLoadComponent from 'utils/helpers/lazyLoadComponent';

const OAuth2Login = lazyLoadComponent(() => import('./OAuth2Login'));
const OAuth2Consent = lazyLoadComponent(() => import('./OAuth2Consent'));

function OAuth2(): React.ReactElement {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={oauth2RouterPaths.login} component={OAuth2Login} />
        <Route path={oauth2RouterPaths.consent} component={OAuth2Consent} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default React.memo(OAuth2);
