interface ProviderProps {
  provider: 'github' | 'google' | 'stripe' | 'okta' | 'perdomain';
}

const authLinks = {
  root: () => '/auth',
  login: () => '/auth/login',
  signup: () => '/auth/signup',
  forgotPassword: () => '/auth/forgot-password',
  resetPassword: () => '/auth/reset_password',
  confirmEmail: () => '/auth/confirm_email',
  emailNotConfirmed: () => '/auth/email-not-confirmed',
  ssoProvider: ({ provider }: ProviderProps) =>
    `/auth/sso/callback/${provider}`,
  companySSO: () => `/auth/sso/company`,
  logout: () => '/logout',
};

export default authLinks;
