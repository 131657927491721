import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { appsLinks } from 'utils/links';

const useIsUserInJupyterPage = () => {
  const { pathname, search } = useLocation();
  const queryParams = queryString.parse(search);
  const userIsInJupyterNotebookOrDashaboard = pathname.startsWith(
    appsLinks.server()
  );

  const dashboardParamIsPresent = 'dashboard' in queryParams;
  const userIsInJupyterNotebook =
    userIsInJupyterNotebookOrDashaboard && !dashboardParamIsPresent;

  return {
    userIsInDashboard: dashboardParamIsPresent,
    userIsInJupyterNotebook: Boolean(userIsInJupyterNotebook),
    userIsInJupyterNotebookOrDashaboard: Boolean(
      userIsInJupyterNotebookOrDashaboard
    ),
  };
};

export default useIsUserInJupyterPage;
