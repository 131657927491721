const rasterRouterPaths = {
  root: '/raster',
  details: '/raster/:namespace/:groupId',
  overview: '/raster/:namespace/:groupId/overview',
  sharing: '/raster/:namespace/:groupId/sharing',
  settings: '/raster/:namespace/:groupId/settings',
  contents: '/raster/:namespace/:groupId/contents',
  preview: '/raster/:namespace/:groupId/preview',
  metadata: '/raster/:namespace/:groupId/metadata',
  activity: '/raster/:namespace/:groupId/activity',
};

export default rasterRouterPaths;
