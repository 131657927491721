import * as React from 'react';
import HistoryItem from 'store/HistoryStack/HistoryItem';
import BreadcrumbItemTitle from '../BreadcrumbItemTitle';
import IconCarretRight from 'components/Svg/icons/IconCarretRight';
import styles from './BreadcrumbItem.module.scss';

type Props = {
  active: boolean;
  item: HistoryItem;
  goBack: () => void;
  hidden?: boolean;
  onWidthUpdate?: (values: { width: number; key: string }) => void;
};

const BreadcrumbItem: React.FC<Props> = (props) => {
  const { active, item, goBack, onWidthUpdate, hidden } = props;

  return (
    <BreadcrumbItemTitle
      item={item}
      hidden={hidden}
      onWidthUpdate={onWidthUpdate}
      onClick={!active ? goBack : undefined}
      active={active}
    >
      {!active && (
        <IconCarretRight
          colorToken="color-text-disabled"
          className={styles.separator}
        />
      )}
    </BreadcrumbItemTitle>
  );
};

export default BreadcrumbItem;
