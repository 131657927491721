import React from 'react';
import './Page.scss';
import Head from 'components/Head';
import ErrorBoundary from 'components/ErrorBoundary';
import classNames from 'classnames';

interface PageProps {
  children: React.ReactNode;
  name: string;
  className?: string;
  title?: string;
  width?: string | number;
  noPadding?: boolean;
  dataTestId?: string;
  dataCy?: string;
}

export const Page: React.FC<PageProps> = (props) => {
  const {
    children,
    name,
    className,
    title = false,
    noPadding,
    width,
    dataTestId,
    dataCy,
  } = props;

  return (
    <ErrorBoundary>
      <main
        className={classNames('TDB-Page', name, className, {
          'TDB-Page--no-padding': noPadding,
        })}
        data-testid={dataTestId || name}
        data-cy={dataCy || name}
      >
        <div className="TDB-Page__content" style={{ maxWidth: width }}>
          {title && (
            <Head>
              <title>{title}</title>
            </Head>
          )}
          {children}
        </div>
      </main>
    </ErrorBoundary>
  );
};

Page.defaultProps = {
  width: 1280,
};

export default React.memo(Page);
