import { useUserContext } from 'store/UserContext';

/**
 * Hook that returns a value depending on auth state
 * it accepts 2 values, one for authenticated users and one for public
 */
const useAuthValue = <T, P = T>(authValue: T, publicValue: T | P): T | P => {
  const { isLoggedIn } = useUserContext();

  return isLoggedIn ? authValue : publicValue;
};

export default useAuthValue;
