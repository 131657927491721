import {
  UserApi_getOnboardingState,
  UserApi_getOnboardingState$Params,
  UserApi_getOnboardingState$Path,
  UserApi_getOnboardingState$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import replacePathParams from 'utils/helpers/replacePathParams';

export const createPath = (data: UserApi_getOnboardingState$Params): string => {
  return replacePathParams(UserApi_getOnboardingState$Path, data);
};

const useGetOnboardingState: UseRequestType<
  UserApi_getOnboardingState$Params,
  UserApi_getOnboardingState$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_getOnboardingState$Response>,
    UserApi_getOnboardingState$Params
  >(
    [createPath(queryData), queryData],
    useQueryRequest(UserApi_getOnboardingState, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetOnboardingState;
