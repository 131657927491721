import { AssetType } from 'api-client';
import classNames from 'classnames';
import IconArray from 'components/Svg/icons/IconArray';
import React from 'react';
import styles from './AssetTypeIcon.module.scss';
import IconBiomedicalImaging from 'components/Svg/icons/IconBiomedicalImaging';
import IconDashboard from 'components/Svg/icons/IconDashboard';
import IconFile from 'components/Svg/icons/IconFile';
import IconGeometry from 'components/Svg/icons/IconGeometry';
import IconGroup from 'components/Svg/icons/IconGroup';
import IconMLModel from 'components/Svg/icons/IconMLModel';
import IconNotebook from 'components/Svg/icons/IconNotebook';
import IconPointCloud from 'components/Svg/icons/IconPointCloud';
import IconRaster from 'components/Svg/icons/IconRaster';
import IconTaskGraph from 'components/Svg/icons/IconTaskGraph';
import IconSOMA from 'components/Svg/icons/IconSOMA';
import IconUDF from 'components/Svg/icons/IconUDF';
import IconVCF from 'components/Svg/icons/IconVCF';
import IconVectorSearch from 'components/Svg/icons/IconVectorSearch';
import MimeType from 'utils/constants/MimeType';
import IconCSV from 'components/Svg/icons/IconCSV';
import IconDOCX from 'components/Svg/icons/IconDOCX';
import IconPDF from 'components/Svg/icons/IconPDF';
import IconGIF from 'components/Svg/icons/IconGIF';
import IconZIP from 'components/Svg/icons/IconZIP';
import IconHDF from 'components/Svg/icons/IconHDF';
import IconHTML from 'components/Svg/icons/IconHTML';
import IconJPEG from 'components/Svg/icons/IconJPEG';
import IconJSON from 'components/Svg/icons/IconJSON';
import IconMP4 from 'components/Svg/icons/IconMP4';
import IconPNG from 'components/Svg/icons/IconPNG';
import IconTAR from 'components/Svg/icons/IconTAR';
import IconTIFF from 'components/Svg/icons/IconTIFF';
import IconTXT from 'components/Svg/icons/IconTXT';
import IconVCard from 'components/Svg/icons/IconVCard';
import IconWAV from 'components/Svg/icons/IconWAV';
import IconXML from 'components/Svg/icons/IconXML';

interface AssetTypeIconProps {
  assetType: AssetType;
  mimeType?: string;
  active?: boolean;
  fill?: string;
  className?: string;
  size?: string | number;
}

const assetTypeIcon = {
  [AssetType.Array]: IconArray,
  [AssetType.Bioimg]: IconBiomedicalImaging,
  [AssetType.Dashboard]: IconDashboard,
  [AssetType.File]: IconFile,
  [AssetType.Geometry]: IconGeometry,
  [AssetType.Group]: IconGroup,
  [AssetType.MlModel]: IconMLModel,
  [AssetType.Notebook]: IconNotebook,
  [AssetType.Pointcloud]: IconPointCloud,
  [AssetType.Raster]: IconRaster,
  [AssetType.RegisteredTaskGraph]: IconTaskGraph,
  [AssetType.Soma]: IconSOMA,
  [AssetType.UserDefinedFunction]: IconUDF,
  [AssetType.Vcf]: IconVCF,
  [AssetType.VectorSearch]: IconVectorSearch,
};

const mimeTypeIcon = {
  [MimeType.Array]: IconArray,
  [MimeType.Bioimg]: IconBiomedicalImaging,
  [MimeType.CSV]: IconCSV,
  [MimeType.DOCX]: IconDOCX,
  [MimeType.GIF]: IconGIF,
  [MimeType.GZIP]: IconZIP,
  [MimeType.ZIP]: IconZIP,
  [MimeType.Geometry]: IconGeometry,
  [MimeType.Group]: IconGroup,
  [MimeType.HDF]: IconHDF,
  [MimeType.HTML]: IconHTML,
  [MimeType.JPEG]: IconJPEG,
  [MimeType.JSON]: IconJSON,
  [MimeType.MLModel]: IconMLModel,
  [MimeType.MP4]: IconMP4,
  [MimeType.Notebook]: IconNotebook,
  [MimeType.OctetStream]: IconFile,
  [MimeType.PDF]: IconPDF,
  [MimeType.PNG]: IconPNG,
  [MimeType.Pointcloud]: IconPointCloud,
  [MimeType.Raster]: IconRaster,
  [MimeType.RegisterdTaskGraph]: IconTaskGraph,
  [MimeType.SOMA]: IconSOMA,
  [MimeType.TAR]: IconTAR,
  [MimeType.TIFF]: IconTIFF,
  [MimeType.TXT]: IconTXT,
  [MimeType.UDF]: IconUDF,
  [MimeType.VCARD]: IconVCard,
  [MimeType.Vcf]: IconVCF,
  [MimeType.VectorSearch]: IconVectorSearch,
  [MimeType.WAV]: IconWAV,
  [MimeType.XML]: IconXML,
};

/**
 * Replaces / and . with - from the mime-type string to be used as CSS classes
 */
function sluggifyMimeType(mimeType: string): string {
  return mimeType?.replace(/(\/|\.)/g, '-') || '';
}

/**
 * Renders the asset icon by reading the mime_type or the asset_type
 * mime_type has priority. If the mime-type is falsy or not supported, it will use the asset_type indicator
 */
const AssetTypeIcon: React.FC<AssetTypeIconProps> = (props) => {
  const { assetType, active, fill, className, size, mimeType } = props;
  // Get the icon by AssetType
  // This will be used as the fallback icon if there is no mime-type
  let IconComponent = assetTypeIcon[assetType];

  // If a mime-type is passed, try to find the icon by mime-type
  if (mimeType) {
    const MimeTypeIconComponent = mimeTypeIcon[mimeType as never];
    // If an icon found for this mime-type, use this icon instead
    if (MimeTypeIconComponent) {
      IconComponent = MimeTypeIconComponent;
    }
  }

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      size={size}
      className={classNames(className, styles[`icon--${assetType}`], {
        [styles.active]: active,
        [styles[`icon--mime-type--${sluggifyMimeType(mimeType as string)}`]]:
          !!mimeType,
      })}
      fill={fill}
    />
  );
};

export default AssetTypeIcon;
