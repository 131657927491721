import * as React from 'react';
import { SvgIconProps } from 'components/Svg';
import './Spinner.scss';
import classNames from 'classnames';
import IconLoadCircle from 'components/Svg/icons/IconLoadCircle';

interface Props {
  Icon?: React.ComponentType<SvgIconProps>;
  iconProps?: SvgIconProps;
  speed?: 'fast' | 'slow';
  className?: string;
}

const Spinner: React.FC<Props> = (props) => {
  const { Icon = IconLoadCircle, speed, className, iconProps } = props;

  return (
    <div
      className={classNames('TDB-Spinner', className, {
        'TDB-Spinner--slow': speed === 'slow',
        'TDB-Spinner--fast': speed === 'fast',
      })}
    >
      <Icon colorToken="text-action-brand" {...iconProps} />
    </div>
  );
};

export default Spinner;
