import supportedRegions from 'utils/constants/supportedRegions';

const getJupyterURL = (region?: string) => {
  if (!region) {
    return global.JUPYTERHUB_URL;
  }

  if (!supportedRegions[region]) {
    return global.JUPYTERHUB_URL;
  }

  return supportedRegions[region];
};

export default getJupyterURL;
