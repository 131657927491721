import React, { useMemo } from 'react';
import useJupyter, { JupyterStatus } from 'store/JupyterStore';
import MainMenuButton from './MainMenuButton';
import { overviewLinks, settingsLinks } from 'utils/links';
import IconHome from 'components/Svg/icons/IconHome';
import IconDatabase from 'components/Svg/icons/IconDatabase';
import { useLocation } from 'react-router-dom';
import IconSettings from 'components/Svg/icons/IconSettings';
import IconBook from 'components/Svg/icons/IconBook';
import IconQuestionmarkFilled from 'components/Svg/icons/IconQuestionmarkFilled';
import IconStore from 'components/Svg/icons/IconStore';
import assetsLinks from 'utils/links/assetsLinks';
import NamespaceSelector from './NamespaceSelector';
import monitorLinks from 'utils/links/monitorLinks';
import academyLinks from 'utils/links/academyLinks';
import useEvent from 'hooks/useEvent';
import eventBus, { EventTypes } from 'utils/helpers/eventBus';
import classNames from 'classnames';
import marketplaceLinks from 'utils/links/marketplaceLinks';
import HelpDialog from './HelpDialog';
import Dialog from 'components/Dialog';
import AuthRender from 'components/AuthRender';
import IconSystemStatus from 'components/Svg/icons/IconSystemStatus';
import IconLaunch from 'components/Svg/icons/IconLaunch';
import appsLinks from 'utils/links/appsLinks/appsLinks';
import './MainMenu.scss';

const MainMenu: React.FC = () => {
  const { pathname } = useLocation();
  const state: boolean = useEvent(EventTypes.TOGGLE_MAIN_SIDEBAR);
  const status = useJupyter((state) => state.status);

  const monitorIndicatorVariant = useMemo(() => {
    if (status === JupyterStatus.Running) {
      return 'info';
    } else if (status === JupyterStatus.ShuttingDown) {
      return 'warning';
    }
  }, [status]);

  return (
    <>
      <div
        className={classNames('TDB-MainMenu', {
          'TDB-MainMenu--mobile-hidden': !state,
        })}
      >
        <div className="TDB-MainMenu__section">
          <AuthRender>
            <NamespaceSelector />
          </AuthRender>
          <MainMenuButton
            active={pathname === overviewLinks.root()}
            text="Home"
            to={overviewLinks.root()}
            icon={IconHome}
            dataTestId="menu-home-link"
            basicTourStep="6"
          />
          <MainMenuButton
            active={pathname.startsWith(assetsLinks.root())}
            text="Assets"
            to={assetsLinks.root()}
            icon={IconDatabase}
            dataTestId="menu-assets-link"
            basicTourStep="0"
            organizationBasicTourStep="0"
          />
          <MainMenuButton
            text="Apps"
            to={appsLinks.root()}
            active={pathname.startsWith(appsLinks.root())}
            icon={IconLaunch}
            basicTourStep="1"
            dataTestId="menu-apps-link"
          />
          <MainMenuButton
            active={pathname.startsWith(monitorLinks.root())}
            text="Monitor"
            to={monitorLinks.root()}
            indicatorVariant={monitorIndicatorVariant}
            icon={IconSystemStatus}
            dataTestId="menu-monitor-link"
            basicTourStep="2"
          />
          <MainMenuButton
            to={academyLinks.root()}
            active={pathname.startsWith(academyLinks.root())}
            text="Academy"
            icon={IconBook}
            basicTourStep="3"
            dataTestId="menu-academy-link"
          />
          <MainMenuButton
            to={marketplaceLinks.root()}
            active={pathname.startsWith(marketplaceLinks.root())}
            text="Marketplace"
            icon={IconStore}
            dataTestId="menu-marketplace-link"
            basicTourStep="4"
          />
        </div>
        <div className="TDB-MainMenu__section">
          <MainMenuButton
            active={pathname.startsWith(settingsLinks.root())}
            text="Settings"
            to={settingsLinks.root()}
            icon={IconSettings}
            dataTestId="menu-settings-link"
            organizationBasicTourStep="1"
          />
          <Dialog
            trigger="click"
            content={<HelpDialog />}
            interactive
            preventOverflow
          >
            <MainMenuButton
              active={false}
              text="Help"
              icon={IconQuestionmarkFilled}
              dataTestId="menu-help-link"
            />
          </Dialog>
        </div>
      </div>
      {/*
       * The following div is holding the click listener to hide left side menus (mobile only)
       * It acts as "on-click-outside"
       * The burger button opens the main menu and any nested SideNavs,
       * those menus can be dismissed by clicking on the div below
       */}
      <div
        onClick={() => eventBus.emit(EventTypes.TOGGLE_MAIN_SIDEBAR, false)}
        className={classNames('TDB-MainMenu__on-click-outside', {
          'TDB-MainMenu__on-click-outside--active': state,
        })}
      />
    </>
  );
};

export default MainMenu;
