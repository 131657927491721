import React, { ReactElement, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import ErrorBoundary from 'components/ErrorBoundary';
import { callbackRouterPaths } from 'utils/routerPaths';
import AuthGuard from 'components/AuthGuard';
import lazyLoadComponent from 'utils/helpers/lazyLoadComponent';

const StripeCallback = lazyLoadComponent(() => import('./StripeCallback'));

const Callbacks: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthGuard>
        <Suspense fallback={null}>
          <Switch>
            <Route
              path={callbackRouterPaths.stripe}
              component={StripeCallback}
            />
            <Route render={(): ReactElement => <NotFound />} />
          </Switch>
        </Suspense>
      </AuthGuard>
    </ErrorBoundary>
  );
};

export default Callbacks;
