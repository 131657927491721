import React, { ReactNode, CSSProperties, Ref, useMemo } from 'react';
import Loader from 'components/Loader';
import { Icon as IconType } from 'components/Svg';
import classNames from 'classnames';
import styles from './Button.module.scss';
import Typography, {
  SizeVariants,
  FontWeight,
  TypographyVariants,
} from 'components/Typography';

export const BUTTON_SIZES = ['small', 'medium', 'large'] as const;
export const BUTTON_TYPES = ['button', 'submit', 'reset'] as const;
export const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'outlined',
  'plain',
] as const;
export const BUTTON_ALIGNMENTS = ['left', 'center', 'right'] as const;

export type ButtonSize = (typeof BUTTON_SIZES)[number];
type ButtonType = (typeof BUTTON_TYPES)[number];
export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];
export type ButtonAlignment = (typeof BUTTON_ALIGNMENTS)[number];

export interface ButtonProps {
  type?: ButtonType;
  onClick?(e: React.SyntheticEvent): any;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
  size: ButtonSize;
  icon?: IconType;
  iconRight?: IconType;
  loading?: boolean;
  variant: ButtonVariant;
  style?: CSSProperties;
  title?: string;
  dataCy?: string;
  ariaLabel?: string;
  fullWidth?: boolean;
  critical?: boolean;
  ariaHidden?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
  role?: string;
  dataTestID?: string;
  selected?: boolean; // applies styling to an active-like state on demand
  align?: ButtonAlignment;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  className,
  type = 'button',
  tabIndex,
  variant,
  onClick,
  style,
  loading,
  icon: Icon,
  size,
  title,
  dataCy,
  ariaLabel,
  fullWidth,
  iconRight: IconRight,
  critical,
  ariaHidden,
  buttonRef,
  role,
  selected,
  dataTestID = 'TDB-Btn',
  align = 'center',
  ...rest
}) => {
  const handleClick = (e: React.SyntheticEvent) => {
    onClick?.(e);
  };

  const textSize: SizeVariants = useMemo(() => {
    if (size === 'small') {
      return 'body-sm';
    }
    return 'body-md';
  }, [size]);

  const textWeight: FontWeight = useMemo(() => {
    if (size === 'large') {
      return '600';
    }
    return '500';
  }, [size]);

  const iconSize: number = useMemo(() => {
    if (size === 'small') {
      return 14;
    }
    return 16;
  }, [size]);

  const textVariant: TypographyVariants = useMemo(() => {
    if (variant === 'primary') {
      return 'on-fill-brand';
    } else if (variant === 'outlined' || variant === 'secondary') {
      return 'default';
    } else if (variant === 'plain') {
      return 'link';
    }
    return 'on-fill-brand';
  }, [variant]);

  const hasOnlyIcon = useMemo(
    () => !children && !IconRight && !!Icon,
    [Icon, IconRight, children]
  );

  const iconFill: string = useMemo(() => {
    if (variant === 'primary') {
      return '--color-text-on-fill-brand-default';
    } else if (variant === 'secondary' || variant === 'outlined') {
      if (hasOnlyIcon) {
        return '--color-text-default';
      }
      return '--color-text-secondary-default';
    } else if (variant === 'plain') {
      return '--color-text-link-default';
    }
    return '--color-text-link-default';
  }, [variant, hasOnlyIcon]);

  return (
    <button
      ref={buttonRef}
      role={role}
      tabIndex={tabIndex}
      className={classNames(
        styles.button,
        styles[size],
        styles[variant],
        styles[`align-${align}`],
        className,
        {
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
          [styles.critical]: critical,
          [styles.selected]: selected,
          [styles.loading]: loading,
          [styles.onlyIcon]: hasOnlyIcon,
        }
      )}
      type={type}
      disabled={disabled || loading}
      onClick={handleClick}
      data-testid={dataTestID}
      style={style}
      title={title}
      data-cy={dataCy}
      aria-label={ariaLabel as string}
      aria-hidden={ariaHidden}
      {...rest}
    >
      {loading && <Loader type="dots-only" size={80} />}

      <div className={styles.content} tabIndex={-1}>
        {Icon && <Icon fill={`var(${iconFill})`} size={iconSize} />}
        {children && (
          <Typography
            className={styles.text}
            variant={textVariant}
            fontWeight={textWeight}
            fontSize={textSize}
          >
            {children}
          </Typography>
        )}
        {IconRight && <IconRight fill={`var(${iconFill})`} size={iconSize} />}
      </div>
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  size: 'large',
  variant: 'primary',
};

export default Button;
