import { matchPath } from 'react-router';
import {
  assetsRouterPaths,
  monitorRouterPaths,
  marketplaceRouterPaths,
  overviewRouterPaths,
  settingsRouterPaths,
  appsRouterPaths,
} from 'utils/routerPaths';
import academyRouterPaths from 'utils/routerPaths/academyRouterPaths';
import stripSearchParamsFromPathName from '../stripSearchParamsFromPathName';

const rootPathnames = [
  assetsRouterPaths.root,
  overviewRouterPaths.root,
  monitorRouterPaths.root,
  academyRouterPaths.root,
  settingsRouterPaths.root,
  marketplaceRouterPaths.root,
  appsRouterPaths.root,
];

/**
 * Helper function that returns true, if the pathname is root.
 * Root pages we consider that are a starting point of a navigation.
 * e.g. Assets routes (a user goes to assets and then starts navigating to some asset's details).
 */
const isPathnameRoot = (pathname: string): boolean => {
  const { pathname: pathWithoutSearchParams } =
    stripSearchParamsFromPathName(pathname);

  const matches = matchPath(pathWithoutSearchParams, {
    path: rootPathnames,
  });

  return !!matches;
};

export default isPathnameRoot;
