import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import {
  ArrayApi_getArrayInfo,
  ModelError,
  NotebookSize,
  NotebookStatus,
  NotebookType,
  PodStatus,
} from 'api-client';
import { dissoc } from 'ramda';
import getJupyterURL from 'utils/helpers/getJupyterURL';
import { AxiosError } from 'axios';
import isAxiosError from 'utils/helpers/isAxiosError';
import { appsLinks } from 'utils/links';

export enum JupyterStatus {
  Idle = 'idle',
  Unknown = 'unknown',
  ShuttingDown = 'shutting_down',
  ErrorEncountered = 'error_encountered',
  Launching = 'launching',
  Running = 'Running',
  NotRunning = 'not_running',
  ServiceUnavailable = 'service_unavailable',
}

interface JupyterSession {
  image: NotebookType;
  server: NotebookSize;
  region?: string;
}

export enum TaskType {
  LaunchNotebook,
}

export interface QueuedTask {
  action: TaskType;
  payload?: Record<string, string>;
  callback?: () => void;
}

export interface JupyterNotebook {
  arrayName: string;
  arrayId: string;
  namespace: string;
}

interface JupyterStore {
  image?: NotebookType;
  server?: NotebookSize;
  status: JupyterStatus;
  progress: number;
  lastSession?: JupyterSession;
  jupyterURL: string;
  dashboardsLoaded: Record<string, boolean>;
  queuedTasks: QueuedTask[];
  pushQueuedTask: (task: QueuedTask) => void;
  clearQueuedTasks: () => void;
  setDashboardAsLoaded: (dashboardName: string) => void;
  launchNotebookServer: (image: NotebookType, server: NotebookSize) => void;
  getNotebookURL: () => string;
  getDashboardURL: (id: string) => string;
  shutDownJupyterNotebook: (region?: string) => void;
  setLaunchingProgress: (progress: number) => void;
  setIdle: () => void;
  notebook?: JupyterNotebook;
  setNotebook: (n?: JupyterNotebook) => void;
  dashboards: Record<string, JupyterNotebook>;
  addDashboard: (n: JupyterNotebook) => void;
  removeDashboard: (id: string) => void;
  setStatus: (status: JupyterStatus) => void;
  activeServerImage?: string;
  setActiveServerImage: (image: string) => void;
  resetState: () => void;
  selectedRegion?: string;
  setSelectedRegion: (region?: string) => void;
  notebookStatuses: NotebookStatus[];
  setNotebookStatuses: (statuses: NotebookStatus[]) => void;
  notebookStatusError?: AxiosError<ModelError>;
  setNotebookStatusError: (err?: AxiosError<ModelError>) => void;
  notebookStatusLoading: boolean;
  setNotebookStatusLoading: (loading: boolean) => void;
  popNotebook: () => JupyterNotebook | undefined;
  launchServerForJupyterNotebook: (jupyterNotebook: JupyterNotebook) => void;
}

export enum ChannelActions {
  RESET_STATE,
}

// Channel to broadcast and sync jupyter status
export const tabChannel = new BroadcastChannel('JUPYTER_CHANNEL');

const useStore = createWithEqualityFn<JupyterStore>(
  (set, get) => ({
    image: undefined,
    server: undefined,
    notebook: undefined,
    lastSession: undefined,
    queuedTasks: [],
    jupyterURL: global.JUPYTERHUB_URL,
    notebookStatuses: [],
    notebookStatusLoading: false,
    activeServerImage: undefined,
    selectedRegion: undefined,
    notebookStatusError: undefined,
    dashboards: {},
    progress: 0,
    status: JupyterStatus.Unknown,
    dashboardsLoaded: {},
    setDashboardAsLoaded: (dashboardName: string) => {
      const dashboardsLoaded = get().dashboardsLoaded;
      set((state) => ({
        dashboardsLoaded: {
          ...dashboardsLoaded,
          [dashboardName]: true,
        },
      }));
    },
    popNotebook: () => {
      const notebook = get().notebook;
      set((state) => ({
        notebook: undefined,
      }));

      return notebook;
    },
    pushQueuedTask: (task) => {
      const queuedTasks = get().queuedTasks;
      set((state) => ({
        queuedTasks: [...queuedTasks, task],
      }));
    },
    clearQueuedTasks: () => {
      set((state) => ({
        queuedTasks: [],
      }));
    },
    getCurrentNotebookStatus: () => {
      const statuses = get().notebookStatuses;
      const selectedRegion = get().selectedRegion;

      if (!statuses.length) {
        return undefined;
      }
      if (statuses.length === 1) {
        return statuses[0];
      } else if (statuses.length && selectedRegion) {
        return statuses.find((v) => v.region === selectedRegion);
      }
    },
    setNotebookStatuses: (statuses) => {
      set((state) => ({
        notebookStatuses: statuses,
      }));
    },
    setNotebookStatusError: (err) => {
      set((state) => ({
        notebookStatusError: err,
      }));
    },
    setNotebookStatusLoading: (loading) => {
      set((state) => ({
        notebookStatusLoading: loading,
      }));
    },
    setActiveServerImage: (image) => {
      if (image !== get().activeServerImage) {
        set((state) => ({
          activeServerImage: image,
        }));
      }
    },
    addDashboard: (n: JupyterNotebook) => {
      const id = n?.arrayId;
      set((state) => ({
        dashboards: {
          ...state.dashboards,
          [id]: n,
        },
      }));
    },
    removeDashboard: (id: string) => {
      const dashboards = get().dashboards;
      const dashboardName = dashboards[id]?.arrayName;
      const dashboardsWithoutId = dissoc(id, dashboards);
      set((state) => ({
        dashboards: dashboardsWithoutId,
      }));

      if (dashboardName) {
        // Since we remove it, we reset state that dashboard as loaded.
        const dashboardsLoaded = get().dashboardsLoaded;
        set((state) => ({
          dashboardsLoaded: {
            ...dashboardsLoaded,
            [dashboardName]: false,
          },
        }));
      }
    },
    resetState: () => {
      set((state) => ({
        dashboards: {},
        notebookStatusLoading: false,
        notebookStatusError: undefined,
        notebookStatuses: [],
        notebook: undefined,
        image: undefined,
        server: undefined,
        activeServerImage: undefined,
        activeRegions: [],
        selectedRegion: undefined,
        progress: 0,
      }));
    },
    setNotebook: (n?: JupyterNotebook) => {
      set((state) => ({
        notebook: n,
      }));
    },
    setLaunchingProgress: (progress: number) => {
      set((state) => ({
        progress,
        status: JupyterStatus.Launching,
      }));
    },
    setStatus: (status) => {
      set((state) => ({
        status,
      }));
    },
    setIdle: () => {
      set((state) => ({
        status: JupyterStatus.Idle,
        progress: 0,
      }));
    },
    shutDownJupyterNotebook: (region?: string) => {
      const selectedRegion = get().selectedRegion;
      const activeRegions = activeRegionsSelector(get());

      tabChannel.postMessage({
        action: ChannelActions.RESET_STATE,
        payload: {
          region: region || selectedRegion,
          activeRegions,
        },
      });
      set((state) => ({
        status: JupyterStatus.ShuttingDown,
        server: undefined,
        image: undefined,
        notebook: undefined,
        selectedRegion: undefined,
        dashboards: {},
        progress: 0,
      }));
    },
    setSelectedRegion: (region?: string) => {
      set((state) => ({
        jupyterURL: getJupyterURL(region),
        selectedRegion: region,
      }));
    },
    getDashboardURL: (id: string) => {
      const dashboards = get().dashboards;
      const dashboard = dashboards[id];
      if (!dashboard) {
        return appsLinks.server();
      }

      return appsLinks.server({
        ...dashboard,
        dashboard: true,
      });
    },
    getNotebookURL: () => {
      const notebook = get().notebook;
      if (!notebook) {
        return appsLinks.server();
      }

      return appsLinks.server(notebook);
    },
    launchServerForJupyterNotebook: async (jupyterNotebook) => {
      const canLaunch = get().status === JupyterStatus.Idle;
      if (!canLaunch) {
        return;
      }
      const response = await ArrayApi_getArrayInfo({
        namespace: jupyterNotebook.namespace,
        array: jupyterNotebook.arrayId,
      });

      const fileProperties = response.data.file_properties;
      if (fileProperties?.image && fileProperties?.size) {
        get().launchNotebookServer(
          fileProperties?.image as NotebookType,
          fileProperties?.size as NotebookSize
        );
      }
    },
    launchNotebookServer: (image: NotebookType, server: NotebookSize) => {
      const selectedRegion = get().selectedRegion;

      set((state: JupyterStore) => ({
        image,
        server,
        lastSession: {
          image,
          server,
          region: selectedRegion,
        },
      }));
    },
  }),
  shallow
);

export default useStore;

export const resetJupyterStoreFromNonReactHook = () => {
  useStore.setState({ status: JupyterStatus.Unknown });
};

export const activeRegionsSelector = (state: JupyterStore) => {
  const { notebookStatuses } = state;

  const regions = notebookStatuses
    .filter((s) => s.pod_status === PodStatus.RUNNING)
    .map((v) => v.region)
    .filter((region) => !!region) as string[];
  return regions;
};

export const notebookStatusSelector = (state: JupyterStore) => {
  const { notebookStatuses, selectedRegion } = state;

  if (!notebookStatuses.length) {
    return undefined;
  }
  if (notebookStatuses.length === 1) {
    return notebookStatuses[0];
  } else if (notebookStatuses.length && selectedRegion) {
    return notebookStatuses.find((v) => v.region === selectedRegion);
  }
};

export const userHasNoCreditsSelector = (state: JupyterStore) => {
  const { notebookStatusError: error, notebookStatusLoading } = state;

  if (notebookStatusLoading || !error) {
    return false;
  }

  return error && isAxiosError(error) && error.response?.status === 402;
};

tabChannel.onmessage = (
  ev: MessageEvent<{
    action: ChannelActions;
    payload: { region?: string; activeRegions: string[] };
  }>
): void => {
  if (ev.data.action === ChannelActions.RESET_STATE) {
    const { payload } = ev.data;
    const { activeRegions, region: regionShuttingDown } = payload;

    if (regionShuttingDown !== useStore.getState().selectedRegion) {
      return;
    }

    if (activeRegions.length === 1) {
      useStore.getState().resetState();
      useStore.getState().setStatus(JupyterStatus.ShuttingDown);
    } else if (activeRegions.length === 2) {
      // Since we shut down active region we need to switch to the other one
      const newRegion = activeRegions.find(
        (region) => region !== regionShuttingDown
      );

      useStore.getState().setSelectedRegion(newRegion);
    } else {
      useStore.getState().setSelectedRegion(undefined);
    }
  }
};

(window as any).jupyter = useStore.getState;
