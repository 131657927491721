import { AssetType } from 'api-client';

const getAssetTitleForBrowsers = (asset?: AssetType) => {
  if (asset === AssetType.Array) {
    return 'Arrays';
  } else if (asset === AssetType.File) {
    return 'Files';
  } else if (asset === AssetType.Bioimg) {
    return 'Biomedical Imaging';
  } else if (asset === AssetType.Dashboard) {
    return 'Dashboards';
  } else if (asset === AssetType.Geometry) {
    return 'Geometries';
  } else if (asset === AssetType.Group) {
    return 'Groups';
  } else if (asset === AssetType.MlModel) {
    return 'ML models';
  } else if (asset === AssetType.Notebook) {
    return 'Notebooks';
  } else if (asset === AssetType.Pointcloud) {
    return 'Point cloud';
  } else if (asset === AssetType.Raster) {
    return 'Raster images';
  } else if (asset === AssetType.RegisteredTaskGraph) {
    return 'Taskgraphs';
  } else if (asset === AssetType.Soma) {
    return 'SOMA';
  } else if (asset === AssetType.UserDefinedFunction) {
    return 'UDFs';
  } else if (asset === AssetType.Vcf) {
    return 'VCFs';
  } else if (asset === AssetType.VectorSearch) {
    return 'Vector search';
  }
};

export default getAssetTitleForBrowsers;
