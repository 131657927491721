import {
  UserApi_getOnboardingItems,
  UserApi_getOnboardingItems$Params,
  UserApi_getOnboardingItems$Path,
  UserApi_getOnboardingItems$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import replacePathParams from 'utils/helpers/replacePathParams';

export const createPath = (data: UserApi_getOnboardingItems$Params): string => {
  return replacePathParams(UserApi_getOnboardingItems$Path, data);
};

const useGetOnboardingItems: UseRequestType<
  UserApi_getOnboardingItems$Params,
  UserApi_getOnboardingItems$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_getOnboardingItems$Response>,
    UserApi_getOnboardingItems$Params
  >(
    [createPath(queryData), queryData],
    useQueryRequest(UserApi_getOnboardingItems, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetOnboardingItems;
