import { ArrayActions, GroupActions, NamespaceActions } from 'api-client';

const hasPermissions =
  (permission: ArrayActions | NamespaceActions | GroupActions) =>
  (
    permissions: ArrayActions[] | NamespaceActions[] | GroupActions[] = []
  ): boolean => {
    return permissions?.includes(permission as never);
  };

export default hasPermissions;
