import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 3C4.34315 3 3 4.34315 3 6V42C3 43.6569 4.34315 45 6 45H42C43.6569 45 45 43.6569 45 42V6C45 4.34315 43.6569 3 42 3H6ZM8.25 31.6978V15.6H13.6893L17.4308 24.7179H17.6194L21.3609 15.6H26.8002V31.6978H22.5242V22.3913H22.3985L18.8142 31.5721H16.236L12.6517 22.3284H12.526V31.6978H8.25ZM39.75 31.6978H28.8714V15.6H33.2417V28.1764H39.75V31.6978Z"
  />,
  'ml model'
);
