import { AssetType } from 'api-client';
import { stringifyUrl } from 'query-string';

const ROOT = '/marketplace';

const marketplaceLinks = {
  root: () => ROOT,
  arrays: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Array },
    }),
  files: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.File },
    }),
  vcf: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Vcf },
    }),
  soma: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Soma },
    }),
  biomedicalImaging: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Bioimg },
    }),
  vectorSearch: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.VectorSearch },
    }),
  pointCloud: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Pointcloud },
    }),
  geometries: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Geometry },
    }),
  raster: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Raster },
    }),
  notebooks: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Notebook },
    }),
  dashboards: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Dashboard },
    }),
  taskGraphs: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.RegisteredTaskGraph },
    }),
  udfs: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.UserDefinedFunction },
    }),
  mlModels: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.MlModel },
    }),
  groups: () =>
    stringifyUrl({
      url: ROOT,
      query: { asset_type: AssetType.Group },
    }),
};

export default marketplaceLinks;
