import { formatTimezonedDate } from '../date';
import fragmentTimestampToDate from '../fragmentTimestampToDate';

const fragmentTimestampToDateTime = (
  timestamp: number,
  timezone: string
): string => {
  const date = fragmentTimestampToDate(timestamp);
  return formatTimezonedDate(timezone, date, 'dd MMM yyyy hh:mm:ss');
};

export default fragmentTimestampToDateTime;
