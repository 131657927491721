import { useQueryRequest, UseRequestType } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import {
  NotificationsApi_listNotifications,
  NotificationsApi_listNotifications$Params,
  NotificationsApi_listNotifications$Path,
  NotificationsApi_listNotifications$Response,
} from 'api-client';

export const queryKey = 'list-notifications';

export const createPath = (
  queryData: NotificationsApi_listNotifications$Params
): string => {
  const query = {
    page: queryData.page as any,
    per_page: queryData.perPage as any,
  };

  return queryString.stringifyUrl({
    url: NotificationsApi_listNotifications$Path,
    query,
  });
};

const useGetNotifications: UseRequestType<
  NotificationsApi_listNotifications$Params,
  NotificationsApi_listNotifications$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<NotificationsApi_listNotifications$Response>
  >(
    [queryKey, queryData],
    useQueryRequest(NotificationsApi_listNotifications, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetNotifications;
