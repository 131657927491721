import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM9.52264 21.6345H12.7326V30.3645H15.0876V21.6345H18.2976V19.6545H9.52264V21.6345ZM18.7076 19.6545L22.1576 24.7545L18.4226 30.3645H21.0626L23.4476 26.6595L25.7876 30.3645H28.5926L24.8576 24.7695L28.2926 19.6545H25.7126L23.5376 23.0745L21.4376 19.6545H18.7076ZM28.6974 21.6345H31.9074V30.3645H34.2624V21.6345H37.4724V19.6545H28.6974V21.6345Z"
  />,
  'txt'
);
