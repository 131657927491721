import classNames from 'classnames';
import './MainMenuButton.scss';
import { Icon } from 'components/Svg';
import React from 'react';
import Typography from 'components/Typography';
import Indicator from './Indicator';

interface MainButtonProps {
  to?: string;
  icon: Icon;
  text: string;
  active: boolean;
  onClick?: () => void;
  indicatorVariant?: 'info' | 'warning';
  dataTestId?: string;
  basicTourStep?: string;
  organizationBasicTourStep?: string;
}

const MainMenuButton: React.FC<MainButtonProps> = (props) => {
  const {
    to,
    icon: Icon,
    text,
    active,
    onClick,
    dataTestId,
    indicatorVariant,
    basicTourStep,
    organizationBasicTourStep,
  } = props;

  return (
    <Typography
      basicTourStep={basicTourStep}
      organizationBasicTourStep={organizationBasicTourStep}
      onClick={onClick}
      to={to}
      className={classNames('TDB-MainMenuButton', {
        'TDB-MainMenuButton--active': active,
      })}
      dataTestId={dataTestId}
    >
      <div className="TDB-MainMenuButton__tile">
        <Icon className="TDB-MainMenuButton__icon" size="3rem" />
      </div>
      <Typography
        className="TDB-MainMenuButton__text"
        align="center"
        fontWeight="600"
        variant="app-default"
        fontSize="body-xxs"
      >
        {text}
      </Typography>
      {indicatorVariant && (
        <Indicator
          className="TDB-MainMenuButton__indicator"
          variant={indicatorVariant}
          active={active}
        />
      )}
    </Typography>
  );
};

export default MainMenuButton;
