enum IframeActions {
  /**
   * Message from our webapp to Jupyterhub with data
   * regarding what server/image to auto-select and launch.
   */
  TILEDB_AUTOLOAD_IMAGE = 'TILEDB_AUTOLOAD_IMAGE',
  /**
   * Message sent while loading a notebook server with the progress as message
   */
  TILEDB_NOTEBOOK_PROGRESS = 'TILEDB_NOTEBOOK_PROGRESS',
  /**
   * Message sent from Jupyterhub's error.html
   */
  TILEDB_ERROR_500 = 'TILEDB_ERROR_500',
  /**
   * Message sent from jupyterlab (prompt plugin), to request
   * webapp to show "add credentials" modal.
   */
  TILEDB_ADD_CREDENTIALS = '@tiledb/prompt_options::add_credentials',
  /**
   * Message sent from webapp to notebook when user add credentials
   * The flow starts when prompt plugin asks the app to show the add
   * credentials modal by sending message TILEDB_ADD_CREDENTIALS
   */
  TILEDB_UPDATED_CREDENTIALS = 'TILEDB_UPDATED_CREDENTIALS',
  /**
   * Message sent from jupyterlab/jupyterhub to show a loader,
   * **DEPRECATED**
   */
  TILEDB_SHOW_LOADER_START = 'TILEDB_SHOW_LOADER_START',
  /**
   * Message sent from jupyterlab/jupyterhub to hide the loader,
   * **DEPRECATED**
   */
  TILEDB_SHOW_LOADER_STOP = 'TILEDB_SHOW_LOADER_STOP',
  /**
   * Message sent from Jupyterhub, in order to redirect user
   * to the billing page to update billing information.
   */
  TILEDB_REQUEST_CREDITS = 'TILEDB_REQUEST_CREDITS',
  /**
   * Message sent when jupyterlab has loaded
   */
  TILEDB_JUPYTERLAB_LOADED = 'jupyterlab_loaded',
  /**
   * Message sent when jupyterlab has loaded
   */
  TILEDB_JUPYTERLAB_LAUNCHING = 'jupyterlab_launching',
  /**
   * Message sent when Jupyterhub's not_running.html page is rendered
   * Rare case, since when rendered jupyterhub is redirecting
   * user after 1 sec to the spawn page
   */
  TILEDB_JUPYTER_NOT_RUNNING = 'jupyter_not_running',
  /**
   * Message sent when Jupyterhub's spawn.html is rendered
   * The page where user selects image & server profile.
   */
  TILEDB_SPAWN_READY = 'spawn_options_ready',

  /**
   * Message sent from Jupyterhub & Jupyterlab when they load
   */
  TILEDB_JUPYTERHUB_OR_JUPYTERLAB_LOADED = 'jupyter_loaded',
  /**
   * Message sent to Jupyterhub to request what the user has selected.
   */
  TILEDB_REQUEST_SELECTIONS = 'TILEDB_REQUEST_SELECTIONS',
  /**
   * Message sent from Jupyterhub spawn page, with the available images & server profiles.
   */
  TILEDB_AVAILABLE_PROFILES = 'TILEDB_AVAILABLE_PROFILES',
  /**
   * Message sent to Jupyterhub to not render a submit button.
   */
  TILEDB_RENDER_NO_SUBMIT_BUTTON = 'TILEDB_RENDER_NO_SUBMIT_BUTTON',

  /**
   * Message received from Jupyterhub with the selections of the user.
   */
  TILEDB_JUPYTERHUB_USER_SELECTIONS = 'TILEDB_JUPYTERHUB_USER_SELECTIONS',
  /**
   * Message received from jupyter notebooks (TileDB prompt extension)
   * to open modal and create a new notebook
   */
  TILEDB_CREATE_NOTEBOOK = 'TILEDB_CREATE_NOTEBOOK',

  /**
   * Message received when jupyterlab extension @tiledb-inc/open_file has loaded
   */
  TILEDB_OPEN_FILE_EXTENSION_LOADED = '@tiledb/open_file::loaded',
  /**
   * Message prefix for notebook sharing request from jupyterlab extension @tiledb-inc/share_notebook
   */
  TILEDB_NOTEBOOK_SHARING_PREFIX = 'tiledb_notebook_share::',
  /**
   * Message sent from jupyterhub upon user selection, if notebook is missing image & server profile
   */
  TILEDB_UPDATE_FILE_PROPERTIES = 'TILEDB_UPDATE_FILE_PROPERTIES',
  /**
   * Message to be sent to jupyter if user has no credits
   */
  TILEDB_NO_CREDITS = 'TILEDB_NO_CREDITS',
  /**
   * Message sent from voila, when a voila page is rendered.
   */
  TILEDB_VOILA_LAUNCHED = 'TILEDB_VOILA_LAUNCHED',
}

export default IframeActions;
