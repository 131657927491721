import CustomError from '../CustomError';

class UnauthorizedError extends CustomError {
  static type = 'UNAUTHORIZED';

  constructor(message?: string) {
    super(message);
    this._type = UnauthorizedError.type;
  }
}

export default UnauthorizedError;
