import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.8861 3.78918C25.5423 3.78918 26.1567 4.11095 26.5304 4.6507L44.5305 30.6505C44.8519 31.1149 44.9621 31.6931 44.8338 32.2432C44.7059 32.793 44.351 33.263 43.8575 33.5373L25.8574 43.5376C25.2534 43.8731 24.5188 43.8731 23.9148 43.5376L5.91476 33.5373C5.42123 33.2631 5.06666 32.793 4.93838 32.2432C4.81015 31.6931 4.9203 31.1149 5.24174 30.6505L23.2418 4.6507C23.6154 4.11097 24.2298 3.78918 24.8861 3.78918ZM9.78664 31.1124L22.8855 38.3897V12.1911L9.78664 31.1124ZM26.8858 12.1909V38.3895L39.9847 31.1122L26.8858 12.1909Z"
  />,
  'point-cloud'
);
