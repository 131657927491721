import React from 'react';
import * as Sentry from '@sentry/react';
import Text from 'components/Text';

interface CustomErrorBoundaryProps extends React.PropsWithChildren {
  fallbackContent?: React.ReactNode;
  onError?: (err: Error) => void;
}

class CustomErrorBoundary extends React.Component<
  CustomErrorBoundaryProps,
  any
> {
  state = {
    hasError: false,
    message: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true,
      message: error.message,
    });

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as never);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });

    this.props.onError?.(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallbackContent || (
          <Text as="p">Unexpected error occurred.</Text>
        )
      );
    }

    return this.props.children;
  }
}

export default CustomErrorBoundary;
