import { RefObject, useCallback, useLayoutEffect, useRef } from 'react';

interface UseScrollPositionResult {
  scrollTop: number;
  height: number;
  scrollHeight: number;
}

type UseScrollPositionCallback = (result: UseScrollPositionResult) => void;

const getScrollPosition = (refElement: RefObject<HTMLElement>) => {
  const target = refElement.current;

  if (!target) {
    return { scrollHeight: 0, scrollTop: 0, height: 0 };
  }

  const scrollTop = target.scrollTop;
  const scrollHeight = target.scrollHeight;
  const height = target.getBoundingClientRect().height || 0;

  return {
    scrollHeight,
    scrollTop,
    height,
  };
};

const useScrollPosition = (
  callback: UseScrollPositionCallback,
  ref: RefObject<HTMLElement>
) => {
  const element = ref.current;
  const result = useRef<any>(getScrollPosition(ref));

  const setPosition = useCallback(() => {
    const currentPosition = getScrollPosition(ref);
    result.current = currentPosition;
    callback(result.current);
  }, [ref, callback]);

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    element.addEventListener('scroll', setPosition);

    return () => element.removeEventListener('scroll', setPosition);
  }, [element, setPosition]);
};

export default useScrollPosition;
