export enum GlobalVariable {
  GOOGLE_CLIENT_ID = 'GOOGLE_CLIENT_ID',
  JUPYTERHUB_URL = 'JUPYTERHUB_URL',
  BASE_PATH = 'BASE_PATH',
  OKTA_DOMAIN = 'OKTA_DOMAIN',
  OKTA_CLIENT_ID = 'OKTA_CLIENT_ID',
  GITHUB_CLIENT_ID = 'GITHUB_CLIENT_ID',
  STRIPE_CLIENT_ID = 'STRIPE_CLIENT_ID',
  STRIPE_API_KEY = 'STRIPE_API_KEY',
  SENTRY_ENVIRONMENT = 'SENTRY_ENVIRONMENT',
  SENTRY_DSN = 'SENTRY_DSN',
  UI_GOOGLE_CAPTCHA_SITE_KEY = 'UI_GOOGLE_CAPTCHA_SITE_KEY',
  AWS_ACCOUNT_ID = 'AWS_ACCOUNT_ID',
  AWS_ENDPOINT = 'AWS_ENDPOINT',
  GCP_ENDPOINT = 'GCP_ENDPOINT',
  LDAP = 'LDAP',
}
