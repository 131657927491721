import { useEffect, useRef } from 'react';
import eventBus, {
  EventSubscription,
  EventTypes,
} from 'utils/helpers/eventBus';

const useEventCallback = (event: EventTypes, callback: Function): void => {
  const subscription = useRef<EventSubscription>();

  useEffect(() => {
    subscription.current = eventBus.on(event, (data?: any) => {
      callback(data);
    });
    return (): void => {
      subscription.current?.unsubscribe();
    };
  }, [callback, event]);
};

export default useEventCallback;
