const pointCloudLinks = {
  details: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}`,

  overview: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/overview`,

  preview: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/preview`,

  sharing: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/sharing`,

  settings: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/settings`,

  contents: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/contents`,

  metadata: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/metadata`,

  activity: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/point-cloud/${namespace}/${groupId}/activity`,
};

export default pointCloudLinks;
