import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M41.4125 11.247C41.4125 9.63291 40.4432 7.35515 35.8265 5.68464C32.8289 4.59774 28.8806 4 24.7063 4C20.532 4 16.5839 4.59776 13.586 5.68464C8.96934 7.35549 8 9.63291 8 11.247V36.753C8 38.3671 8.96934 40.6448 13.5811 42.3154C16.5836 43.4023 20.532 44 24.7064 44C28.8808 44 32.8288 43.4022 35.8266 42.3154C40.4433 40.6445 41.4126 38.3671 41.4126 36.753L41.4125 11.247ZM24.7065 7.76474C33.1772 7.76474 37.6478 10.3531 37.6478 11.2472C37.6478 12.1411 33.1773 14.7294 24.7065 14.7294C16.2358 14.7294 11.7652 12.1411 11.7652 11.247C11.7652 10.3531 16.2358 7.76474 24.7065 7.76474ZM11.7652 16.0283C12.3541 16.3257 12.9608 16.5867 13.5818 16.8096C16.5841 17.8824 20.5324 18.4942 24.7063 18.4942C28.8802 18.4942 32.8287 17.8824 35.8307 16.8096C36.4534 16.5867 37.0617 16.3261 37.6519 16.0283V23.7647C37.6519 24.6589 33.2 27.2472 24.7106 27.2472C16.2215 27.2472 11.7694 24.6588 11.7694 23.7647L11.7652 16.0283ZM24.7065 40.2353C16.2359 40.2353 11.7652 37.6469 11.7652 36.7528V28.546C12.3541 28.8434 12.9608 29.1044 13.5818 29.3269C16.5841 30.4142 20.5324 31.0119 24.7063 31.0119C28.8802 31.0119 32.8287 30.4142 35.8265 29.3273V29.3269C36.4492 29.1044 37.0572 28.8434 37.6477 28.546V36.7531C37.6477 37.647 33.1772 40.2354 24.7064 40.2354L24.7065 40.2353ZM23.2948 24.9882H26.1185C26.7911 24.9882 27.4124 24.6295 27.7486 24.0468C28.0847 23.4645 28.0847 22.7469 27.7486 22.1646C27.4124 21.5823 26.791 21.2235 26.1185 21.2235H23.2948C22.6222 21.2235 22.0008 21.5823 21.6647 22.1646C21.3285 22.7469 21.3285 23.4645 21.6647 24.0468C22.0008 24.6295 22.6222 24.9882 23.2948 24.9882ZM23.2946 33.4589H26.1183C26.7908 33.4589 27.4123 33.8177 27.7484 34.4003C28.0845 34.9826 28.0845 35.7002 27.7484 36.2825C27.4123 36.8649 26.7909 37.2236 26.1183 37.2236H23.2946C22.6221 37.2236 22.0006 36.8649 21.6645 36.2825C21.3284 35.7003 21.3284 34.9827 21.6645 34.4003C22.0006 33.8177 22.622 33.4589 23.2946 33.4589Z"
  />,
  'database'
);
