import * as React from 'react';
import history from 'utils/helpers/history';
import { ArrayActions, GroupActions, NamespaceActions } from 'api-client';
import Placeholder from 'components/v2/Placeholder';

interface Props extends React.PropsWithChildren {
  permissions?: NamespaceActions[] | ArrayActions[] | GroupActions[];
  showFallback?: boolean;
  requiredPermission: NamespaceActions | ArrayActions | GroupActions;
}

const PermissionsGuard: React.FC<Props> = (props) => {
  const {
    showFallback,
    children,
    requiredPermission,
    permissions = [],
  } = props;
  if (!permissions.includes(requiredPermission as never)) {
    if (showFallback) {
      return (
        <Placeholder
          title="Access denied"
          description="You don't have permissions to access this page."
          graphic="unauthorized-access"
          actions={[
            {
              variant: 'primary',
              size: 'large',
              children: 'Back to home',
              onClick: (): void => history.push('/'),
            },
          ]}
        />
      );
    }

    return null;
  }

  return <>{children}</>;
};

export default PermissionsGuard;
