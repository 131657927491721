import './LazyLoadListWrapper.scss';
import React, { useRef } from 'react';
import useScrollPosition from 'hooks/useScrollPosition';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import useMount from 'hooks/useMount';

interface LazyLoadListWrapperProps extends React.PropsWithChildren {
  onScrollEnd: (() => void) | undefined;
  className?: string;
  loading: boolean;
  // Specify in pixels the distance from bottom to trigger onScrollEnd
  triggerOffset?: number;
  // Passes div element to parent on mount
  getRef?: (ref: HTMLDivElement) => void;
}

const LazyLoadListWrapper: React.FC<LazyLoadListWrapperProps> = (props) => {
  const {
    children,
    onScrollEnd,
    className,
    loading,
    triggerOffset = 0,
    getRef,
  } = props;
  const ref = useRef<any>(null);

  useScrollPosition((result) => {
    const { scrollTop, scrollHeight, height } = result;

    // Trigger callback on scroll end
    if (scrollTop + height + triggerOffset >= scrollHeight) {
      if (!loading && onScrollEnd) {
        onScrollEnd();
      }
    }
  }, ref);

  // Pass div element to parent
  useMount(() => getRef?.(ref.current as HTMLDivElement));

  return (
    <div ref={ref} className={classNames('TDB-LazyLoadListWrapper', className)}>
      {children}
      {loading && (
        <div className="TDB-LazyLoadListWrapper__Loader">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default LazyLoadListWrapper;
