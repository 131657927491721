import {
  UserApi_getUserMetaStatus,
  UserApi_getUserMetaStatus$Params,
  UserApi_getUserMetaStatus$Path,
  UserApi_getUserMetaStatus$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

const useGetUserMeta: UseRequestType<
  UserApi_getUserMetaStatus$Params,
  UserApi_getUserMetaStatus$Response
> = (__, config, queryConfig) => {
  const { isFetching, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_getUserMetaStatus$Response>,
    UserApi_getUserMetaStatus$Params
  >(
    [UserApi_getUserMetaStatus$Path],
    useQueryRequest(UserApi_getUserMetaStatus, config),
    queryConfig
  );

  return [refetch, data, error, isFetching];
};

export default useGetUserMeta;
