import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  NotebookApi_getNotebookServerStatus,
  NotebookApi_getNotebookServerStatus$Params,
  NotebookApi_getNotebookServerStatus$Path,
  NotebookApi_getNotebookServerStatus$Response,
} from 'api-client';
import replacePathParams from 'utils/helpers/replacePathParams';

export const getNotebookStatusKey = (ns: string) => {
  return replacePathParams(NotebookApi_getNotebookServerStatus$Path, {
    namespace: ns,
  });
};

const useGetNotebookStatus: UseRequestType<
  NotebookApi_getNotebookServerStatus$Params,
  NotebookApi_getNotebookServerStatus$Response
> = (queryData, config, queryConfig) => {
  const { data, error, refetch, isInitialLoading } = useQuery<
    AxiosResponse<NotebookApi_getNotebookServerStatus$Response>,
    NotebookApi_getNotebookServerStatus$Params
  >(
    [getNotebookStatusKey(queryData.namespace), queryData],
    useQueryRequest(NotebookApi_getNotebookServerStatus, config),
    queryConfig
  );
  return [refetch, data, error, isInitialLoading];
};

export default useGetNotebookStatus;
