import * as React from 'react';
import HistoryItem from 'store/HistoryStack/HistoryItem';
import styles from './Breadcrumb.module.scss';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbHiddenContent from './BreadcrumbHiddenContent';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import useHistoryStore, {
  useGetNavigationItemsSinceRoot,
} from 'store/HistoryStack';
import history from 'utils/helpers/history';
import BreadcrumbTitle from './BreadcrumbTitle';
import { HistoryItemWithkeyAndPosition } from './BreadcrumbHiddenContent/BreadcrumbHiddenContent';
import { isNumber } from 'utils/helpers/number';

type Props = {
  items: HistoryItem[];
  goBack: (n: number) => void;
};

interface ChildWidths {
  width: number;
  key: string;
}

const MORE_CONTENT_WIDTH = 48;

export const Breadcrumb: React.FC<Props> = (props) => {
  const { items, goBack } = props;
  const [containerRef, containerBounds] = useMeasure({
    polyfill: ResizeObserver,
  });
  const historyItems: HistoryItemWithkeyAndPosition[] = React.useMemo(() => {
    return items.map((item, i) => ({
      ...item,
      key: `${item.key}-${i}`,
      position: i,
    }));
  }, [items]);
  const [hiddenItems, setHiddenItems] = React.useState<
    Set<HistoryItemWithkeyAndPosition>
  >(new Set());

  const contentRef = React.useRef<HTMLDivElement>(null);
  const [childrenWidths, setChildWidth] = React.useState<
    Record<string, number>
  >({});
  const threshold = React.useMemo(
    () =>
      hiddenItems.size > 0
        ? containerBounds.width - MORE_CONTENT_WIDTH
        : containerBounds.width,
    [containerBounds.width, hiddenItems.size]
  );

  const updateChildWidth = React.useCallback((values: ChildWidths) => {
    setChildWidth((widths) => ({
      ...widths,
      [values.key]: values.width,
    }));
  }, []);

  const itemsLength = items.length;

  React.useEffect(() => {
    setHiddenItems(new Set());
  }, [itemsLength]);

  React.useEffect(() => {
    if (containerBounds.width < 10) {
      return;
    }

    /**
     * Total width cache (used in the iteration)
     */
    let totalWidth = 0;

    /**
     * Iterate through every button
     * If the button can fit in the current viewport we append it in the "visible" list
     * Otherwise we append it in the "hidden" list
     */
    if (!historyItems.length) {
      return;
    }
    // Remove first item from threshold
    const firstItemKey = historyItems[0].key;
    const firstItemWidth = childrenWidths[firstItemKey];
    let thresholdExcludingFirstAndLastItem = threshold;
    if (firstItemWidth) {
      thresholdExcludingFirstAndLastItem -= firstItemWidth;
    }

    if (items.length > 2) {
      const lastPosition = itemsLength - 1;
      const lastItemKey = historyItems[lastPosition].key;
      const lastItemWidth = childrenWidths[lastItemKey];
      // Remove last item from threshold
      if (isNumber(lastItemWidth) && !isNaN(lastItemWidth)) {
        thresholdExcludingFirstAndLastItem -= lastItemWidth;
      }
    }

    for (let i = itemsLength - 2; i >= 0; i--) {
      // Skip first item (will always be visible)
      if (i === 0) {
        continue;
      }
      const historyItem = historyItems[i];
      const key = historyItem.key;
      const itemWidth = childrenWidths[key];

      if (!itemWidth) {
        continue;
      }

      if (hiddenItems.size > 0) {
        /**
         * If we hit overflow we put all the rest of the items in hidden,
         * This way we make sure we keep them in the same order.
         * (e.g. having the 2nd item in the hidden content and the 3rd showing because it's smaller).
         */
        setHiddenItems((hiddenItems) => new Set(hiddenItems).add(historyItem));
        continue;
      }
      const nextTotalWidth = totalWidth + itemWidth;
      if (nextTotalWidth < thresholdExcludingFirstAndLastItem) {
        totalWidth = nextTotalWidth;
      } else {
        /**
         * When we have hidden items, we render a "more items" button that we need to account the width that is taking (check threshold)
         * We always render first item so we skip first item.
         */
        setHiddenItems((hiddenItems) => new Set(hiddenItems).add(historyItem));
      }
    }
  }, [
    childrenWidths,
    containerBounds.width,
    hiddenItems.size,
    historyItems,
    items.length,
    itemsLength,
    threshold,
  ]);

  if (!items.length) {
    return null;
  }

  if (items.length === 1) {
    return <BreadcrumbTitle item={items[0]} />;
  }

  return (
    <div className={styles.container} ref={containerRef}>
      <div ref={contentRef} className={styles.content}>
        <BreadcrumbItem
          onWidthUpdate={updateChildWidth}
          goBack={() => goBack(itemsLength)}
          item={historyItems[0]}
          key={`${historyItems[0].pathname}-0`}
          active={false}
        />
        {hiddenItems.size > 0 && (
          <BreadcrumbHiddenContent
            goBack={(n: number) => goBack(itemsLength - n)}
            items={Array.from(hiddenItems)}
          />
        )}
        {historyItems.map((item, i) => {
          const { key } = item;

          if (i === 0) {
            return null;
          }

          const isLast = item.position === itemsLength - 1;
          return (
            <BreadcrumbItem
              hidden={hiddenItems.has(item)}
              onWidthUpdate={updateChildWidth}
              goBack={() => goBack(itemsLength - i)}
              item={item}
              key={key}
              active={isLast} // Active is the last item in the stack
            />
          );
        })}
      </div>
    </div>
  );
};

const BreadcrumbWithStore: React.FC = () => {
  const historyItems = useHistoryStore(useGetNavigationItemsSinceRoot);
  const onItemClick = React.useCallback((n: number) => {
    history.pushWithSameKeyAsNItem(n);
  }, []);

  return <Breadcrumb items={historyItems} goBack={onItemClick} />;
};

export default BreadcrumbWithStore;
