import React from 'react';
import { UserApi_logout } from 'api-client';
import useMount from 'hooks/useMount';
import history from 'utils/helpers/history';
import { authLinks } from 'utils/links';
import Loader from 'components/Loader';
import logoutHandler from 'utils/helpers/logoutHandler';

const Logout: React.FC = () => {
  useMount(() => {
    UserApi_logout().finally(() => {
      logoutHandler();
      history.push(authLinks.login());
    });
  });

  return <Loader data-testid="logout-loader" />;
};

export default Logout;
