const notebookRouterPaths = {
  root: '/notebooks',
  details: '/notebooks/details/:namespace/:arrayId',
  sharing: '/notebooks/details/:namespace/:arrayId/sharing',
  overview: '/notebooks/details/:namespace/:arrayId/overview',
  settings: '/notebooks/details/:namespace/:arrayId/settings',
  preview: '/notebooks/details/:namespace/:arrayId/preview',
  activity: '/notebooks/details/:namespace/:arrayId/activity',
};

export default notebookRouterPaths;
