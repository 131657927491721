import { useRef, MutableRefObject, useEffect } from 'react';

/**
 * Hook to set focus on element
 * @param auto Automatically auto focuses input on mount
 */
function useFocus(auto?: boolean): [MutableRefObject<null>, Function] {
  const htmlElRef: MutableRefObject<any> = useRef();
  const setFocus = (): void => {
    htmlElRef?.current?.focus();
    htmlElRef?.current?.select();
  };

  useEffect(() => {
    auto && setFocus();
  }, [auto]);

  return [htmlElRef, setFocus];
}

export default useFocus;
