import { MutateResponse, useMutateRequest } from 'hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import {
  NotificationsApi_deleteNotifications,
  NotificationsApi_deleteNotifications$Params,
  NotificationsApi_deleteNotifications$Response,
} from 'api-client';
import queryClient from 'utils/helpers/queryClient';
import { queryKey as countQueryKey } from '../useGetNotificationCount';
import { queryKey as listQueryKey } from '../useGetNotifications';

const keysToInvalidate = [countQueryKey, listQueryKey];

const useDeleteNotifications = (): MutateResponse<
  NotificationsApi_deleteNotifications$Params,
  NotificationsApi_deleteNotifications$Response
> => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    NotificationsApi_deleteNotifications,
    {
      // invalidate notification queries
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            keysToInvalidate.includes(query.queryKey[0] as string),
        });
      },
    }
  );

  return [
    useMutateRequest(mutateAsync),
    data,
    error as null | Error,
    isLoading,
  ];
};

export default useDeleteNotifications;
