type PollOptions = {
  /**
   * The interval between polls in milliseconds.
   */
  interval?: number;
  /**
   * A predicate function that determines if the polled value satisfies the condition.
   * Defaults to always returning true.
   */
  condition?: (value: any) => boolean;
  /**
   * The maximum time to poll in milliseconds before rejecting.
   * Defaults to infinity (no timeout).
   */
  timeout?: number;
};

async function polling<T>(fn: () => T, options: PollOptions = {}): Promise<T> {
  const {
    interval = 1000,
    condition = (v: any) => Boolean(v),
    timeout = Infinity,
  } = options;

  const startTime = Date.now();

  while (true) {
    const value = fn();

    if (condition(value)) {
      return value;
    }

    if (Date.now() - startTime > timeout) {
      throw new Error(`Polling timed out after ${timeout} milliseconds`);
    }

    await new Promise((resolve) => setTimeout(resolve, interval));
  }
}

export default polling;
