import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M42.6828 5.6148L42.5207 4.96648L41.528 4.70622C33.7709 2.68064 25.7098 5.11467 20.3685 10.6909H10.7332C8.83556 10.6909 7.1495 11.9031 6.55002 13.706L4.11461 21.0145C4.03967 21.2393 4 21.4751 4 21.7109C4 22.9275 4.98739 23.9149 6.20399 23.9149H12.3727L9.61076 29.0618C9.15257 29.919 9.30842 30.9756 9.9957 31.6629L15.4924 37.1595C16.1781 37.8453 17.2332 38.0027 18.0872 37.5445L21.8811 35.5186V41.796C21.8811 43.0126 22.8685 44 24.0851 44C24.3209 44 24.5567 43.9603 24.7815 43.8853L32.09 41.4499C33.8928 40.8505 35.105 39.1644 35.105 37.2668V28.0389C41.6995 22.7685 44.7816 14.041 42.6828 5.6148ZM29.9112 11.0585C31.6333 9.3364 34.423 9.3364 36.1451 11.0585C37.8656 12.779 37.8672 15.5702 36.1451 17.2923C34.423 19.0144 31.6318 19.0129 29.9112 17.2923C28.1891 15.5702 28.1891 12.7806 29.9112 11.0585Z"
  />,
  'launch'
);
