import CustomError from '../CustomError';

class ForbiddenError extends CustomError {
  static type = 'FORBIDDEN';

  constructor(message?: string) {
    super(message);
    this._type = ForbiddenError.type;
  }
}

export default ForbiddenError;
