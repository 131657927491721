import React, { useEffect } from 'react';
import Auth from './Auth';
import NotFound from './NotFound';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import withErrorBoundary from 'HOC/withErrorBoundary';
import Console from './Console';
import OAuth2 from './OAuth2';
import {
  authRouterPaths,
  oauth2RouterPaths,
  callbackRouterPaths,
} from 'utils/routerPaths';
import Callbacks from './Callbacks';
import {
  authLinks,
  rootPath,
  assetsLinks,
  marketplaceLinks,
} from 'utils/links';
import { WorkspaceProvider } from 'store/WorkspaceContext';
import { OnboardingProvider } from 'store/OnboardingContext';
import { OrganizationProvider } from 'store/OrganizationContext';
import Logout from './Auth/Logout';

interface SSOParams {
  provider: 'github' | 'google';
}

function Routes(): JSX.Element {
  const { pathname } = useLocation();

  /**
   * Scroll to top on every react-router transition
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/* Redirects for pages that have been moved permanently */}
      {/* Permanent redirects start */}
      <Redirect exact from="/login" to={authLinks.login()} />
      <Redirect exact from="/signup" to={authLinks.signup()} />
      <Redirect
        exact
        from="/forgot-password"
        to={authRouterPaths.forgotPassword}
      />
      <Redirect exact from="/explore/arrays" to={marketplaceLinks.arrays()} />
      <Redirect
        exact
        from="/explore/notebooks"
        to={marketplaceLinks.notebooks()}
      />
      <Redirect exact from="/explore/udfs" to={marketplaceLinks.udfs()} />
      <Redirect
        exact
        from="/explore/dashboards"
        to={marketplaceLinks.dashboards()}
      />
      <Redirect
        exact
        from="/explore/ml_models"
        to={marketplaceLinks.mlModels()}
      />
      <Redirect exact from="/explore/files" to={marketplaceLinks.files()} />
      <Redirect exact from="/explore/groups" to={marketplaceLinks.groups()} />
      <Redirect
        exact
        from="/explore/taskgraphs"
        to={marketplaceLinks.taskGraphs()}
      />

      {/* Assets route redirects */}
      <Redirect from="/arrays/me" to={assetsLinks.arrays.my()} />
      <Redirect from="/arrays/shared" to={assetsLinks.arrays.shared()} />
      <Redirect from="/arrays/public" to={marketplaceLinks.arrays()} />
      <Redirect from="/files/me" to={assetsLinks.files.my()} />
      <Redirect from="/files/shared" to={assetsLinks.files.shared()} />
      <Redirect from="/files/public" to={marketplaceLinks.files()} />
      <Redirect from="/notebooks/me" to={assetsLinks.notebooks.my()} />
      <Redirect from="/notebooks/shared" to={assetsLinks.notebooks.shared()} />
      <Redirect from="/notebooks/public" to={marketplaceLinks.notebooks()} />
      <Redirect from="/dashboards/me" to={assetsLinks.dashboards.my()} />
      <Redirect
        from="/dashboards/shared"
        to={assetsLinks.dashboards.shared()}
      />
      <Redirect from="/udfs/me" to={assetsLinks.udfs.my()} />
      <Redirect from="/udfs/shared" to={assetsLinks.udfs.shared()} />
      <Redirect from="/udfs/public" to={marketplaceLinks.udfs()} />
      <Redirect from="/taskgraphs/me" to={assetsLinks.taskGraphs.my()} />
      <Redirect
        from="/taskgraphs/shared"
        to={assetsLinks.taskGraphs.shared()}
      />
      <Redirect from="/taskgraphs/public" to={marketplaceLinks.taskGraphs()} />
      <Redirect from="/vcf/me" to={assetsLinks.vcf.my()} />
      <Redirect from="/vcf/shared" to={assetsLinks.vcf.shared()} />
      <Redirect from="/vcf/public" to={marketplaceLinks.vcf()} />
      <Redirect from="/soma/me" to={assetsLinks.soma.my()} />
      <Redirect from="/soma/shared" to={assetsLinks.soma.shared()} />
      <Redirect from="/soma/public" to={marketplaceLinks.soma()} />
      <Redirect
        from="/biomedical-imaging/me"
        to={assetsLinks.biomedicalImaging.my()}
      />
      <Redirect
        from="/biomedical-imaging/shared"
        to={assetsLinks.biomedicalImaging.shared()}
      />
      <Redirect
        from="/biomedical-imaging/public"
        to={marketplaceLinks.biomedicalImaging()}
      />
      <Redirect from="/mlmodels/me" to={assetsLinks.mlModels.my()} />
      <Redirect from="/mlmodels/shared" to={assetsLinks.mlModels.shared()} />
      <Redirect from="/mlmodels/public" to={marketplaceLinks.mlModels()} />
      <Redirect from="/vector-search/me" to={assetsLinks.vectorSearch.my()} />
      <Redirect
        from="/vector-search/shared"
        to={assetsLinks.vectorSearch.shared()}
      />
      <Redirect
        from="/vector-search/public"
        to={marketplaceLinks.vectorSearch()}
      />
      <Redirect from="/point-cloud/me" to={assetsLinks.pointCloud.my()} />
      <Redirect
        from="/point-cloud/shared"
        to={assetsLinks.pointCloud.shared()}
      />
      <Redirect from="/point-cloud/public" to={marketplaceLinks.pointCloud()} />
      <Redirect from="/geometry/me" to={assetsLinks.geometry.my()} />
      <Redirect from="/geometry/shared" to={assetsLinks.geometry.shared()} />
      <Redirect from="/geometry/public" to={marketplaceLinks.geometries()} />
      <Redirect from="/raster/me" to={assetsLinks.raster.my()} />
      <Redirect from="/raster/shared" to={assetsLinks.raster.shared()} />
      <Redirect from="/raster/public" to={marketplaceLinks.raster()} />
      <Redirect from="/groups/me" to={assetsLinks.groups.my()} />
      <Redirect from="/groups/shared" to={assetsLinks.groups.shared()} />
      <Redirect from="/groups/public" to={marketplaceLinks.groups()} />
      {/* Assets route redirects end */}
      {/* Permanent redirects end */}

      <Route path={authRouterPaths.logout} component={Logout} />
      <Route
        path="/reset_password"
        render={(props: RouteComponentProps<any>): React.ReactElement => {
          return (
            <Redirect
              to={authRouterPaths.resetPassword + props.location.search}
            />
          );
        }}
      />
      <Redirect
        exact
        from="/email-not-confirmed"
        to={authRouterPaths.emailNotConfirmed}
      />
      <Route
        path="/confirm_email"
        render={(props: RouteComponentProps<any>): React.ReactElement => {
          return (
            <Redirect
              to={authRouterPaths.confirmEmail + props.location.search}
            />
          );
        }}
      />
      <Route
        path="/sso/callback/:provider"
        render={(
          props: RouteComponentProps<{ provider: string }>
        ): React.ReactNode => {
          return (
            <Redirect
              to={
                authLinks.ssoProvider({
                  provider: (props as RouteComponentProps<SSOParams>).match
                    .params.provider,
                }) + props.location.search
              }
            />
          );
        }}
      />

      <Route path={authRouterPaths.root} component={Auth} />
      <Route path={oauth2RouterPaths.root} component={OAuth2} />

      <Route path={callbackRouterPaths.root} render={() => <Callbacks />} />
      <Route
        path={rootPath}
        render={(): JSX.Element => (
          <WorkspaceProvider>
            <OrganizationProvider>
              <OnboardingProvider>
                <Console />
              </OnboardingProvider>
            </OrganizationProvider>
          </WorkspaceProvider>
        )}
      />

      <Route render={(): JSX.Element => <NotFound />} />
    </Switch>
  );
}

export default withErrorBoundary(Routes);
