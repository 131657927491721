import {
  History,
  Location,
  LocationDescriptor,
  createBrowserHistory,
} from 'history';
import toggleMainSidebar from '../toggleMainSidebar';
import prependBasePath from 'utils/helpers/prependBasePath';
import useHistoryStore, {
  peekNItemInNavigationSelector,
} from 'store/HistoryStack';
import pushPathnameToToHistoryStack from './pushPathnameToToHistoryStack';
import arePathnamesInSameLevel from 'store/HistoryStack/arePathnamesInSameLevel';
import { authLinks, oauth2Links } from 'utils/links';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';

type CustomHistory = History<unknown> & {
  pushWithSameKeyAsNItem: (n: number) => void;
};

type LocationWithKey = Location<{ key?: string }>;

// Prepend BASE_PATH to support serving the UI from a subpath i.e. /ui (for enterprise)
const customHistory = createBrowserHistory({
  basename: prependBasePath(''),
});

/**
 * Replace history's behavior to prevent multiple pushes of the same page.
 * https://github.com/ReactTraining/history/issues/470
 */
const prevHistoryPush = customHistory.push;
let lastLocation = customHistory.location;
let lastPath: string;

customHistory.listen((location, action) => {
  lastLocation = location;
  updateRedirectToAfterLogin(location.pathname, location.search);

  if (action === 'PUSH') {
    const locationKey =
      (location as LocationWithKey)?.state.key || location.key;

    pushPathnameToToHistoryStack(location.pathname, {
      key: locationKey,
      search: location.search,
    });
  }

  if (action === 'POP') {
    /**
     * HANDLE Browser history
     * User goes back/forward using browser history,
     */
    const secondToLastItem = useHistoryStore.getState().peek(2);
    const lastItemInStack = useHistoryStore.getState().peek(1);
    if (useHistoryStore.getState().peek(1)?.key === location.key) {
      /**
       * Case goBack from browser history
       */
    } else if (
      secondToLastItem?.key === location.key ||
      arePathnamesInSameLevel(secondToLastItem?.pathname, location.pathname)
    ) {
      useHistoryStore.getState().goBack(1);
    } else if (
      arePathnamesInSameLevel(lastItemInStack?.pathname, location.pathname)
    ) {
      // NOOP
      /**
       * Case browser history "goForward"
       */
    } else {
      pushPathnameToToHistoryStack(location.pathname + location.search, {
        key: location.key,
        exact: false,
      });
    }
  }

  if (location.pathname !== lastPath) {
    // hide sidebars on route change
    toggleMainSidebar(false);
  }

  lastPath = location.pathname;
});

type LocationType = LocationDescriptor & { pathname: string };

customHistory.push = (
  location: LocationType | string,
  state: { [k: string]: string } = {}
): void => {
  if (
    lastLocation === null ||
    location !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(location, state);
  }
};

(customHistory as CustomHistory).pushWithSameKeyAsNItem = (n = 1) => {
  const item = peekNItemInNavigationSelector(useHistoryStore.getState(), n);

  customHistory.push({
    pathname: item?.pathname,
    key: item?.key,
    search: item.search,
    state: { key: item?.key },
  });
};

// Saves the pathname to the session storage as the last path visited (auth routes excluded)
// This value will be retrieved on a successful login to redirect the user to that path
export function updateRedirectToAfterLogin(pathname: string, search: string) {
  // Do nothing if the path is an auth route
  // Otherwise the user may get stuck in login page
  if (pathname.startsWith(authLinks.root())) {
    return;
  }

  // Do nothing if the path is an oauth2 route
  // Otherwise the user may get stuck in consent page
  if (pathname.startsWith(oauth2Links.root())) {
    return;
  }

  // Do nothing if the path is the logout page
  // Otherwise the user may get stuck unauthenticated
  if (pathname.startsWith(authLinks.logout())) {
    return;
  }

  // Set path to session storage
  sessionStorage.setItem(
    sessionStorageKeys.REDIRECT_TO_AFTER_LOGIN,
    pathname + search
  );
}

export default customHistory as CustomHistory;
