import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 4C6.68629 4 4 6.68629 4 10V38C4 41.3137 6.68629 44 10 44H38C41.3137 44 44 41.3137 44 38V10C44 6.68629 41.3137 4 38 4H10ZM19.0314 30.2895C19.5414 30.0695 20.0064 29.6895 20.4264 29.1495L20.6664 30.3645H22.1664V24.5745H17.6664V26.3295H20.0364C19.9664 27.0795 19.7164 27.6545 19.2864 28.0545C18.8664 28.4445 18.2664 28.6395 17.4864 28.6395C16.9564 28.6395 16.5064 28.5395 16.1364 28.3395C15.7664 28.1295 15.4664 27.8545 15.2364 27.5145C15.0064 27.1745 14.8364 26.7945 14.7264 26.3745C14.6264 25.9445 14.5764 25.5045 14.5764 25.0545C14.5764 24.5845 14.6264 24.1295 14.7264 23.6895C14.8364 23.2495 15.0064 22.8595 15.2364 22.5195C15.4664 22.1695 15.7664 21.8945 16.1364 21.6945C16.5064 21.4845 16.9564 21.3795 17.4864 21.3795C18.0564 21.3795 18.5414 21.5295 18.9414 21.8295C19.3414 22.1295 19.6114 22.5795 19.7514 23.1795H22.0014C21.9414 22.5695 21.7764 22.0295 21.5064 21.5595C21.2364 21.0895 20.8914 20.6945 20.4714 20.3745C20.0614 20.0545 19.5964 19.8145 19.0764 19.6545C18.5664 19.4845 18.0364 19.3995 17.4864 19.3995C16.6664 19.3995 15.9264 19.5445 15.2664 19.8345C14.6164 20.1245 14.0664 20.5245 13.6164 21.0345C13.1664 21.5445 12.8214 22.1445 12.5814 22.8345C12.3414 23.5145 12.2214 24.2545 12.2214 25.0545C12.2214 25.8345 12.3414 26.5645 12.5814 27.2445C12.8214 27.9145 13.1664 28.4995 13.6164 28.9995C14.0664 29.4995 14.6164 29.8945 15.2664 30.1845C15.9264 30.4645 16.6664 30.6045 17.4864 30.6045C18.0064 30.6045 18.5214 30.4995 19.0314 30.2895ZM26.4232 19.6545H24.0682V30.3645H26.4232V19.6545ZM36.022 19.6545H28.492V30.3645H30.847V25.9395H35.332V24.1095H30.847V21.6345H36.022V19.6545Z"
  />,
  'gif'
);
