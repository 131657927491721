const taskgraphLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/overview`,

  metadata: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/metadata`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/sharing`,

  preview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/preview`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/settings`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/taskgraphs/details/${namespace}/${arrayId}/activity`,
};

export default taskgraphLinks;
