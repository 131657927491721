import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M47.1238 24.9743L30.0828 42.0153C29.9183 42.1798 29.7538 42.2345 29.5347 42.2345L20.439 42.2892C20.2198 42.2892 20.0007 42.1794 19.8909 42.07L2.46615 24.6453C2.19227 24.3163 2.19227 23.878 2.46615 23.5494L19.5072 6.50835C19.6716 6.39861 19.8361 6.28918 20.0553 6.28918H29.151C29.3701 6.28918 29.5893 6.39893 29.699 6.50835L47.1238 23.9331C47.3977 24.207 47.3977 24.7004 47.1238 24.9743ZM26.7404 8.75536H22.4664L24.6034 10.8376L26.7404 8.75536ZM26.6856 35.9877L29.6993 39.0013L43.0142 25.6864H36.9868L26.6856 35.9877ZM26.3567 12.5909L36.9873 23.2214H43.0146L29.316 9.5775L26.3567 12.5909ZM14.7955 24.0974L24.9876 34.234L34.796 24.4803L24.6039 14.2882L14.7955 24.0966V24.0974ZM6.57646 22.8918H12.6038L22.9053 12.5903L19.8917 9.57667L6.57646 22.8918ZM22.8501 39.8235H27.124L24.987 37.6865L22.8501 39.8235ZM23.2337 35.9879L12.6031 25.3026H6.57579L20.2744 39.0013L23.2337 35.9879Z"
  />,
  'geometry'
);
