import { useEffect, useState } from 'react';

const useDocumentVisibility = () => {
  const [documentIsHidden, setHidden] = useState(document.hidden);

  const onVisibilityChange = () => setHidden(document.hidden);
  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });

  return !documentIsHidden;
};

export default useDocumentVisibility;
