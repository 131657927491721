import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.0021 4H37.0125C38.855 4 40.3472 5.49229 40.3472 7.33473V34.0125C40.3472 35.855 38.855 37.3473 37.0125 37.3473H11.7587C10.9431 37.465 10.3174 38.166 10.3174 39.0146C10.3174 39.9457 11.0709 40.6993 12.0021 40.6993H38.6799C39.5911 40.6993 40.3299 41.438 40.3299 42.3493C40.3299 43.2606 39.5911 43.9993 38.6799 43.9993H12.0021C9.24837 43.9993 7.01735 41.7683 7.01735 39.0146C7.01735 39.0117 7.01735 39.0088 7.01736 39.0059L7 39.0146V9.00209C7 6.23927 9.23927 4 12.0021 4ZM18.6715 17.3389H30.3431C31.2635 17.3389 32.0104 16.5919 32.0104 15.6715C32.0104 14.7512 31.2635 14.0042 30.3431 14.0042H18.6715C17.7512 14.0042 17.0042 14.7512 17.0042 15.6715C17.0042 16.5919 17.7512 17.3389 18.6715 17.3389Z"
  />,
  'documentation'
);
