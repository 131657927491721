import replacePathParams from 'utils/helpers/replacePathParams';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  ArrayApi_getArrayInfo,
  ArrayApi_getArrayInfo$Params,
  ArrayApi_getArrayInfo$Path,
  ArrayApi_getArrayInfo$Response,
} from 'api-client';

export const createPath = (data: ArrayApi_getArrayInfo$Params): string =>
  replacePathParams(ArrayApi_getArrayInfo$Path, data);

const useGetArray: UseRequestType<
  ArrayApi_getArrayInfo$Params,
  ArrayApi_getArrayInfo$Response
> = (queryData, config, queryConfig) => {
  const { isInitialLoading, data, error, refetch } = useQuery<
    AxiosResponse<ArrayApi_getArrayInfo$Response>,
    ArrayApi_getArrayInfo$Params
  >(
    [createPath(queryData), queryData],
    useQueryRequest(ArrayApi_getArrayInfo, config),
    queryConfig
  );
  return [refetch, data, error, isInitialLoading];
};

export default useGetArray;
