import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import { authRouterPaths } from 'utils/routerPaths';
import { authLinks, overviewLinks } from 'utils/links';
import useSessionStorage from 'hooks/useSessionStorage';
import sessionStorageKeys from 'utils/constants/sessionStorageKeys';
import { useUserContext } from 'store/UserContext';
import lazyLoadComponent from 'utils/helpers/lazyLoadComponent';

const Login = lazyLoadComponent(() => import('./Login'));
const Signup = lazyLoadComponent(() => import('./Signup'));
const EmailNotConfirmed = lazyLoadComponent(
  () => import('./EmailNotConfirmed')
);
const ForgotPassword = lazyLoadComponent(() => import('./ForgotPassword'));
const ResetPassword = lazyLoadComponent(() => import('./ResetPassword'));
const CompanySSO = lazyLoadComponent(() => import('./CompanySSO'));
const SSO = lazyLoadComponent(() => import('./SSO'));
const ConfirmEmail = lazyLoadComponent(() => import('./ConfirmEmail'));

function Auth(): JSX.Element {
  const { isLoggedIn } = useUserContext();
  const [redirectToAfterLogin] = useSessionStorage(
    sessionStorageKeys.REDIRECT_TO_AFTER_LOGIN
  );
  const redirectUrl = redirectToAfterLogin || overviewLinks.root();

  return (
    <>
      {isLoggedIn && <Redirect to={redirectUrl} />}
      <Suspense fallback={null}>
        <Switch>
          <Redirect exact from={authRouterPaths.root} to={authLinks.login()} />
          <Route path={authRouterPaths.login} component={Login} />
          <Route path={authRouterPaths.signup} component={Signup} />
          <Route
            path={authRouterPaths.emailNotConfirmed}
            component={EmailNotConfirmed}
          />
          <Route path={authRouterPaths.confirmEmail} component={ConfirmEmail} />
          <Route
            path={authRouterPaths.forgotPassword}
            component={ForgotPassword}
          />
          <Route path={authRouterPaths.companySSO} component={CompanySSO} />
          <Route path={authRouterPaths.ssoProvider} component={SSO} />
          <Route
            path={authRouterPaths.resetPassword}
            component={ResetPassword}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}

export default Auth;
