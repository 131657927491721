const mlModelRouterPaths = {
  root: '/mlmodels',
  details: '/mlmodels/details/:namespace/:arrayId',
  overview: '/mlmodels/details/:namespace/:arrayId/overview',
  sharing: '/mlmodels/details/:namespace/:arrayId/sharing',
  settings: '/mlmodels/details/:namespace/:arrayId/settings',
  preview: '/mlmodels/details/:namespace/:arrayId/preview',
  activity: '/mlmodels/details/:namespace/:arrayId/activity',
  metadata: '/mlmodels/details/:namespace/:arrayId/metadata',
};

export default mlModelRouterPaths;
