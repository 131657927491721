const stripSearchParamsFromPathName = (pathname: string) => {
  const baseURL = 'https://do-not-return-base-url.com'; // As base path could be anything, as long as it's valid.
  const url = new URL(pathname, baseURL);

  return {
    pathname: url.pathname,
    searchParams: url.searchParams,
  };
};

export default stripSearchParamsFromPathName;
