const vectorSearchLinks = {
  details: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}`,

  overview: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/overview`,

  sharing: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/sharing`,

  settings: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/settings`,

  contents: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/contents`,

  metadata: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/metadata`,

  activity: ({ namespace, groupId }: { namespace: string; groupId: string }) =>
    `/vector-search/${namespace}/${groupId}/activity`,
};

export default vectorSearchLinks;
