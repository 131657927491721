import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24.1139 4.07214C35.1519 4.07214 44.1139 13.034 44.1139 24.0721C44.1139 35.1102 35.1519 44.0721 24.1139 44.0721C13.0748 44.0721 4.11389 35.1102 4.11389 24.0721C4.11389 13.0331 13.0748 4.07214 24.1139 4.07214ZM24.1139 14.0721C29.6324 14.0721 34.114 18.5526 34.114 24.0722C34.114 29.5908 29.6325 34.0723 24.1139 34.0723C18.5944 34.0723 14.1138 29.5909 14.1138 24.0722C14.1138 18.5527 18.5943 14.0721 24.1139 14.0721Z"
  />,
  'soma'
);
