import { TaskGraphLog } from 'api-client';
import { AxiosResponse } from 'axios';
import { createPath as createGetTaskGraphLogQueryKey } from 'hooks/api/Tasks/useGetTaskGraphDetails';
import queryClient from 'utils/helpers/queryClient';

type Params = {
  id: string;
  namespace: string;
};

const getTaskGraphLogFromCache = (params: Params): TaskGraphLog | undefined => {
  const { id, namespace } = params;
  const queryKey = createGetTaskGraphLogQueryKey({
    id,
    namespace,
  });

  const res = queryClient
    .getQueryCache()
    .find<unknown, unknown, AxiosResponse<TaskGraphLog>>([queryKey], {
      exact: false,
    });

  return res?.state.data?.data;
};

export default getTaskGraphLogFromCache;
