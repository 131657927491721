import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M7.09538 7.00154H14.5686C15.6449 7.00154 16.6444 7.55538 17.198 8.49385L20.9146 14.6923H4V10.0769C4 8.37692 5.37623 7 7.07539 7L7.09538 7.00154Z" />
    <path d="M40.9246 10.0784H4.02V37.7707C4.02 39.463 5.39624 40.8477 7.09539 40.8477H10.1708V40.0784C10.1708 38.8015 11.201 37.7707 12.4773 37.7707C13.7382 37.7707 14.7838 38.8015 14.7838 40.0784V40.8477H33.2362V40.0784C33.2362 38.8015 34.2664 37.7707 35.5427 37.7707C36.8036 37.7707 37.8492 38.8015 37.8492 40.0784V40.8477H40.9246C42.6161 40.8477 44 39.463 44 37.7707V13.1553C44 11.4477 42.6161 10.0784 40.9246 10.0784ZM10.1708 31.6169C11.0165 28.2984 13.8151 29.2153 14.3994 27.34V25.94C14.1073 25.7861 13.2769 24.7092 13.2 23.8784C12.9694 23.8477 12.6157 23.6323 12.5081 22.74C12.4466 22.2477 12.6618 21.9861 12.8002 21.8938C12.8002 21.8938 12.4619 21.1092 12.4619 20.3553C12.4466 18.0784 13.5844 16.1892 16.2908 16.1892C17.7516 16.1892 18.2129 17.22 18.2129 17.22C19.5199 17.22 20.135 18.6507 20.135 20.343C20.135 21.1738 19.7967 21.8815 19.7967 21.8815C19.9197 21.9584 20.1504 22.2353 20.0889 22.7123C19.9812 23.5892 19.6276 23.82 19.3969 23.8353C19.3047 24.6661 18.4743 25.7277 18.1975 25.8815V27.2661C18.7665 29.1277 21.5805 28.22 22.4262 31.5277H10.1093L10.1708 31.6169ZM36.3115 30.0784H27.0854C26.2243 30.0784 25.5477 29.3861 25.5477 28.54C25.5477 27.6784 26.2243 27.0015 27.0854 27.0015H36.3115C37.1573 27.0015 37.8492 27.6784 37.8492 28.54C37.8492 29.3861 37.1573 30.0784 36.3115 30.0784ZM36.3115 23.9246H27.0854C26.2243 23.9246 25.5477 23.2323 25.5477 22.3861C25.5477 21.5246 26.2243 20.8477 27.0854 20.8477H36.3115C37.1573 20.8477 37.8492 21.5246 37.8492 22.3861C37.8492 23.2323 37.1573 23.9246 36.3115 23.9246Z" />
  </>,
  'vcard'
);
