const activityLinks = {
  root: () => '/activity',
  tasks: () => '/activity/tasks',
  taskGraphLogs: () => '/activity/taskgraphs',
  taskDetails: ({ taskId }: { taskId: string }) => `/activity/tasks/${taskId}`,
  activityDetails: ({
    namespace,
    activityId,
    arrayId,
  }: {
    namespace: string;
    arrayId: string;
    activityId: string;
  }) => `/activity/${namespace}/${arrayId}/${activityId}`,
  taskGraphLogDetails: ({
    namespace,
    taskGraphId,
  }: {
    namespace: string;
    taskGraphId: string;
  }) => `/activity/taskgraphs/${namespace}/${taskGraphId}`,
};

export default activityLinks;
