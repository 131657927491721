import './InboxButton.scss';
import Button from 'components/v2/Button';
import Dialog from 'components/Dialog';
import IconBellFilled from 'components/Svg/icons/IconBellFilled';
import Text from 'components/Text';
import React, { useState } from 'react';
import useInboxStore, { useInboxPolling } from 'store/InboxStore';
import InboxContent from '../InboxContent';
import { isNumber } from 'utils/helpers/number';
import useUserPreferencesStore from 'store/UserPreferencesStore';
import IconBellFilledSnoozed from 'components/Svg/icons/IconBellFilledSnoozed';

const InboxButton: React.FC = () => {
  const [dialogActive, setDialogActive] = useState(false);
  const unreadCount = useInboxStore((state) => state.unreadCount);
  const notificationsSnoozed = useUserPreferencesStore(
    (state) => state.notificationsSnoozed
  );
  useInboxPolling();

  return (
    <div className="TDB-InboxButton">
      <Dialog
        interactive
        trigger="manual"
        placement="bottom"
        content={
          <InboxContent closeDialogFunc={() => setDialogActive(false)} />
        }
        preventOverflow
        variant="transparent"
        className="TDB-InboxDialog"
        visible={dialogActive}
        handleCloseDialog={() => setDialogActive(false)}
      >
        {!notificationsSnoozed && isNumber(unreadCount) && unreadCount > 0 && (
          <div className="TDB-InboxButton__badge">
            <Text
              as="p"
              color="text-action-primary"
              variant="subtext-small"
              fontWeight="bold"
              dataTestId="inbox-button-badge"
            >
              {unreadCount}
            </Text>
          </div>
        )}
        <Button
          icon={notificationsSnoozed ? IconBellFilledSnoozed : IconBellFilled}
          size="medium"
          variant="secondary"
          onClick={() => setDialogActive(!dialogActive)}
          dataTestID="inbox-button"
        />
      </Dialog>
    </div>
  );
};

export default InboxButton;
