import { useContext } from 'react';
import ThemeContext, { Theme } from 'store/ThemeContext';

interface UseTheme {
  theme: Theme.Dark | Theme.Light;
  changeTheme: (theme: Theme) => void;
  themeTabSelected: Theme;
}

function useTheme(): UseTheme {
  const { theme, changeTheme, themeTabSelected } =
    useContext<UseTheme>(ThemeContext);

  return { theme, changeTheme, themeTabSelected };
}

export default useTheme;
