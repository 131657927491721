const fileLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/overview`,

  metadata: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/metadata`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/sharing`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/activity`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/settings`,

  preview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/files/details/${namespace}/${arrayId}/preview`,

  download: ({
    namespace,
    arrayId,
    timestamp,
  }: {
    namespace: string;
    arrayId: string;
    timestamp?: number;
  }) =>
    `/files/details/${namespace}/${arrayId}/download?timestamp=${timestamp}`,
};

export default fileLinks;
