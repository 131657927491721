import './NotificationItem.scss';
import * as React from 'react';
import {
  Notification,
  NotificationStatus,
  NotificationsApi_changeNotificationStatus$Params,
  NotificationsApi_deleteNotifications$Params,
} from 'api-client';
import Text from 'components/Text';
import classNames from 'classnames';
import useDate from 'hooks/useDate';
import getNotificationDescription from './getNotificationDescription';
import { GradientIconType } from 'components/GradientIcons/createGradientIcon';
import getNotificationIcon from './getNotificationIcon';
import getNotificationLink from './getNotificationLink';
import Button from 'components/v2/Button';
import IconCheckFilled from 'components/Svg/icons/IconCheckFilled';
import Tooltip from 'components/Tooltip';
import IconTrash from 'components/Svg/icons/IconTrash';
import useChangeNotificationStatus from 'hooks/api/Notifications/useChangeNotificationStatus';
import { useUserContext } from 'store/UserContext';
import IconNotification from 'components/Svg/icons/IconNotification';
import useDeleteNotifications from 'hooks/api/Notifications/useDeleteNotifications';
import { CustomErrorBoundary } from 'components/ErrorBoundary';
import IconWarning from 'components/Svg/icons/IconWarning';

interface NotificationComponentProps {
  notification: Notification;
  onClick?: () => void;
}

const NotificationComponent: React.FC<NotificationComponentProps> = (props) => {
  const { notification, onClick } = props;
  const { description, icon, link } = React.useMemo(() => {
    const description = getNotificationDescription(notification);
    const icon = getNotificationIcon(notification);
    const link = getNotificationLink(notification);
    return { description, icon, link };
  }, [notification]);

  return (
    <NotificationTemplate
      onClick={onClick}
      notification={notification}
      description={description}
      icon={icon}
      link={link}
    />
  );
};

interface NotificationTemplateProps {
  onClick?: () => void;
  link?: string;
  notification: Notification;
  description: React.ReactNode;
  icon: GradientIconType | null;
}

export const NotificationTemplate: React.FC<NotificationTemplateProps> = (
  props
) => {
  const { notification, description, icon: Icon, link, onClick } = props;
  const { relativeTimeInWordsToNow } = useDate();
  const [changeStatusRequest, , , changeStatusLoading] =
    useChangeNotificationStatus();
  const [deleteNotifications, , , deleteLoading] = useDeleteNotifications();
  const { user } = useUserContext();
  const as = link ? 'a' : 'div';
  const isUnread = notification.status === NotificationStatus.Unread;

  const changeNotificationStatus = React.useCallback(() => {
    const data: NotificationsApi_changeNotificationStatus$Params = {
      namespace: user.id,
      statusChange: {
        notifications: [notification.id as string],
        status: isUnread ? NotificationStatus.Read : NotificationStatus.Unread,
      },
    };
    changeStatusRequest([data]);
  }, [user, notification, isUnread, changeStatusRequest]);

  const onChangeStatusClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault(); // prevent anchor click
      e.stopPropagation(); // prevent parent element's onClick
      changeNotificationStatus();
    },
    [changeNotificationStatus]
  );
  const onDeleteClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault(); // prevent anchor click
      e.stopPropagation(); // prevent parent element's onClick

      const data: NotificationsApi_deleteNotifications$Params = {
        namespace: user.id,
        notifications: [notification.id as string],
      };
      deleteNotifications([data]);
    },
    [notification, user, deleteNotifications]
  );

  const handleClick = React.useCallback(() => {
    if (notification.status === NotificationStatus.Unread) {
      changeNotificationStatus();
    }
    onClick?.();
  }, [notification.status, changeNotificationStatus, onClick]);

  return (
    <Text
      as={as}
      to={link}
      className={classNames('TDB-NotificationItem', {
        'TDB-NotificationItem--active': isUnread,
      })}
      role="alert"
      onClick={handleClick}
    >
      <div className="TDB-NotificationItem__status">
        <div
          className={classNames('TDB-NotificationItem__status__dot', {
            'TDB-NotificationItem__status__dot--active': isUnread,
          })}
        />
      </div>
      {Icon && (
        <div className="TDB-NotificationItem__icon">
          <Icon active={isUnread} />
        </div>
      )}

      <div className="TDB-NotificationItem__main">
        {notification.created_at && (
          <Text
            color="text-main-tertiary"
            as="p"
            variant="subtext"
            className="TDB-NotificationItem__date mb-half"
            fontWeight="semi-bold"
          >
            {relativeTimeInWordsToNow(notification.created_at)} ago
          </Text>
        )}
        <Text
          as="p"
          color="text-main-secondary"
          fontWeight="semi-bold"
          variant="body-md"
          className="TDB-NotificationItem__description"
        >
          {description}
        </Text>
      </div>
      <div className="TDB-NotificationItem__actions">
        <Tooltip content={`Mark as ${isUnread ? 'read' : 'unread'}`}>
          <Button
            className="TDB-NotificationItem__actions__button"
            icon={() =>
              isUnread ? (
                <IconCheckFilled size="2.5rem" />
              ) : (
                <IconNotification size="2.5rem" />
              )
            }
            variant="plain"
            size="medium"
            onClick={onChangeStatusClick}
            loading={changeStatusLoading}
            dataTestID="button-change-notification-status"
          />
        </Tooltip>
        <Tooltip content="Delete">
          <Button
            className="TDB-NotificationItem__actions__button"
            icon={() => (
              <IconTrash size="2.5rem" colorToken="text-main-tertiary" />
            )}
            variant="plain"
            size="medium"
            onClick={onDeleteClick}
            loading={deleteLoading}
            dataTestID="button-delete-notification"
          />
        </Tooltip>
      </div>
    </Text>
  );
};

interface ErrorBoundaryContentProps {
  notification: Notification;
}

export const ErrorBoundaryContent: React.FC<ErrorBoundaryContentProps> = (
  props
) => {
  const { notification } = props;
  const [deleteNotifications, , , deleteLoading] = useDeleteNotifications();
  const { user } = useUserContext();
  const onDeleteClick = React.useCallback(() => {
    const data: NotificationsApi_deleteNotifications$Params = {
      namespace: user.id,
      notifications: [notification.id as string],
    };
    deleteNotifications([data]);
  }, [notification, user, deleteNotifications]);

  return (
    <div className="d-flex align-center justify-space-between">
      <div className="d-flex align-center">
        <IconWarning className="mr-1" />
        <Text>Notification is corrupted.</Text>
      </div>
      <Button
        className="TDB-NotificationItem__actions__button"
        icon={() => <IconTrash size="2.5rem" />}
        variant="plain"
        size="medium"
        onClick={onDeleteClick}
        loading={deleteLoading}
        dataTestID="button-delete-notification"
      />
    </div>
  );
};

// NotificationWithErrorBoundary will catch any error in the notification item
// and will try to delete the notification to prevent page crash
const NotificationWithErrorBoundary: React.FC<NotificationComponentProps> = (
  props
) => {
  return (
    <CustomErrorBoundary
      fallbackContent={
        <ErrorBoundaryContent notification={props.notification} />
      }
    >
      <NotificationComponent {...props} />
    </CustomErrorBoundary>
  );
};

export default NotificationWithErrorBoundary;
