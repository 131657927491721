import React, { ReactNode } from 'react';
import { AssetType, InvitationType, Notification } from 'api-client';
import getAssetTypeName from 'utils/helpers/getAssetTypeName';

const getDescriptionForInvitation = (notification: Notification): ReactNode => {
  switch (notification.notification_event?.invitation?.invitation_type) {
    case InvitationType.JOINORGANIZATION:
      return (
        <>
          You have been invited to join{' '}
          <strong>
            {notification.notification_event.invitation.organization?.name}
          </strong>
          .
        </>
      );
    case InvitationType.ARRAYSHARE:
      return (
        <>
          <strong>
            {notification?.notification_event?.invitation?.invited_by
              ?.namespace || 'someone'}
          </strong>{' '}
          shared the{' '}
          {getAssetTypeName(
            notification?.notification_event?.invitation?.asset
              ?.asset_type as AssetType
          )}{' '}
          named{' '}
          <strong>
            {notification?.notification_event?.invitation?.asset?.namespace}/
            {notification?.notification_event?.invitation?.asset?.name}
          </strong>{' '}
          with{' '}
          {notification?.notification_event.invitation.namespace_invited ? (
            <strong>
              {
                notification?.notification_event.invitation.namespace_invited
                  .namespace
              }
            </strong>
          ) : (
            'you'
          )}
          .
        </>
      );
    case InvitationType.GROUPSHARE:
      return (
        <>
          <strong>
            {notification?.notification_event?.invitation?.invited_by
              ?.namespace || 'someone'}
          </strong>{' '}
          shared the{' '}
          {getAssetTypeName(
            notification?.notification_event?.invitation?.asset
              ?.asset_type as AssetType
          )}{' '}
          named{' '}
          <strong>
            {notification?.notification_event?.invitation?.asset?.namespace}/
            {notification?.notification_event?.invitation?.asset?.name}
          </strong>{' '}
          with{' '}
          {notification?.notification_event.invitation.namespace_invited ? (
            <strong>
              {
                notification?.notification_event.invitation.namespace_invited
                  .namespace
              }
            </strong>
          ) : (
            'you'
          )}
          .
        </>
      );
    default:
      return '';
  }
};

export default getDescriptionForInvitation;
