import { useCallback, useEffect } from 'react';
import useJupyter, {
  JupyterStatus,
  QueuedTask,
  TaskType,
} from 'store/JupyterStore';
import useModal from 'store/ModalStore';

const useListenForQueuedTasksAndHandle = () => {
  const status = useJupyter((state) => state.status);
  const queuedTasks = useJupyter((state) => state.queuedTasks);
  const clearQueuedTasks = useJupyter((state) => state.clearQueuedTasks);
  const showSelectRegionAndServerProfileModal = useModal(
    (state) => state.showSelectRegionAndServerProfileModal
  );

  const handleQueuedTask = useCallback(
    (task: QueuedTask) => {
      if (task.action === TaskType.LaunchNotebook) {
        const { payload, callback } = task;

        showSelectRegionAndServerProfileModal({
          image: payload?.image,
          region: payload?.region,
          size: payload?.size,
        });
        callback?.();
      }
    },
    [showSelectRegionAndServerProfileModal]
  );

  useEffect(() => {
    if (status === JupyterStatus.Idle) {
      queuedTasks.forEach(handleQueuedTask);

      clearQueuedTasks();
    }
  }, [clearQueuedTasks, handleQueuedTask, queuedTasks, status]);
};

export default useListenForQueuedTasksAndHandle;
