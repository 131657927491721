const udfLinks = {
  details: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}`,

  overview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}/overview`,

  sharing: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}/sharing`,

  activity: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}/activity`,

  settings: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}/settings`,

  preview: ({ namespace, arrayId }: { namespace: string; arrayId: string }) =>
    `/udfs/details/${namespace}/${arrayId}/preview`,
};

export default udfLinks;
