import * as React from 'react';
import classNames from 'classnames';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import './WorkspaceItem.scss';
import IconCheckMark from 'components/Svg/icons/IconCheckMark';

type Props = {
  logo?: string;
  name: string;
  namespace: string;
  onClick: () => void;
  active?: boolean;
};

const WorkspaceItem: React.FC<Props> = (props) => {
  const { namespace, logo, name, onClick, active } = props;
  return (
    <div
      className={classNames('TDB-WorkspaceItem', {
        'TDB-WorkspaceItem--active': active,
      })}
      onClick={onClick}
      data-testid="TDB-WorkspaceItem"
    >
      <div className="TDB-WorkspaceItem__content">
        <Avatar
          className="mr-1"
          namespace={namespace}
          size="small"
          logo={logo}
        />
        <div>
          <Typography as="p" fontSize="body-md" fontWeight="600">
            {name}
          </Typography>
          <Typography variant="secondary" fontSize="body-xs" fontWeight="500">
            @{namespace}
          </Typography>
        </div>
      </div>
      {active && <IconCheckMark colorToken="color-text-default" size={16} />}
    </div>
  );
};

export default WorkspaceItem;
