import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M33.596 3C34.368 3 34.996 3.628 34.996 4.4V7.6C34.996 8.374 34.368 9 33.596 9H31.474C28.182 9 25.354 11.29 24.644 14.47L24.57 14.84L24.21 16.986L30.584 16.988C31.358 16.988 31.984 17.614 31.984 18.388V21.588C31.984 22.362 31.358 22.988 30.584 22.988H23.2L21.324 34.156C20.298 40.256 15.126 44.764 8.99 44.992L8.504 45H6.4C5.626 45 5 44.374 5 43.6V40.4C5 39.628 5.626 39 6.4 39H8.504C11.796 39 14.624 36.712 15.334 33.532L15.406 33.162L17.116 22.986L13.384 22.988C12.61 22.988 11.984 22.362 11.984 21.588V18.388C11.984 17.614 12.61 16.988 13.384 16.988H18.124L18.654 13.846C19.68 7.746 24.852 3.236 30.988 3.01L31.474 3H33.594H33.596Z" />
    <path d="M31.368 27.8361L35.52 31.9881L39.672 27.8361C40.14 27.3681 40.9 27.3681 41.368 27.8361L43.208 29.6761C43.676 30.1441 43.676 30.9041 43.208 31.3721L39.056 35.5241L43.206 39.6761C43.676 40.1441 43.676 40.9041 43.206 41.3721L41.368 43.2121C40.9 43.6801 40.14 43.6801 39.672 43.2121L35.52 39.0601L31.368 43.2101C30.9 43.6801 30.14 43.6801 29.672 43.2101L27.832 41.3721C27.364 40.9041 27.364 40.1441 27.832 39.6761L31.984 35.5241L27.832 31.3721C27.364 30.9041 27.364 30.1441 27.832 29.6761L29.672 27.8361C30.14 27.3681 30.9 27.3681 31.368 27.8361Z" />
  </>,
  'udf'
);
