import React, { createContext, useCallback, useContext, useMemo } from 'react';
import {
  OnboardingItem,
  OnboardingState,
  UserApi_getOnboardingItems$Response,
  UserApi_getOnboardingState$Response,
} from 'api-client';
import { useWorkspaceContext } from 'store/WorkspaceContext';
import useGetCompletedOnboardingItems from 'hooks/api/Onboarding/useGetCompletedOnboardingItems';
import { AxiosResponse } from 'axios';
import { EventTypes } from 'utils/helpers/eventBus';
import useEventCallback from 'hooks/useEventCallback';
import useAddCompletedOnboardingItems from 'hooks/api/Onboarding/useAddCompletedOnboardingItems';
import onboardingFlows, {
  getOnboardingItemsByFlow,
  OnboardingFlow,
} from 'components/Onboarding/onboardingFlows';
import useGetOnboardingState from 'hooks/api/Onboarding/useGetOnboardingState';
import { useUserContext } from 'store/UserContext';

interface OnboardingContextType {
  completedOnboardingItems: OnboardingItem[];
  refetchCompletedOnboardingItems: () => Promise<
    AxiosResponse<UserApi_getOnboardingItems$Response>
  >;
  refetchOnboardingState: () => Promise<
    AxiosResponse<UserApi_getOnboardingState$Response>
  >;
  /**
   * Percentage completed of user onboarding
   */
  onboardingProgress: number;

  /**
   * Status of onboarding requests
   */
  onboardingLoading: boolean;

  /**
   * Intro onboarding flow complete indicator
   */
  isIntroComplete: boolean;

  /**
   * Basic tour onboarding flow complete indicator
   */
  isBasicTourComplete: boolean;

  /**
   * Profiee setup onboarding flow complete indicator
   */
  isProfileSetupComplete: boolean;

  /**
   * Cloud storage onboarding flow complete indicator
   */
  isCloudStorageComplete: boolean;

  /**
   * Launch notebook onboarding flow complete indicator
   */
  isLaunchNotebookComplete: boolean;

  /**
   * Add asset onboarding flow complete indicator
   */
  isAddAssetComplete: boolean;

  /**
   * Invite members to organization flow complete indicator
   */
  isInviteMemberComplete: boolean;

  /**
   * Indicate onboarding finish complete indicator
   */
  isIndicateOnboardingFinishComplete: boolean;

  /**
   * Indicate whether user has skipped the onboarding
   */
  isUserOnboardingSkipped: boolean;

  /**
   * Onboarding state (real-time data state from the DB)
   */
  onboardingState: OnboardingState;

  /**
   * Organization basic tour flow complete indicator
   */
  isOrganizationBasicTourComplete: boolean;

  /**
   * Indicate organization onboarding finish complete indicator
   */
  isIndicateOrganizationOnboardingFinishComplete: boolean;

  /**
   * Indicate whether user has skipped the onboarding for this organization
   */
  isOrganizationOnboardingSkipped: boolean;
}

/**
 * Initial store
 */
export const store: any = {};

/**
 * Create organization context
 */
const OnboardingContext = createContext(store);

export interface OnboardingContextProps extends React.PropsWithChildren {
  /**
   * For testing purposes only
   */
  __unsafeOnboarding?: any;
  /**
   * For testing purposes only
   */
  __unsafeOnboardingState?: any;
}

export const OnboardingProvider: React.FC<OnboardingContextProps> = (props) => {
  const { children, __unsafeOnboarding, __unsafeOnboardingState } = props;
  const { user } = useUserContext();
  const { workspace, isOrganizationWorkspace, isUserWorkspace } =
    useWorkspaceContext();
  const [addOnboardingItems] = useAddCompletedOnboardingItems();
  const [refetchOnboardingState, onboardingStateResponse] =
    useGetOnboardingState(
      {
        namespace: workspace?.id as string,
        namespaceType: workspace?.type as string,
      },
      { noSnackbar: true },
      {
        enabled: !!workspace && !__unsafeOnboardingState,
      }
    );

  const [refetchCompletedOnboardingItems, onboardingResponse] =
    useGetCompletedOnboardingItems(
      {
        namespace: user?.id,
        organization: isOrganizationWorkspace ? workspace.id : undefined,
      },
      { noSnackbar: true },
      { enabled: !!user && !!workspace && !__unsafeOnboarding }
    );

  const completedOnboardingItems = useMemo<OnboardingItem[] | undefined>(
    () => __unsafeOnboarding || onboardingResponse?.data?.entries,
    [onboardingResponse, __unsafeOnboarding]
  );

  const onboardingState = useMemo<OnboardingState | undefined>(
    () => __unsafeOnboardingState || onboardingStateResponse?.data,
    [onboardingStateResponse, __unsafeOnboardingState]
  );
  useEventCallback(EventTypes.LAUNCHED_NOTEBOOK, async () => {
    // Mark launch notebook as complete if `LAUNCHED_NOTEBOOK` event dispatched from anywhere
    const data = {
      namespace: user?.id as string,
      items: {
        entries: [OnboardingItem.LaunchNotebook],
      },
    };
    await addOnboardingItems([data]);
    refetchCompletedOnboardingItems();
  });

  useEventCallback(EventTypes.ADDED_ASSET, async () => {
    // Mark add asset item as complete if `ADDED_ASSET` event dispatched from anywhere
    refetchOnboardingState();
  });

  useEventCallback(EventTypes.LAUNCHED_NOTEBOOK, async () => {
    // Mark launch notebook as complete if `LAUNCHED_NOTEBOOK` event dispatched from anywhere
    const data = {
      namespace: user?.id as string,
      items: {
        entries: [OnboardingItem.LaunchNotebook],
      },
    };
    await addOnboardingItems([data]);
    refetchCompletedOnboardingItems();
  });

  /**
   * Checks if the given onboarding flow is completed
   *
   * @param completedOnboardingItems namespace's completed onboarding items
   * @param flow the flow to check if completed
   * @returns boolean
   */
  const isFlowComplete = useCallback(
    (flow: OnboardingFlow): boolean => {
      if (!completedOnboardingItems) {
        // If completed items are not present for any reason do not start the onboarding
        return false;
      }

      return !getOnboardingItemsByFlow(flow).some(
        (item) => !completedOnboardingItems?.includes(item)
      );
    },
    [completedOnboardingItems]
  );

  const {
    isIntroComplete,
    isBasicTourComplete,
    isProfileSetupComplete,
    isCloudStorageComplete,
    isLaunchNotebookComplete,
    isAddAssetComplete,
    isIndicateOnboardingFinishComplete,
    isUserOnboardingSkipped,
    isOrganizationBasicTourComplete,
    isIndicateOrganizationOnboardingFinishComplete,
    isInviteMemberComplete,
    isOrganizationOnboardingSkipped,
  } = useMemo(() => {
    return {
      isIntroComplete: isFlowComplete(onboardingFlows.user.intro),
      isBasicTourComplete: isFlowComplete(onboardingFlows.user.basicTour),
      isProfileSetupComplete: isFlowComplete(onboardingFlows.user.profileSetup),
      // cloud storage is marked as complete also if default storage settings are already set
      isCloudStorageComplete: onboardingState?.cloud_storage_settings || false,
      isLaunchNotebookComplete: isFlowComplete(
        onboardingFlows.user.launchNotebook
      ),
      isAddAssetComplete: onboardingState?.registered_asset || false,
      isIndicateOnboardingFinishComplete: isFlowComplete(
        onboardingFlows.user.indicateOnboardingFinish
      ),
      isUserOnboardingSkipped: completedOnboardingItems?.includes(
        OnboardingItem.SkipUserOnboarding
      ),
      isOrganizationBasicTourComplete: isFlowComplete(
        onboardingFlows.organization.basicTour
      ),
      isIndicateOrganizationOnboardingFinishComplete: isFlowComplete(
        onboardingFlows.organization.indicateOnboardingFinish
      ),
      isInviteMemberComplete: onboardingState?.organization_members || false,
      isOrganizationOnboardingSkipped: completedOnboardingItems?.includes(
        OnboardingItem.SkipOrganizationOnboarding
      ),
    };
  }, [completedOnboardingItems, onboardingState, isFlowComplete]);

  const onboardingProgress = useMemo(() => {
    let flowsToCheck: boolean[] = [];

    if (isUserWorkspace) {
      flowsToCheck = [
        isBasicTourComplete,
        isProfileSetupComplete,
        isCloudStorageComplete,
      ];
    } else if (isOrganizationWorkspace) {
      flowsToCheck = [
        isOrganizationBasicTourComplete,
        isInviteMemberComplete,
        isCloudStorageComplete,
        isAddAssetComplete,
      ];
    }

    let completedItemsCount = 0;
    flowsToCheck.forEach((isComplete: boolean) => {
      if (isComplete) {
        completedItemsCount++;
      }
    });
    return Math.round((completedItemsCount / flowsToCheck.length) * 100);
  }, [
    isBasicTourComplete,
    isProfileSetupComplete,
    isCloudStorageComplete,
    isAddAssetComplete,
    isUserWorkspace,
    isOrganizationWorkspace,
    isOrganizationBasicTourComplete,
    isInviteMemberComplete,
  ]);

  const onboardingLoading = useMemo(() => {
    return !completedOnboardingItems || !onboardingState;
  }, [completedOnboardingItems, onboardingState]);

  const providedValue = {
    onboardingLoading,
    completedOnboardingItems,
    refetchCompletedOnboardingItems,
    refetchOnboardingState,
    onboardingProgress,
    isCloudStorageComplete,
    isLaunchNotebookComplete,
    isAddAssetComplete,
    isIntroComplete,
    isBasicTourComplete,
    isProfileSetupComplete,
    isIndicateOnboardingFinishComplete,
    isUserOnboardingSkipped,
    onboardingState,
    isOrganizationBasicTourComplete,
    isIndicateOrganizationOnboardingFinishComplete,
    isInviteMemberComplete,
    isOrganizationOnboardingSkipped,
  };

  return (
    <OnboardingContext.Provider value={providedValue}>
      {children}
    </OnboardingContext.Provider>
  );
};

export function useOnboardingContext(): OnboardingContextType {
  return useContext(OnboardingContext);
}

export default OnboardingProvider;
