const assetsRouterPaths = {
  root: '/assets',
  data: '/assets/data/:category(my|shared)',
  code: '/assets/code/:category(my|shared)',
  arrays: '/assets/arrays/:category(my|shared)',
  files: '/assets/files/:category(my|shared)',
  vcf: '/assets/vcf/:category(my|shared)',
  soma: '/assets/soma/:category(my|shared)',
  biomedicalImaging: '/assets/biomedical-imaging/:category(my|shared)',
  pointCloud: '/assets/point-cloud/:category(my|shared)',
  geometry: '/assets/geometry/:category(my|shared)',
  raster: '/assets/raster/:category(my|shared)',
  vector: '/assets/vector-search/:category(my|shared)',
  notebooks: '/assets/notebooks/:category(my|shared)',
  dashboards: '/assets/dashboards/:category(my|shared)',
  udfs: '/assets/udfs/:category(my|shared)',
  taskGraphs: '/assets/task-graphs/:category(my|shared)',
  mlModels: '/assets/ml-models/:category(my|shared)',
  groups: '/assets/groups/:category(my|shared)',
};

export default assetsRouterPaths;
