import React, { useEffect } from 'react';
import { UserApi_patchUserMetaStatus$Params } from 'api-client';
import { ModalInfoParams } from 'components/ModalsV2/ModalInfo';
import useModal from 'store/ModalStore';
import imgFreeTier from './assets/free-tier.jpg';
import Typography from 'components/Typography';
import styles from './styles.module.scss';
import useGetUserMeta from 'hooks/api/User/useGetUserMeta';
import useUpdateUserMeta from 'hooks/api/User/useUpdateUserMeta';
import { useUserContext } from 'store/UserContext';

/**
 * Hook that checks if there is any pending information to be shown to the user.
 * If there is pending information (usually a one-time notification about a product update),
 * an informational modal is rendered. When the user dismisses the modal, a request is fired
 * to update the database, ensuring the message is not shown again.
 */
function usePendingNotification() {
  const { isLoggedIn } = useUserContext();
  const [, userMetaResponse] = useGetUserMeta(
    undefined,
    { noSnackbar: true },
    { enabled: isLoggedIn }
  );
  const showFreeTierModal = useFreeTierModal();

  useEffect(() => {
    if (userMetaResponse?.data.announce_cc_removal_and_free_tier) {
      showFreeTierModal();
    }
  }, [showFreeTierModal, userMetaResponse]);
}

export default usePendingNotification;

/**
 * Returns the function to open the free tier modal with its required params
 */
function useFreeTierModal(): Function {
  const showInfoModal = useModal((state) => state.showInfoModal);
  const flushModals = useModal((state) => state.flushModals);
  const [updateMeta] = useUpdateUserMeta();
  const onSuccess = async () => {
    const params: UserApi_patchUserMetaStatus$Params = {
      meta: {
        announce_cc_removal_and_free_tier: false,
      },
    };
    return updateMeta([params, { noSnackbar: true }]).finally(flushModals);
  };
  const params: ModalInfoParams = {
    onSuccess: onSuccess,
    title: "We've made some important changes",
    image: {
      light: imgFreeTier,
      dark: imgFreeTier,
    },
    content: (
      <div
        className={styles['free-tier__content']}
        data-testid="free-tier-content"
      >
        <div>
          <Typography
            fontSize="heading-xs"
            fontWeight="700"
            color="color-text-default"
            as="h3"
            className={styles['free-tier__bullet']}
          >
            1. Removal of payment instruments
          </Typography>
          <Typography
            fontSize="body-md-text"
            color="color-text-default"
            fontWeight="500"
            as="p"
            align="left"
          >
            As part of our ongoing efforts to simplify onboarding and reduce
            administrative overhead for our users, we have removed the ability
            to add a payment method on TileDB Cloud. Going forward, all usage is
            charged against your TileDB Cloud credits. If you are a member of a
            paid organization with credits, no action is needed on your end.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize="heading-xs"
            fontWeight="700"
            color="color-text-default"
            as="h3"
            className={styles['free-tier__bullet']}
          >
            2. Introducing our new free tier
          </Typography>
          <Typography
            fontSize="body-md-text"
            color="color-text-default"
            fontWeight="500"
            as="p"
            align="left"
          >
            If you are not a member of a paid account, you will also no longer
            be able to create organizations on TileDB. Instead, we have launched
            a new Free tier for individual users, which entails $100 in free
            credits for 6 months. For access to this Free tier, simply click
            Apply for free tier on your Home screen and follow the instructions.
            Your application will be reviewed by one of our staff within 24
            hours.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize="body-md-text"
            color="color-text-default"
            fontWeight="500"
            as="p"
            align="left"
          >
            If you have any questions, or feel you have been incorrectly removed
            from a paid tier, please contact our team at{' '}
            <Typography
              as="a"
              className="link"
              href="mailto:sales@tiledb.com"
              target="_blank"
            >
              sales@tiledb.com
            </Typography>
            .
          </Typography>
        </div>
      </div>
    ),
    action: {
      text: 'Done',
      onClick: flushModals,
    },
  };
  return () => showInfoModal(params);
}
