import React from 'react';
import {
  AssetType,
  InvitationType,
  Notification,
  NotificationType,
} from 'api-client';
import AssetTypeIcon from 'components/AssetTypeIcon';
import IconMembers from 'components/Svg/icons/IconMembers';

const getIconFromNotificationType = (notification: Notification): any => {
  if (notification.type === NotificationType.Invitation) {
    switch (notification.notification_event.invitation?.invitation_type) {
      case InvitationType.JOINORGANIZATION:
        return () => <IconMembers size={16} />;
      case InvitationType.ARRAYSHARE:
        return () => (
          <AssetTypeIcon
            size={16}
            assetType={
              notification.notification_event.invitation?.asset
                ?.asset_type as AssetType
            }
            active
          />
        );
      case InvitationType.GROUPSHARE:
        return () => (
          <AssetTypeIcon
            size={16}
            assetType={
              notification.notification_event.invitation?.asset
                ?.asset_type as AssetType
            }
            active
          />
        );
      default:
        return null;
    }
  }
  if (notification.type === NotificationType.Taskgraph) {
    return () => (
      <AssetTypeIcon
        size={16}
        assetType={AssetType.RegisteredTaskGraph}
        active
      />
    );
  }
  return null;
};

export default getIconFromNotificationType;
