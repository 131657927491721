import React, {
  PropsWithChildren,
  useMemo,
  createContext,
  useEffect,
  useState,
} from 'react';
import useTimeout from 'hooks/useTimeout';
import useLocalStorage from 'hooks/useLocalStorage';
import useMount from 'hooks/useMount';
declare const window: any;

export enum Theme {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
}

const ThemeContext: any = createContext({});

function ThemeProvider(props: PropsWithChildren<any>): React.ReactElement {
  const defaultTheme = props.theme || 'light';
  const defaultSystemTheme = window?.matchMedia?.(
    '(prefers-color-scheme: dark)'
  )?.matches
    ? Theme.Dark
    : Theme.Light;

  const [theme, setTheme] = useLocalStorage('DATA_THEME', defaultTheme);
  const [systemTheme, setSystemTheme] = useState(defaultSystemTheme);

  const documentElement = useMemo(() => {
    return document.documentElement;
  }, []);

  useMount(() => {
    /**
     * Add listener to system theme change (if supported)
     */
    const matchMedia = window?.matchMedia?.('(prefers-color-scheme: dark)');
    if (matchMedia) {
      matchMedia.onchange = (e: any) => {
        const systemTheme = e.matches ? Theme.Dark : Theme.Light;
        setSystemTheme(systemTheme);
      };
    }
  });

  useEffect(() => {
    let newTheme = theme;
    if (newTheme === 'system') {
      /**
       * apply system theme if selected theme === 'system'
       */
      newTheme = systemTheme;
    }
    documentElement.setAttribute('data-theme', newTheme as string);
  }, [documentElement, theme, systemTheme]);

  function changeTheme(newTheme: Theme): void {
    documentElement.classList.add('changing-theme');
    documentElement.setAttribute('data-theme', newTheme);
    setTheme(newTheme);
  }

  useTimeout(
    () => {
      documentElement.classList.remove('changing-theme');
    },
    200,
    [theme]
  );

  const context = {
    /**
     * Always provide a valid theme value ("light" or "dark")
     * If the selected theme is "system" provide the actual theme (defaultSystemTheme) which is either "light" or "dark"
     */
    theme: theme === 'system' ? defaultSystemTheme : theme,
    changeTheme,
    // The tab selected. can be either "light", "dark" or "system"
    themeTabSelected: theme,
  };

  return (
    <ThemeContext.Provider value={context}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider };

export default ThemeContext;
