import { unstable_batchedUpdates } from 'react-dom';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface WorkspaceSelectedStore {
  pageSize: number;
  setPageSize: (count: number) => void;
  notificationsSnoozed: boolean;
  setNotificationsSnoozed: (active: boolean) => void;
}

const persistConfig = {
  name: 'userPreferences-storage',
};

const useUserPreferencesStore = create(
  persist<WorkspaceSelectedStore>(
    (set, get) => ({
      pageSize: 10,
      setPageSize: (count: number) => set({ pageSize: count }),
      notificationsSnoozed: false,
      setNotificationsSnoozed: (active: boolean) =>
        set({ notificationsSnoozed: active }),
    }),
    persistConfig
  )
);

export const resetPageSize = () => {
  unstable_batchedUpdates(() => {
    useUserPreferencesStore.getState().setPageSize(10);
  });
};

export default useUserPreferencesStore;
