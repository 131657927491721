import {
  OrganizationApi_getOrganization,
  OrganizationApi_getOrganization$Params,
  OrganizationApi_getOrganization$Path,
  OrganizationApi_getOrganization$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import replacePathParams from 'utils/helpers/replacePathParams';

export const createPath = (
  data: OrganizationApi_getOrganization$Params
): string => {
  const { organization } = data;
  return replacePathParams(OrganizationApi_getOrganization$Path, {
    organization,
  });
};

const useGetOrganization: UseRequestType<
  OrganizationApi_getOrganization$Params,
  OrganizationApi_getOrganization$Response
> = (queryData, config, queryConfig) => {
  const { data, error, refetch, isInitialLoading } = useQuery<
    AxiosResponse<OrganizationApi_getOrganization$Response>,
    OrganizationApi_getOrganization$Params
  >(
    [createPath(queryData), queryData],
    useQueryRequest(OrganizationApi_getOrganization, config),
    queryConfig
  );

  return [refetch, data, error, isInitialLoading];
};

export default useGetOrganization;
