import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path d="M8 12.6138L16.6138 4H21.3875L8 17.3875V12.6138Z" />
    <path d="M12.7226 27.5162H17.4963L37.0314 7.98247L34.6439 5.59622L12.7226 27.5162Z" />
    <path d="M39.25 10.2024L37.915 8.86618L19.2637 27.5162H24.0387L39.25 12.3049V10.2024Z" />
    <path d="M32.3488 27.5162L39.25 20.615V27.5162H32.3488Z" />
    <path d="M39.2502 14.0725L25.8064 27.5162H30.5802L39.2502 18.8475V14.0725Z" />
    <path d="M33.76 4.7125L33.0475 4H29.6975L8 25.6975V27.5163H10.955L33.76 4.7125Z" />
    <path d="M8 10.8463V4H14.8463L8 10.8463Z" />
    <path d="M23.1563 4L8 19.155V23.93L27.93 4H23.1563Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 28.7662H39.25V44H8V28.7662ZM17.7038 34.37H19.5575V33.31H14.5813V34.37H16.4388V39.5737H17.7038V34.37ZM20.335 39.5737H21.6V33.31H20.335V39.5737ZM24.0775 34.37H27.1075V33.3112H22.8125V39.575H24.0775V36.9137H26.6925V35.8537H24.0775V34.37ZM29.4225 34.37H32.4525V33.3112H28.1588V39.575H29.4225V36.9137H32.0375V35.8537H29.4225V34.37Z"
    />
  </>,
  'tiff'
);
