import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4ZM24 21.643L16.8422 14.4851C16.5622 14.2051 16.3426 14.0731 16.1068 14.0016C15.871 13.9302 15.6298 13.9302 15.394 14.0016C15.1582 14.0731 14.9387 14.2051 14.6587 14.4851L14.4851 14.6587C14.2051 14.9387 14.0731 15.1582 14.0016 15.394C13.9302 15.6298 13.9302 15.871 14.0016 16.1068C14.0731 16.3426 14.2051 16.5622 14.4851 16.8422L21.643 24L14.4851 31.1578C14.2051 31.4378 14.0731 31.6574 14.0016 31.8932C13.9302 32.129 13.9302 32.3702 14.0016 32.606C14.0731 32.8418 14.2051 33.0613 14.4851 33.3413L14.6587 33.5149C14.9387 33.7949 15.1582 33.9269 15.394 33.9984C15.6298 34.0698 15.871 34.0698 16.1068 33.9984C16.3426 33.9269 16.5622 33.7949 16.8422 33.5149L24 26.357L31.1578 33.5149C31.4378 33.7949 31.6574 33.9269 31.8932 33.9984C32.129 34.0698 32.3702 34.0698 32.606 33.9984C32.8418 33.9269 33.0613 33.7949 33.3413 33.5149L33.5149 33.3413C33.7949 33.0613 33.9269 32.8418 33.9984 32.606C34.0698 32.3702 34.0698 32.129 33.9984 31.8932C33.9269 31.6574 33.7949 31.4378 33.5149 31.1578L26.357 24L33.5149 16.8422C33.7949 16.5622 33.9269 16.3426 33.9984 16.1068C34.0698 15.871 34.0698 15.6298 33.9984 15.394C33.9269 15.1582 33.7949 14.9387 33.5149 14.6587L33.3413 14.4851C33.0613 14.2051 32.8418 14.0731 32.606 14.0016C32.3702 13.9302 32.129 13.9302 31.8932 14.0016C31.6574 14.0731 31.4378 14.2051 31.1578 14.4851L24 21.643Z"
  />,
  'close-filled'
);
