import { FunctionComponent, ReactNode } from 'react';
import { create } from 'zustand';

export interface HeaderRenderProps {
  isLoggedIn: boolean;
}

type HeaderVariant = 'transparent';

export interface HeaderContent {
  icon?: FunctionComponent<any>;
  variant?: HeaderVariant | undefined;
  render?: (props: HeaderRenderProps) => ReactNode;
}

interface HeaderStore {
  HeaderContent: HeaderContent;
  setHeaderContent: (headerContent: HeaderContent) => void;
}

const useStore = create<HeaderStore>((set, get) => ({
  HeaderContent: {
    icon: undefined,
    render: undefined,
    variant: undefined,
  },
  setHeaderContent: (header: HeaderContent) => {
    return set((state: HeaderStore) => ({
      HeaderContent: header,
    }));
  },
}));

export default useStore;
