import { resetJupyterStoreFromNonReactHook } from 'store/JupyterStore';
import { clearWorkspaceSelectedStore } from 'store/WorkspaceSelectedStore';
import eventBus, { EventTypes } from '../eventBus';
import logoutJupyterHandler from '../logoutJupyterHandler';

const logoutHandler = () => {
  resetJupyterStoreFromNonReactHook();
  logoutJupyterHandler();
  clearWorkspaceSelectedStore();
  eventBus.emit(EventTypes.LOGOUT);
};

export default logoutHandler;
