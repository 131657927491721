const fileRouterPaths = {
  root: '/files',
  details: '/files/details/:namespace/:arrayId',
  sharing: '/files/details/:namespace/:arrayId/sharing',
  activity: '/files/details/:namespace/:arrayId/activity',
  overview: '/files/details/:namespace/:arrayId/overview',
  metadata: '/files/details/:namespace/:arrayId/metadata',
  settings: '/files/details/:namespace/:arrayId/settings',
  preview: '/files/details/:namespace/:arrayId/preview',
  download: '/files/details/:namespace/:arrayId/download',
};

export default fileRouterPaths;
