export enum MimeType {
  // TileDB-specific mime types
  Notebook = 'application/vnd.tiledb.v1.notebook',
  Array = 'application/vnd.tiledb.v1.array',
  UDF = 'application/vnd.tiledb.v1.user_defined_function',
  MLModel = 'application/vnd.tiledb.v1.ml_model',
  RegisterdTaskGraph = 'application/vnd.tiledb.v1.registered_task_graph',
  Group = 'application/vnd.tiledb.v1.group',
  Vcf = 'application/vnd.tiledb.v1.vcf',
  SOMA = 'application/vnd.tiledb.v1.soma',
  Pointcloud = 'application/vnd.tiledb.v1.pointcloud',
  Bioimg = 'application/vnd.tiledb.v1.bioimg',
  Geometry = 'application/vnd.tiledb.v1.geometry',
  Raster = 'application/vnd.tiledb.v1.raster',
  VectorSearch = 'application/vnd.tiledb.v1.vector_search',

  // Common mime types
  OctetStream = 'application/octet-stream',
  CSV = 'text/csv',
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  TIFF = 'image/tiff',
  TXT = 'text/plain',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  JSON = 'application/json',
  GZIP = 'application/gzip',
  ZIP = 'application/zip',
  WAV = 'audio/wav',
  HTML = 'text/html',
  VCARD = 'text/vcard',
  HDF = 'application/x-hdf',
  TAR = 'application/x-tar',
  GIF = 'image/gif',
  XML = 'text/xml',
  MP4 = 'video/mp4',
}
