const groupRouterPaths = {
  root: '/groups',
  details: '/groups/:namespace/:groupId',
  overview: '/groups/:namespace/:groupId/overview',
  sharing: '/groups/:namespace/:groupId/sharing',
  settings: '/groups/:namespace/:groupId/settings',
  contents: '/groups/:namespace/:groupId/contents',
  metadata: '/groups/:namespace/:groupId/metadata',
  activity: '/groups/:namespace/:groupId/activity',
};

export default groupRouterPaths;
