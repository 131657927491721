import { getDateTimeInstanceFromDate } from './date';
import { curry } from 'ramda';

const formatDistanceLocale = {
  lessThanXSeconds: {
    one: '< a second',
    other: '< {{count}} seconds',
  },

  xSeconds: {
    one: '1 second',
    other: '{{count}} seconds',
  },

  halfAMinute: 'half a minute',

  lessThanXMinutes: {
    one: '< a minute',
    other: '< {{count}} minutes',
  },

  xMinutes: {
    one: '1 minute',
    other: '{{count}} minutes',
  },

  aboutXHours: {
    one: '1 hour',
    other: '{{count}} hours',
  },

  xHours: {
    one: '1 hour',
    other: '{{count}} hours',
  },

  xDays: {
    one: '1 day',
    other: '{{count}} days',
  },

  aboutXMonths: {
    one: '1 month',
    other: '{{count}} months',
  },

  xMonths: {
    one: '1 month',
    other: '{{count}} months',
  },

  aboutXYears: {
    one: '1 year',
    other: '{{count}} years',
  },

  xYears: {
    one: '1 year',
    other: '{{count}} years',
  },

  overXYears: {
    one: 'over 1 year',
    other: 'over {{count}} years',
  },

  almostXYears: {
    one: 'almost 1 year',
    other: 'almost {{count}} years',
  },
};

type LocaleType = typeof formatDistanceLocale;

function formatDistanceToString(
  token: keyof LocaleType,
  count: number
): string {
  let result;
  if (typeof formatDistanceLocale[token] === 'string') {
    result = formatDistanceLocale[token];
  } else if (count === 1) {
    result = (formatDistanceLocale[token] as LocaleType['aboutXHours']).one;
  } else {
    result = (
      formatDistanceLocale[token] as LocaleType['aboutXHours']
    ).other.replace('{{count}}', String(count));
  }

  return result as string;
}

const MINUTES_IN_DAY = 1440;
const MINUTES_IN_ALMOST_TWO_DAYS = 2520;
const MINUTES_IN_MONTH = 43200;
const MINUTES_IN_TWO_MONTHS = 86400;

export const formatDetailedDistance = (
  dirtyDate: Date,
  dirtyBaseDate: Date
): string => {
  const duration = getDateTimeInstanceFromDate(dirtyDate).diff(
    getDateTimeInstanceFromDate(dirtyBaseDate),
    ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
  );

  let { minutes, hours, seconds, milliseconds, years, months, days } = duration;

  years = Math.abs(years);
  months = Math.abs(months);
  days = Math.abs(days);
  minutes = Math.abs(minutes);
  hours = Math.abs(hours);
  seconds = Math.abs(seconds);
  milliseconds = Math.abs(milliseconds);

  const units = {
    year: years,
    month: months,
    day: days,
    hour: hours,
    minute: minutes,
    second: seconds,
    millisecond: milliseconds,
  };

  const formattedDuration = Object.entries(units)
    .filter(([key, val]) => val !== 0)
    .map(([key, val]) => `${val} ${key}${val > 1 ? 's' : ''}`)
    .join(', ');

  return formattedDuration;
};

export const formatDistance = (
  dirtyDate: Date,
  dirtyBaseDate: Date
): string => {
  let { minutes } = getDateTimeInstanceFromDate(dirtyDate).diff(
    getDateTimeInstanceFromDate(dirtyBaseDate),
    ['minutes']
  );

  minutes = Math.abs(Math.round(minutes));

  // 0 up to 2 mins
  if (minutes < 2) {
    if (minutes === 0) {
      return formatDistanceToString('lessThanXMinutes', 1);
    } else {
      return formatDistanceToString('xMinutes', minutes);
    }

    // 2 mins up to 0.75 hrs
  } else if (minutes < 45) {
    return formatDistanceToString('xMinutes', minutes);

    // 0.75 hrs up to 1.5 hrs
  } else if (minutes < 90) {
    return formatDistanceToString('aboutXHours', 1);

    // 1.5 hrs up to 24 hrs
  } else if (minutes < MINUTES_IN_DAY) {
    const hours = Math.round(minutes / 60);
    return formatDistanceToString('aboutXHours', hours);

    // 1 day up to 1.75 days
  } else if (minutes < MINUTES_IN_ALMOST_TWO_DAYS) {
    return formatDistanceToString('xDays', 1);

    // 1.75 days up to 30 days
  } else if (minutes < MINUTES_IN_MONTH) {
    const days = Math.round(minutes / MINUTES_IN_DAY);
    return formatDistanceToString('xDays', days);

    // 1 month up to 2 months
  } else if (minutes < MINUTES_IN_TWO_MONTHS) {
    const months = Math.round(minutes / MINUTES_IN_MONTH);
    return formatDistanceToString('aboutXMonths', months);
  }
  let { months } = getDateTimeInstanceFromDate(dirtyDate).diff(
    getDateTimeInstanceFromDate(dirtyBaseDate),
    ['months']
  );

  months = Math.abs(Math.round(months));

  // 2 months up to 12 months
  if (months < 12) {
    const nearestMonth = Math.abs(Math.round(minutes / MINUTES_IN_MONTH));

    return formatDistanceToString('xMonths', nearestMonth);

    // 1 year up to max Date
  } else {
    const monthsSinceStartOfYear = months % 12;
    const years = Math.floor(months / 12);

    // N years up to 1 years 3 months
    if (monthsSinceStartOfYear < 3) {
      return formatDistanceToString('aboutXYears', years);

      // N years 3 months up to N years 9 months
    } else if (monthsSinceStartOfYear < 9) {
      return formatDistanceToString('overXYears', years);

      // N years 9 months up to N year 12 months
    } else {
      return formatDistanceToString('almostXYears', years + 1);
    }
  }
};

const curriedFormatDistance = curry(formatDistance);

export const formatDistanceToNow = curriedFormatDistance(new Date(Date.now()));
