/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
  immediate = false
): any => {
  let timeout: NodeJS.Timeout | null;
  return (...args: Parameters<F>): any => {
    const later = function (): void {
      timeout = null;
      if (!immediate) {
        func(...args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout as NodeJS.Timeout);
    timeout = setTimeout(later, waitFor);
    if (callNow) {
      func(...args);
    }
  };
};

export default debounce;
