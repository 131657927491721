import { useQueryRequest, UseRequestType } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import {
  NotificationsApi_getNotificationsCount,
  NotificationsApi_getNotificationsCount$Params,
  NotificationsApi_getNotificationsCount$Path,
  NotificationsApi_getNotificationsCount$Response,
} from 'api-client';

export const queryKey = 'notification-count';

export const createPath = (
  queryData: NotificationsApi_getNotificationsCount$Params
): string => {
  const query = {
    namespace: queryData.namespace,
    status: queryData.status,
  };

  return queryString.stringifyUrl({
    url: NotificationsApi_getNotificationsCount$Path,
    query,
  });
};

const useGetNotificationCount: UseRequestType<
  NotificationsApi_getNotificationsCount$Params,
  NotificationsApi_getNotificationsCount$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<NotificationsApi_getNotificationsCount$Response>
  >(
    [queryKey, queryData, 'notifications'],
    useQueryRequest(NotificationsApi_getNotificationsCount, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetNotificationCount;
