import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 44L4 26H22V44H4Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 22L4 4H22V22H4Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26 44V26H44V44H26Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26 22V6L42 6V22H26Z" />
  </>,
  'array'
);
