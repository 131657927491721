import {
  OrganizationApi_fetchAllOrganizations,
  OrganizationApi_fetchAllOrganizations$Params,
  OrganizationApi_fetchAllOrganizations$Path,
  OrganizationApi_fetchAllOrganizations$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

const useGetOrganizations: UseRequestType<
  OrganizationApi_fetchAllOrganizations$Params,
  OrganizationApi_fetchAllOrganizations$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<OrganizationApi_fetchAllOrganizations$Response>,
    OrganizationApi_fetchAllOrganizations$Params
  >(
    [OrganizationApi_fetchAllOrganizations$Path, queryData],
    useQueryRequest(OrganizationApi_fetchAllOrganizations, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetOrganizations;
