import React from 'react';
import { createSvgIcon } from '../Svg';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M25.2651 4.46169C27.7992 6.66673 33.8707 11.3776 40.1855 12.1651H40.1853C41.1877 12.2939 41.9324 13.139 41.8605 14.1554C41.0872 33.3289 28.4725 41.7484 24.9215 43.7531C24.3488 44.0823 23.6472 44.0823 23.0745 43.7531C19.5234 41.7624 6.9087 33.3428 6.13554 14.1554C6.10674 13.1387 6.85149 12.2939 7.85382 12.1651C14.1681 11.3776 20.2395 6.66673 22.7881 4.46169C23.4896 3.8461 24.5492 3.8461 25.2651 4.46169ZM14.512 23.9926C14.512 29.2475 18.7645 33.5004 24.0198 33.5004C29.275 33.5004 33.5276 29.2478 33.5276 23.9926C33.5276 18.7377 29.275 14.4848 24.0198 14.4848C18.7649 14.4848 14.512 18.7374 14.512 23.9926ZM22.5448 24.8518L28.2148 19.1814L30.2194 21.1717L24.5493 26.842L22.5731 28.8039L20.5829 26.8137L17.8195 24.0499L19.781 22.0884L22.5448 24.8518Z"
  />,
  'secure-shield'
);
