export interface EventSubscription {
  // Unsubscribes from an event to prevent memory leaks
  unsubscribe: () => void;
}

export enum EventTypes {
  CHANGE_THEME,
  TOGGLE_MAIN_SIDEBAR,
  TOGGLE_ARRAY_VERSION_SIDEBAR,
  TOGGLE_WORKSPACE_MENU,
  LAUNCHED_NOTEBOOK,
  ADDED_ASSET,
  LOGOUT,
}

class EventBus {
  // store any events as keys
  private eventListeners: { [event: string]: Function[] } = {};

  /**
   * Subscribe to an event
   * Always unsubscribe on unmount to prevent memory leaks!
   */
  public on(event: EventTypes, func: Function): EventSubscription {
    if (!EventTypes[event]) {
      throw new Error(
        `Event \`${event}\` does not exist in types. Use a valid event type or add \`${event}\` to eventBus's types and use the variable.`
      );
    }

    const eventsFound = this.eventListeners[event] || [];
    eventsFound.push(func);
    this.eventListeners[event] = eventsFound;

    return {
      // Unsubscribes from an event to prevent memory leaks
      unsubscribe: (): void => {
        const events = this.eventListeners[event];
        this.eventListeners[event] = events.filter((f: Function): boolean => {
          return f !== func;
        });
      },
    };
  }

  public emit(event: EventTypes, data?: any): void {
    const events = this.eventListeners[event];
    if (!events) {
      return;
    }
    events.forEach((func: any) => {
      func(data);
    });
  }
}

const eventBus = new EventBus();

export default eventBus;
