import React from 'react';
import { useUserContext } from 'store/UserContext';

interface Props extends React.PropsWithChildren {
  /**
   * Pass true to render children when not logged-in
   */
  inverse?: boolean;
}

/**
 * Renders children only if user is logged in
 */
const AuthRender: React.FC<Props> = (props) => {
  const { children, inverse } = props;
  const { isLoggedIn } = useUserContext();

  if (inverse) {
    /**
     * Renders children only when not logged in
     */
    return isLoggedIn ? null : (children as React.ReactElement);
  }

  return isLoggedIn ? (children as React.ReactElement) : null;
};

export default AuthRender;
