import { useEffect, useCallback } from 'react';

function useKeyDown(key: any, callback: Function, args: any[]): void {
  const handler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key) {
        callback();
      }
    },
    [callback, key]
  );

  useEffect(() => {
    window.addEventListener('keydown', handler);
    return (): void => {
      window.removeEventListener('keydown', handler);
    };
  }, [handler]);
}

export default useKeyDown;
