/*
 *  Div containing the app background depending on which page is active
 *  The main purpose is to have a specific background in overview page
 */
import classNames from 'classnames';
import './MainContent.scss';
import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { overviewLinks } from 'utils/links';

const MainContent: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { pathname } = useLocation();

  return (
    <div
      className={classNames('TDB-MainContent', {
        'TDB-MainContent--overview': pathname === overviewLinks.root(),
      })}
    >
      {children}
    </div>
  );
};

export default MainContent;
