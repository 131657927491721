import { useEffect, useState } from 'react';
import eventBus from 'utils/helpers/eventBus';
import { EventTypes } from 'utils/helpers/eventBus/eventBus';

function useEvent(type: EventTypes): any {
  const [data, setData] = useState();

  useEffect(() => {
    const sub = eventBus.on(type, (d: any) => {
      setData(d);
    });

    return (): void => {
      sub.unsubscribe();
    };
  }, [type]);

  return data;
}

export default useEvent;
