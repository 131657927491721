enum sessionStorageKeys {
  /**
   * Path to redirect after a successful login
   */
  REDIRECT_TO_AFTER_LOGIN = 'REDIRECT_TO_AFTER_LOGIN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  LAZY_LOAD_REFRESHED = 'LAZY_LOAD_REFRESHED',
}

export default sessionStorageKeys;
