import { ReactNode } from 'react';
import { Notification, NotificationType } from 'api-client';
import getDescriptionForInvitation from './getDescriptionForInvitation';
import getDescriptionForTaskgraph from './getDescriptionForTaskgraph';

const getNotificationDescription = (
  notification: Notification
): ReactNode | never => {
  switch (notification.type) {
    case NotificationType.Invitation:
      return getDescriptionForInvitation(notification);
    case NotificationType.Taskgraph:
      return getDescriptionForTaskgraph(notification);
    default:
      return '';
  }
};

export default getNotificationDescription;
