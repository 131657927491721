import generateUniqueId from 'utils/helpers/generateUniqueId';
import getTitleBasedOnPathname from '../getTitleBasedOnPathname';
import isPathnameRoot from '../isPathnameRoot';

interface props {
  pathName: string;
  /**
   * Setting exact: false, will return title for pathnames that are not "root"
   * Normally we have rules for Asset details pages, only for root pages. e.g. /arrays/TileDB-Inc/array-1
   * With exact: false we'll get title for sub-pages as well. e.g. /arrays/TileDB-Inc/array-1/overview
   */
  key?: string;
  exact?: boolean;
  search?: string;
}

class HistoryItem {
  public title: string | undefined;
  public pathname: string;
  public hasTitle: boolean;
  public isRoot: boolean;
  public key: string;
  public search?: string;
  /**
   * Not all pathnames have rules to get title.
   */
  public getTitle: Promise<string> | undefined;

  constructor(props: props) {
    const { pathName, exact, key, search } = props;
    const options = {
      exact: exact ?? undefined,
    };
    const getTitlePromise = getTitleBasedOnPathname(pathName, options);

    this.key = key ?? generateUniqueId();
    this.hasTitle = !!getTitlePromise;
    this.pathname = pathName;
    this.getTitle = getTitlePromise;
    this.isRoot = isPathnameRoot(pathName);
    this.search = search;

    getTitlePromise?.then((title) => {
      this.title = title;
    });
  }
}

export default HistoryItem;
