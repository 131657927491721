import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { overviewLinks } from 'utils/links';
import AuthPromptPlaceholder from 'components/AuthPromptPlaceholder';
import Placeholder, { PlaceholderGraphic } from 'components/v2/Placeholder';

export interface FetchingProps extends React.PropsWithChildren {
  isLoading: boolean;
  error?: any;
  ref?: (element: HTMLElement | null) => void;
  customErrorElement?: JSX.Element;
  skeleton?: JSX.Element;
  className?: string;
  noDataPlaceholder?: {
    shouldRender: boolean;
    element: JSX.Element;
  };
}

type Status = 403 | 404 | 413 | 500 | 'ECONNABORTED';

function getGraphicByStatus(status: Status): PlaceholderGraphic {
  switch (status) {
    case 403:
      return 'unauthorized-access';
    case 404:
      return 'no-search-results';
    default:
      return 'unexpected-error';
  }
}

function getDefaultDescriptionByStatus(status: Status): string {
  switch (status) {
    case 500:
      return 'We are experiencing an internal server problem. Please try back later.';
    case 404:
      return 'The page you are looking for might have been removed, had it’s name changed or is temporary unavailable.';
    case 403:
      return "You don't have access to this functionality.";
    case 413:
      return 'Content is too large to render.';
    case 'ECONNABORTED':
      return 'Could not connect to the server. Please check your internet connection.';
    default:
      /* istanbul ignore next */
      return '';
  }
}

export const Fetching: React.FC<FetchingProps> = (props) => {
  const {
    children,
    ref,
    isLoading,
    className,
    error,
    customErrorElement,
    skeleton,
    noDataPlaceholder,
  } = props;
  const history = useHistory();
  const transition = { duration: 0.5 };
  const childrenStyle = {
    opacity: 0,
  };
  const shouldRenderChildren = !isLoading && !error;
  const childrenAnimateStyle = {
    opacity: shouldRenderChildren ? 1 : 0,
  };

  if (isLoading) {
    return skeleton || null;
  }

  if (error) {
    const status = error?.response?.status || error.status || error.code;
    const message = error?.response?.data?.message || error.data?.title;
    let title = 'Uh oh, something went wrong!';

    if (!!customErrorElement) {
      return customErrorElement;
    }

    if (status === 401) {
      return <AuthPromptPlaceholder />;
    }

    if (status === 413) {
      title = 'Content too large';
    }

    return (
      <div
        ref={ref}
        className="d-flex fill-height align-center justify-center flex-1"
      >
        <Placeholder
          graphic={getGraphicByStatus(status)}
          title={title}
          description={message || getDefaultDescriptionByStatus(status)}
          actions={[
            {
              variant: 'primary',
              size: 'large',
              children: 'Go to home page',
              onClick: (): void => {
                history.push(overviewLinks.root());
              },
            },
          ]}
        />
      </div>
    );
  }

  if (noDataPlaceholder?.shouldRender && !!noDataPlaceholder?.element) {
    return noDataPlaceholder?.element;
  }

  return (
    <motion.div
      ref={ref}
      className={className}
      style={childrenStyle}
      transition={transition}
      animate={childrenAnimateStyle}
    >
      {children}
    </motion.div>
  );
};

export default Fetching;
