import * as React from 'react';
import useJupyter, { JupyterStatus } from 'store/JupyterStore';
import useNetwork from 'hooks/useNetwork';
import debounce from 'utils/helpers/debounce';
import useShouldRenderHiddenIframe from './useShouldRenderHiddenIframe';
import useAutoLaunch from 'hooks/jupyter/useAutoLaunch';
import { NotebookSize, NotebookType } from 'api-client';
import useOnJupyterIncomingMessage from 'hooks/jupyter/useOnJupyterIncomingMessage';
import useWaitForJupyterhubToBeReadyAfterShutdown from 'hooks/jupyter/useWaitForJupyterhubToBeReadyAfterShutdown';
import './HiddenJupyterIframe.scss';
import useListenForQueuedTasksAndHandle from './useListenForQueuedTasksAndHandle';

type Props = {};

const HiddenJupyterIframe: React.FC<Props> = (props) => {
  const {
    status,
    setStatus,
    setLaunchingProgress,
    server,
    image,
    setIdle,
    jupyterURL,
  } = useJupyter((state) => ({
    status: state.status,
    server: state.server,
    image: state.image,
    setStatus: state.setStatus,
    setIdle: state.setIdle,
    setLaunchingProgress: state.setLaunchingProgress,
    jupyterURL: state.jupyterURL,
  }));

  const network = useNetwork();
  const iframe = React.useRef<HTMLIFrameElement | null>(null);
  const errorsEncountered = React.useRef(0);
  const [iframeKey, setIframeKey] = React.useState(0);
  const errorsEncounteredFromService = React.useRef(0);

  const reRenderHiddenIframeDebounced = React.useRef(
    debounce(() => setIframeKey((k) => k + 1), 8_000, true)
  );
  const onError = React.useCallback(() => {
    /**
     * Upon error retry (refresh iframe), if we errors
     * persist. update status to ServiceUnavailable
     */
    reRenderHiddenIframeDebounced.current();

    errorsEncounteredFromService.current++;
    if (errorsEncounteredFromService.current > 15) {
      setStatus(JupyterStatus.ServiceUnavailable);
    }
  }, [setStatus, errorsEncounteredFromService, reRenderHiddenIframeDebounced]);

  React.useEffect(() => {
    if (network) {
      /**
       * Update iframe to refresh jupyter upon reconnection
       */
      reRenderHiddenIframeDebounced.current();
    }
  }, [network, reRenderHiddenIframeDebounced]);

  useOnJupyterIncomingMessage({
    onJupyterlabLaunching: () => {
      setStatus(JupyterStatus.Running);
      errorsEncountered.current = 0;
    },
    onJupyterSpawnPageReady: () => {
      errorsEncountered.current = 0;
      setIdle();
    },
    onJupyterNotRunning: () => {
      reRenderHiddenIframeDebounced.current();
    },
    onJupyterProgress: (progressNumber) => {
      /**
       * In case the iframe re-renders we might get a 0 progress message, that is sent on page load.
       * Ignore that first progress and use the current progress instead
       */
      if (progressNumber) {
        setLaunchingProgress(progressNumber);
      }
    },
    onJupyterError: () => {
      reRenderHiddenIframeDebounced.current();
      // Upon receiving an error we retry, after 35 retries we set status as Error
      errorsEncountered.current++;
      if (errorsEncountered.current > 35) {
        setStatus(JupyterStatus.ErrorEncountered);
      }
    },
  });

  /**
   * Re-render periodically the hidden iframe until it's ready to launch a new server (Spawn page has loaded).
   */
  useWaitForJupyterhubToBeReadyAfterShutdown({
    reRenderIframe: reRenderHiddenIframeDebounced.current,
    callback: () => {
      errorsEncountered.current = 0;
      setIdle();
    },
  });

  useAutoLaunch({
    enabled: Boolean(status === JupyterStatus.Idle),
    iframe: iframe,
    fileProperties: {
      image: image as NotebookType,
      size: server as NotebookSize,
    },
  });

  /**
   * Listen for Jupyter queued tasks and handle them,
   * e.g. Prompt a user with a image & server selection modal when Jupyter is ready to launch.
   */
  useListenForQueuedTasksAndHandle();

  /**
   * We don't need to render for this iframe to listen to events,
   * if user is already in the /server page or one of the modals which render jupyterhub are open.
   */
  const shouldRenderIframe = useShouldRenderHiddenIframe();

  if (!shouldRenderIframe) {
    return null;
  }

  return (
    <iframe
      className="TDB-HiddenJupyterIframe"
      ref={iframe}
      title="hidden jupyter"
      key={iframeKey}
      onErrorCapture={onError}
      src={jupyterURL}
    />
  );
};

export default HiddenJupyterIframe;
